import React from "react";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "@material-ui/icons/Apple";
import DeviceUnknownIcon from "@material-ui/icons/DeviceUnknown";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	apple: {
		color: "#aaa",
		"&:hover": {
			opacity: 0.8,
		},
	},
	android: {
		color: "#a4c639",
		"&:hover": {
			opacity: 0.8,
		},
	},
	unknown: {
		color: "#000",
		"&:hover": {
			opacity: 0.8,
		},
	}
	
}));

interface PlatformIconProps {
	platform: string;
	style?: React.CSSProperties;
}

export const PlatformIcon =  (props: PlatformIconProps)  => {
	const classes = useStyles();
	switch (props.platform.toLowerCase()) {
		case "ios":
			return <AppleIcon className={classes.apple} style={props.style} />;
		case "android":
			return <AndroidIcon className={classes.android} style={props.style} />;
		default:
			return <DeviceUnknownIcon className={classes.unknown} style={props.style} />;
	}
};

export default PlatformIcon;