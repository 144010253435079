import React, { useCallback, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Loader } from "../loader";

import { UserInfo } from "./UserHeader";
import { UserPaymentCards } from "./UserPaymentCards";
import { UserRedeem } from "./UserRedeem";
import { UserOrdersV2 } from "./UserOrdersV2";
import { UserOrders } from "./UserOrders";
import { UserPartners } from "./UserPartners";
import { UserSupportRequests } from "./UserSupportRequest";
import { UserDevices } from "./UserDevices";
import { Box } from "@material-ui/core";

import { getUser, User } from "../../api/user";
import { getProducts, Product } from "../../api/products";
import { getOperators, Operator } from "../../api/operators";
import { Device } from "../../api/device";
import { getSupportRequestsByUserId, SupportRequest as SupportRequestType } from "../../api/support_requests";
import { Typography } from "@material-ui/core";
import { FlexTicketContextProvider } from "./FlexTicketContext";

// Poll the user every 10 seconds
const userPollInterval = 10000;

export const UserDetails = () => {
	const [user, setUser] = useState<User>({});
	const [products, setProducts] = useState<{ [index: string]: Product }>({});
	const [operators, setOperators] = useState<{ [index: string]: Operator }>({});
	const [supportRequests, setSupportRequests] = useState<SupportRequestType[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const params = useParams<{ user_id: string }>();
	const [statusMessage, setStatusMessage] = useState<string>("");
	const [statusTitle, setStatusTitle] = useState<string>("");
	const history = useHistory();

	const devicesCompareFn = (a: Device, b: Device): number => {
		if (b.updated === undefined) {
			return 0;
		}
		if (a.updated === undefined) {
			return 0;
		}
		let n = a.updated > b.updated ? 1 : -1;
		return n
	}
	const fetchUserCallback = useCallback(() => {
		console.log(`Fetching user`, params.user_id, new Date());
		getProducts()
			.then((products) => {
				let indexed: { [index: string]: Product } = {};
				for (const product of products) {
					indexed[product.product_id] = product;
				}
				setProducts(indexed);
			});
		getOperators()
			.then((operators) => {
				let indexed: any = {};
				if (operators === undefined) {
					return;
				}

				for (const operator of operators) {
					indexed[operator.id] = operator;
				}

				setOperators(indexed);
			})

		// Backend can return Users by querying one of; user_id, user_email or a order_id. 
		// We update the browser URL to always show the userID, no new request must be done if the same user is returned.
		getUser(params.user_id)
			.then((usr) => {
				const isInvalidUser = Object.keys(usr).length === 1
				const userId = usr.user_id;
				const isUserFound = userId !== null && userId !== undefined;
				switch (true) {
					case isInvalidUser:
						usr = {};
						setStatusTitle("Could not find user");
						setStatusMessage("");
						break;

					case isUserFound:
						if (params.user_id !== userId) {
							// The search query was different from user id, clean it up by setting the expected user_id in the browser URL
							history.push(`/users/${userId}`);
						} else {
							getSupportRequestsByUserId(userId).then(setSupportRequests);
							setUser(usr);
						}
						break;

					default:
						// Handle the case where usr.user_id is null or undefined
						setStatusTitle("User ID is null or undefined");
						setStatusMessage("User ID is null or undefined, cannot fetch support requests");
						break;
				}

			})
			.then(() => setLoading(false))
			.catch(err => {
				console.log("Error", err)
				setUser({});
				setStatusTitle("Could not find user");
				if (err.response) {
					setStatusMessage("'" + err.response.statusText + "' occured while attempting to get user '" + params.user_id + "'");
				}

				setLoading(false);
			});
	}, [params.user_id])

	useEffect(() => {
		setLoading(true);
		fetchUserCallback();

		const intervalId = setInterval((prev) => {
			if (prev) {
				clearInterval(prev);
			}
			fetchUserCallback();
		}, userPollInterval);

		return () => clearInterval(intervalId);
	}, [fetchUserCallback]);

	if (loading) {
		return <Loader />;
	}

	if (Object.keys(user).length === 0) {
		return <>
			<Typography variant="h4">{statusTitle}</Typography>
			<Typography variant="body1">{statusMessage}</Typography>
		</>;
	}

	return (<>
		<FlexTicketContextProvider>
			<UserInfo user={user} />
			<Box display="flex" justifyContent="stretch" gridGap={30}>
				<UserPaymentCards payment_cards={user.payment_cards} />
				<UserPartners partners={user.partner_connections} />
			</Box>
			<br />
			<UserRedeem userId={user.user_id} />
			<UserOrdersV2 orders={user.orders_v2} user={user} operators={operators} products={products} />
			<UserOrders orders={user.orders} user={user} />
			<UserSupportRequests requests={supportRequests} />
			<UserDevices devices={user.devices?.sort(devicesCompareFn)} />
			<p>&nbsp;</p>
		</FlexTicketContextProvider>
	</>);
};
