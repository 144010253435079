
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useEffect, useState } from 'react';
import { clear, getLastUpdateDate, reinitialise } from "../../api/cache";


const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(3),
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(5),
	},

    buttonmargin: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
        marginRight: theme.spacing(2),
	},

    apierror: {
		color: "#c50c0c",
        marginBottom: theme.spacing(2),
        display: "block",
	},

    clearCache: {
        backgroundColor: "#c50c0c",
    }
}));

export const CacheUtils = () => {
    const classes = useStyles();
    const [popupVisible, setPopupVisible] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [isCacheCleared, setIsCacheCleared] = useState<boolean|false>(false)
    const [lastUpdateDate, setLastUpdateDate] = useState<string|"">("")
    const [lastUpdatedBy, setLastUpdatedBy] = useState<string|"">("")
    const [dialogAction, setDialogAction] = useState<string|"">("")
    const [validateTerm, setvalidateTerm] = useState<string|"">("")
    const [apiError, setAPIError] = useState<string|"">("")
    
    useEffect(() => {
		fetchLastUpdateDate();
	}, []);


	const fetchLastUpdateDate = () => {
        getLastUpdateDate().then((response) => {
            setLastUpdateDate(response.last_updated_date)
            setLastUpdatedBy(response.last_updated_by)
            setIsCacheCleared(response.is_cleared)
        }).catch (err => {
            setAPIError(err.response.statusText)
        })
	}

    const reinitCache = () => {
        setConfirmed(false)
        if (dialogAction === "reinitialise") {
            reinitialise()
            .then((response) => {
                setLastUpdateDate(response.last_updated_date)
                setLastUpdatedBy(response.last_updated_by)
                setIsCacheCleared(response.is_cleared)
                setPopupVisible(false)
            }).catch(err => {
                setAPIError(err.response.statusText)
                setPopupVisible(false)
            })
        } else if (dialogAction === "clear") {
            clear()
            .then((response) => {
                setLastUpdateDate(response.last_updated_date)
                setIsCacheCleared(response.is_cleared)
                setLastUpdatedBy(response.last_updated_by)
                setPopupVisible(false)
            }).catch(err => {
                setAPIError(err.response.statusText)
                setPopupVisible(false)
            })
        }
        
    }

    const validateUserConfirmationInput = (inputValue : string) => {
        if (inputValue === validateTerm) {
            setConfirmed(true)
        } else {
            setConfirmed(false)
        }
    }

    const prepareDialog= (action: string, validateterm: string) => {
        setDialogAction(action)
        setvalidateTerm(validateterm)
        setPopupVisible(true)

    }

    return (
        <div>
            <Toolbar>
				<Typography variant="h6">Cache Details</Typography>
			</Toolbar>
            <Paper className={classes.paper}>
                    <Grid direction="column" container >
                        {apiError !== "" &&
                            <Grid item>
                                <label className={classes.apierror}>{apiError}</label>
                            </Grid> 
                        }
                        <Grid item>
				    	    <Typography variant="button">Cache Last Update Date: {lastUpdateDate} </Typography>
                        </Grid>
                        <Grid item>
				    	    <Typography variant="button">Cache Last Updated By: {lastUpdatedBy} </Typography>
                        </Grid>
                        <Grid item>
				    	    <Typography variant="button">Is Cache Cleared: {isCacheCleared.toString()} </Typography>
                        </Grid>

                        <Grid direction="row" container >
                            <Grid item>
                                <Button 
                                    onClick={() => prepareDialog('reinitialise', 'Reinitialise')} 
                                    variant="contained" 
                                    className={classes.buttonmargin}
                                    color="primary">Reinitialise Cache
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button 
                                    onClick={() => prepareDialog('clear', 'Clear Cache')} 
                                    variant="contained" 
                                    className={`${classes.buttonmargin} ${classes.clearCache}`}
                                    color="primary">Clear Cache
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
			</Paper>
            

            <Dialog open={popupVisible} onClose={() => setPopupVisible(false)}>
                <DialogTitle>Confirm</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please type "{validateTerm}" in the textbox below to confirm you want to {dialogAction} cache, and then click on Confirm.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="userinput"
                        label={validateTerm}
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={
                            (e) => {
                                validateUserConfirmationInput(e.target.value);
                            }
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setPopupVisible(false)}>Cancel</Button>
                    <Button disabled={!confirmed} onClick={() => reinitCache()}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}