import React from "react";
import DateLabel from "../general/DateLabel";
import { makeStyles } from "@material-ui/core/styles";

import {
	SupportRequestNote
} from "../../api/support_requests";

const styleProps = {
	sentColor: "#0b93f6",
	receiveColor: "#e5e5ea",
	bgColor: "#fff",
};

const useSupportRequestNoteStyles = makeStyles((theme) => ({
	list: {
		display: "flex",
		flexDirection: "column",
		margin: "0 auto",
		padding: "0",
		listStyle: "none",
	},

	bubble: {
		display: "flex",
		flexDirection: "column",
		maxWidth: "455px",
		wordBreak: "keep-all",
		borderRadius: "25px",
		padding: "10px",
		margin: "10px",
	},

	bubble_header: {
		fontSize: "0.7rem",
		padding: "0",
		margin: "0",
	},

	bubble_received: {
		alignSelf: "flex-start",
		backgroundColor: styleProps.receiveColor,
		color: "black",
	},

	bubble_sent: {
		alignSelf: "flex-end",
		backgroundColor: styleProps.sentColor,
		color: "white",
	},

	bubble_content: {
		marginTop: "0.7rem",
		marginBottom: "0",
	},
}));


const Bubble = ({ created, user, note, is_system }: { created: number, user: string, note: string, is_system: boolean }) => {
	const styles = useSupportRequestNoteStyles(styleProps);
	const bubbleType = `${styles.bubble} ${is_system ? styles.bubble_received : styles.bubble_sent}`;
	return <div className={bubbleType}>
		<p className={`${styles.bubble_header} ${bubbleType}`}><DateLabel inputDate={created} /></p>
		{user.length > 0 && <p className={`${styles.bubble_header} ${bubbleType}`}>{user}</p>}
		<p className={styles.bubble_content}>{note}</p>
	</div>
};

export const SupportRequestNotes = ({ notes }: { notes: SupportRequestNote[] }) => {

	const styles = useSupportRequestNoteStyles(styleProps);

	if (notes.length === 0) {
		return <></>
	}

	return <div className={styles.list}>
		{notes.map((note) => {
			return <Bubble created={note.created} user={note.user} is_system={note.is_system} note={note.note} />;
		})}
	</div>
}