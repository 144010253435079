import React, { useState, useEffect, ReactNode } from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormControlLabel,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	Typography,
} from "@material-ui/core";
import {
	AddCircle as AddIcon,
	Create as CreateIcon,
} from "@material-ui/icons";

import { postContest, newContest, Contest } from "../../api/contest";
import { Product } from "../../api/products";

type AddContestProps = {
	products: Array<Product>;
	onUpdate: () => void;
}

export const AddContest = ({ products, onUpdate }: AddContestProps) => {
	const [open, setOpen] = useState<boolean>(false);
	const [saving, setSaving] = useState<boolean>(false);
	const [contest, setContest] = useState<Contest>(newContest());

	useEffect(() => {
		if (products.length > 0) {
			setContest({ ...contest, reward_product_id: products[0].product_id })
		}
	}, [products])

	const handleClose = (save: boolean) => {
		if (save) {
			setSaving(true);
			postContest(contest)
				.then(() => {
					setSaving(false);
					onUpdate();
					setOpen(false);
					setContest(newContest());
				});
		} else {
			setOpen(false);
		}
	}

	const handleActiveCheck = (ev: React.ChangeEvent<HTMLInputElement>) => {
		setContest({ ...contest, active: ev.target.checked });
	}

	const handleProductIdChanged = (ev: any) => {
		setContest({ ...contest, reward_product_id: ev.target.value })
	}

	const newButton = (
		<Grid item container direction="row" justifyContent="flex-end" spacing={2}>
			<Grid item>
			<Button
				variant="contained"
				color="primary"
				endIcon={<AddIcon />}
				onClick={() => setOpen(true)}
			>
				New Contest
			</Button>
			</Grid>
		</Grid>
	);

	const addForm = (
		<Grid item container direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={1}>
			<Grid item>
				<Typography color="textSecondary" style={{marginBottom: "5px"}}>
					Reward:
				</Typography>
			</Grid>
			<Grid item>
				<Select
					value={contest.reward_product_id}
					onChange={handleProductIdChanged}
					disabled={saving}
					style={{minWidth: 240}}
				>
					{products.map(p => (
						<MenuItem
							value={p.product_id}
						>
							{p.product_description.price_category_single_en} {p.product_description.title_en}
						</MenuItem>
					))}
				</Select>
			</Grid>
			<Grid item>
				<Typography
					color="textSecondary"
					style={{
						marginBottom: "5px",
						marginLeft: "10px",
						marginRight: "-10px",
				}}>
					Active:
				</Typography>
			</Grid>
			<Grid item>
				<Switch
					checked={contest.active}
					onChange={handleActiveCheck}
					disabled={saving}
					inputProps={{ "aria-label": "controlled" }}
				/>
			</Grid>
			<Grid item>
				<Button
					variant="contained"
					color="primary"
					endIcon={<CreateIcon />}
					onClick={() => handleClose(true)}
					disabled={saving}
				>
					Create
				</Button>
			</Grid>
			<Grid item>
				<Button
					variant="outlined"
					onClick={() => handleClose(false)}
					disabled={saving}
				>
					Cancel
				</Button>
			</Grid>
		</Grid>
	);

	return open ? addForm : newButton;
}