import { useEffect, useState } from 'react';

import {
    AdminUser,
	addUserAsAdminV2,
    updateUserAsAdminV2,
} from "../../api/permissions";

import {
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
    Grid,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell, 
    TextField,
    Typography,
 } from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

const newAdminUser = (): AdminUser => {
    return  {
        id: "",
        email: "",
        claims: [],
        created: 0,
        last_login: 0,
    };
};

type AddAdminUserModalProps = {
    claims: string[];
    isOpen: boolean;

    onAdded: () => void;
    onClose: () => void;
}

export const AddAdminUserModal = (props: AddAdminUserModalProps) => {
	const [alertMessage, setAlertMessage] = useState<string>("");
    const [canCommit, setCanCommit] = useState<boolean>(false);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [user, setUser] = useState<AdminUser>(newAdminUser());

    useEffect(() => {
        setUser(newAdminUser());
        setAlertMessage("");
        setIsOpen(props.isOpen);
    }, [props]);

    const handleClose = () => {
        setUser(newAdminUser());
        setIsOpen(false);
        props.onClose();
    };

    const handleEmailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value.trim();
        setUser({...user, email: email});
        setCanCommit(email.length > 0);
    };

    const handlePermissionChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!user) {
            return;
        }

        let newClaims = user.claims;
        if (e.target.checked) {
            newClaims.push(e.target.value);
        } else {
            newClaims = user.claims.filter((x) => x !== e.target.value); 
        }
        setUser({...user, claims: newClaims});
        setCanCommit(user.email.length > 0);
    };

    const handleCommit = () => {
        setAlertMessage("");

        addUserAsAdminV2(user)
            .then(() => {
                handleClose();
                props.onAdded();
            })
            .catch((error) => {
                setAlertMessage("Failed to add user");
            });
        setCanCommit(false);
    };


    return (
		<>
		<Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Add admin user</DialogTitle>
			<DialogContent>
                    <Grid container>
                        {alertMessage &&
                            <Grid item xs={12}>
                                <Alert severity="warning">{alertMessage}</Alert>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                placeholder="E-mail"
                                id="email"
                                label="E-mail"
                                type="email"
                                variant="outlined"
                                fullWidth
                                value={user?.email}
                                onChange={handleEmailChanged}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <AdminUserPermissionsTable user={user} claims={props.claims} onPermissionChanged={handlePermissionChanged} />
                            </Paper>
                        </Grid>
                    </Grid>
			</DialogContent>
			<DialogActions>
				<Grid container justifyContent="space-between">
					<Grid item>
						<Button color="secondary" onClick={handleClose}>
							Cancel
						</Button>
					</Grid>
                        <Grid item xs={3}>
                            <Button
                                disabled={!canCommit}
                                variant="contained"
                                onClick={handleCommit}
                                color="primary">
                                OK
                            </Button>
                        </Grid>
				</Grid>
			</DialogActions>
		</Dialog>
		</>
    );
};

type UpdateAdminUserModalProps = {
    user?: AdminUser;
    claims: string[];
    isOpen: boolean;

    onUpdated: () => void;
    onClose: () => void;
}

export const UpdateAdminUserModal = (props: UpdateAdminUserModalProps) => {
	const [alertMessage, setAlertMessage] = useState<string>("");
    const [canCommit, setCanCommit] = useState<boolean>(false);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [user, setUser] = useState<AdminUser>(newAdminUser());

    useEffect(() => {
        setUser(props.user ? props.user : newAdminUser());
        setIsOpen(props.isOpen);
    }, [props]);

    const handleClose = () => {
        setIsOpen(false);
        props.onClose();
    };

    const handleEmailChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value.trim();
        setUser({...user, email: email});
        setCanCommit(email.length > 0);
    };

    const handlePermissionChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!user) {
            return;
        }

        let newClaims = user.claims;
        if (e.target.checked) {
            newClaims.push(e.target.value);
        } else {
            newClaims = user.claims.filter((x) => x !== e.target.value); 
        }
        setUser({...user, claims: newClaims});
        setCanCommit(user.email.length > 0);
    };

    const handleCommit = () => {
        setAlertMessage("");

        updateUserAsAdminV2(user.id, user)
            .then(() => {
                props.onUpdated();
                handleClose();
            })
            .catch((error) => {
                setAlertMessage("Failed to update user");
            });

        setCanCommit(false);
    };


    return (
		<>
        <Box>
            {alertMessage &&
                <Alert severity="warning">{alertMessage}</Alert>
            }
        </Box>
		<Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Update admin user</DialogTitle>
			<DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                placeholder="E-mail"
                                id="email"
                                label="E-mail"
                                type="email"
                                variant="outlined"
                                fullWidth
                                value={user?.email}
                                onChange={handleEmailChanged}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <AdminUserPermissionsTable user={user} claims={props.claims} onPermissionChanged={handlePermissionChanged} />
                            </Paper>
                        </Grid>
                    </Grid>
			</DialogContent>
			<DialogActions>
				<Grid container justifyContent="space-between">
					<Grid item>
						<Button color="secondary" onClick={handleClose}>
							Cancel
						</Button>
					</Grid>
                        <Grid item xs={3}>
                            <Button
                                disabled={!canCommit}
                                variant="contained"
                                onClick={handleCommit}
                                color="primary">
                                OK
                            </Button>
                        </Grid>
				</Grid>
			</DialogActions>
		</Dialog>
		</>
    );
};

export const AdminUserPermissionsTable = ({user, claims, onPermissionChanged}: {user: AdminUser | undefined, claims: string[], onPermissionChanged: (e: React.ChangeEvent<HTMLInputElement>) => void}) => {
    return (
        <TableContainer>
            <Table aria-label="Claims" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Claim</TableCell>
                        <TableCell>Granted</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {user && 
                        claims.map((claim) => {
                            return (
                            <TableRow>
                                <TableCell width={40}>
                                    <Typography>{claim}</Typography>
                                </TableCell>
                                <TableCell width={50}>
                                    <Checkbox id="claim" value={claim} checked={user.claims.includes(claim)}
                                        onChange={onPermissionChanged} />
                                </TableCell>
                            </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};