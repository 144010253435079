import React, { useEffect, useState } from "react";

import { Loader } from "../loader";
import { Link } from "react-router-dom";

import { 
	Avatar,
	IconButton, 
	Paper, 
	Table, 
	TableHead, 
	TableBody, 
	TableRow, 
	TableCell, 
	Toolbar,
	Typography, 	
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";

import { getOperators, Operator } from "../../api/operators";
import { OperatorIcon } from "../operator_icon";

const useOperatorListStyles = makeStyles((theme) => ({
	filters: {
		marginTop: `${theme.spacing(1)}px`,
		padding: `${theme.spacing(2)}px`,
		backgroundColor: "#f0f0f0",
	},
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(5),
	}
}));

export const ListOperators = () => {
	const classes = useOperatorListStyles();
	const [operators, setOperators] = useState<Operator[]>([]);
	const [loaded, setLoaded] = useState<boolean>(false);

	useEffect(() => {
		getOperators()
			.then((operators)=> { 
				if (operators) {
					setOperators(operators);
					setLoaded(true);
				} 
			});
	}, []);

	if (!loaded) return <Loader />;
	
	return (
		<>
			<Toolbar>
				<Typography variant="h6">Operators</Typography>
			</Toolbar>	
			<Paper className={classes.paper}>
				<Table aria-label="Products">
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							<TableCell>Name</TableCell>
							<TableCell>Code</TableCell>
							<TableCell>BoB participant ID</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{operators.map((operator)=>
							<OperatorItem key={operator.id} operator={operator} />
						)}
					</TableBody>
				</Table>
			</Paper>
		</>
	);
};

const useOperatorItemStyles = makeStyles((theme) => ({
	operatorName: {
		fontWeight: "bold",
		color: "black",
		textDecoration: "none",
	},
	tableRow: {
		textDecoration: "none",
	}
}));

export const OperatorItem = ({ operator }: { operator: Operator }) => {
	const classes = useOperatorItemStyles();

	return (
		<TableRow hover className={classes.tableRow}>
			<TableCell width={30}>
				<Avatar>
					<OperatorIcon operator={operator.code} />
				</Avatar>
			</TableCell>
			<TableCell width={50}  className={classes.operatorName}>
				<Link to={`/operators/${operator.id}`} className={classes.operatorName}>
					{operator.name}
				</Link>
			</TableCell>
			<TableCell>
				<Typography variant="body2">{operator.code}</Typography>
			</TableCell>
			<TableCell>
				<Typography variant="inherit">{operator.bob?.participant_id}</Typography>
			</TableCell>
		</TableRow>
	);
};