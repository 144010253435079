import { baseUrl } from "./config";
import { getToken } from "./index";
import { checkErrors } from "./utils";

export type Permission = {
	id: string;
	permissions: { [key: string]: boolean };
	email: string;
	created: string;
	modified: string;
};
export const getPermissions = async (): Promise<Permission[]> => {
	return fetch(`${baseUrl}/api/v1/permissions`, {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	}).then((response) => response.json());
};

export const addUserAsAdmin = async (email: string): Promise<Permission> => {
	return fetch(`${baseUrl}/api/v1/permissions/add`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
		body: JSON.stringify({ email, permissions: ["admin"] }),
	}).then((response) => response.json());
};

export const removeUserAsAdmin = async (email: string): Promise<Permission> => {
	return fetch(`${baseUrl}/api/v1/permissions/remove`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
		body: JSON.stringify({ email, permissions: ["admin"] }),
	}).then((response) => response.json());
};

export type AdminUser = {
	id: string,
	email: string,
	claims: string[],
	created: number,
	last_login: number,
};

export const getClaims = async (): Promise<string[]> => {
	return fetch(`${baseUrl}/api/v2/permissions/claims`, {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
	.then(checkErrors)
	.then((response) => response.json());
};

export const getPermissionsV2 = async (): Promise<AdminUser[]> => {
	return fetch(`${baseUrl}/api/v2/permissions`, {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
	.then(checkErrors)
	.then((response) => response.json());
};

export const getPermissionV2 = async (id: string): Promise<AdminUser> => {
	return fetch(`${baseUrl}/api/v2/permissions/${id}`, {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
	.then(checkErrors)
	.then((response) => response.json());
};

export const addUserAsAdminV2 = async (user: AdminUser): Promise<AdminUser> => {
	return fetch(`${baseUrl}/api/v2/permissions`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
		body: JSON.stringify(user),
	})
	.then(checkErrors)
	.then((response) => response.json());
};

export const updateUserAsAdminV2 = async (id: string, user: AdminUser): Promise<AdminUser> => {
	return fetch(`${baseUrl}/api/v2/permissions/${id}`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
		body: JSON.stringify(user),
	})
	.then(checkErrors)
	.then((response) => response.json());
};

export const removeUserAsAdminV2 = async (id: string): Promise<void> => {
	return fetch(`${baseUrl}/api/v2/permissions/${id}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
	.then(checkErrors)
	.then((response) => {});
};