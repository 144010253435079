import { baseUrl } from "./config";
import { getToken } from "./index";
import firebase from "firebase/app";
import { PartnerConnections, Reservation } from "./rentals";
import { Order } from "./orders";
import { OrderV2 } from "./orders_v2";
import { Device } from "./device";
import { checkErrors } from "./utils";
import { CustomClaimsKey } from "../components/permissions/PermissionsV2";

export type User = {
	user_id?: string;
	email?: string;
	is_anonymous?: boolean;
	auth_providers?: string;
	display_name?: string;
	has_auth_account?: boolean;
	has_firestore_account?: boolean;
	payment_cards?: PaymentCard[];
	partner_connections?: PartnerConnections;
	orders?: Order[];
	orders_v2?: OrderV2[];
	reservations?: Reservation[];
	devices?: Device[];
};

export type PaymentCard = {
	id: string;
	created: number;
	verified: string;
	issuer: string;
	issuer_country: string;
	masked_pan: string;
	payment_method: string;
	expiry_date: string;
	pan_hash: string;
	issuer_id: string;
	is_default: boolean;
};


export const signIn = (email: string, password: string): Promise<firebase.auth.UserCredential> => {
	// Set to true to run with the auth emulator host
	const useAuthEmulator = false;

	const auth = firebase.auth();
	if (useAuthEmulator) {
		auth.useEmulator('http://localhost:9099');
	}

	return auth
		.signInWithEmailAndPassword(email, password)
		.then((user: firebase.auth.UserCredential) => {
			return Promise.all([user, user.user?.getIdTokenResult()]);
		})
		.then(([user, userToken]) => {
			if (!userToken) {
				return auth.signOut()
					.then(() => {
						throw new Error("Failed to sign in");
					});
			}

			if (!userToken.claims[CustomClaimsKey]) {
				console.log("User does not have admin access");
				return auth.signOut()
					.then(() => {
						throw new Error("User does not have admin access");
					});
			}

			return user;
		});
};

export const signOut = () => {
	console.log("Signing out");
	return firebase.auth().signOut();
};


export const getUser = async (id: string): Promise<User> => {
	return fetch(`${baseUrl}/api/v2/users/${id}`, {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
		.then(checkErrors)
		.then(response => { return response.json() });
};

export const mergeUser = async (srcUserId: string, dstUserId: string): Promise<Response> => {
	return fetch(`${baseUrl}/api/v1/users/${srcUserId}?merge=${dstUserId}`, {
		method: 'PATCH',
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	});
}