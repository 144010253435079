import { baseUrl } from "./config";
import { getToken } from "./index";

enum RentalEventName {
	Started = "start",
	Ended = "end",
}

enum VehicleType {
	Scooter = "scooter",
	Bike = "bike",
	CarPool = "car_pool",
}

type GeoPoint = {
	lat: number;
	lon: number;
	z: number;
};

type RentalHistory = {
	user_id: string;
	id: String;
	event_name: RentalEventName;
	operator: string;
	icon_url: string;
	vehicle_id: string;
	vechicle_code: string;
	vehicle_type: VehicleType;
	rental_id: string;
	rental_order_id: string;
	amount: number;
	duration: number;
	event_location: GeoPoint;
	payment_transaction_completed: boolean;
};

export const getRentalHistory = async (): Promise<Array<RentalHistory>> => {
	return (
		fetch(`${baseUrl}/api/v1/rental_history/recent`, {
			headers: {
				Authorization: `Bearer ${await getToken()}`,
			},
		})
			.then((response) => response.json())
			//HACK: `id` is a timestamp in local time, but missing the trailing Z. Adding that here.
			.then((rentalHistory) => {
				return rentalHistory.map((rental: RentalHistory) => {
					return {
						...rental,
						id: `${rental.id}Z`,
					};
				});
			})
	);
};
