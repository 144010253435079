import React, { useEffect, useState } from "react";
import { getSupportRequests, SupportRequest as SupportRequestType, SupportRequestStatus } from "../../api/support_requests";

import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TableFooter } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { SupportStatus } from "./SupportStatus";
import { Loader } from "../loader";
import { Chip, Grid, FormControl, Select, MenuItem, Button } from "@material-ui/core";

import DateLabel from "../general/DateLabel";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import PlatformIcon from "../general/PlatformIcon";

const useSupportRequestStyles = makeStyles((theme) => ({
	caseNumber: {
		paddingLeft: `${theme.spacing(1)}px`,
		paddingRight: `${theme.spacing(1)}px`,
		cursor: "pointer",
		textDecoration: "none",
		"&:hover": {
			backgroundColor: "#000",
			color: "#fff",
			transition: "0.5s",
		}
	},
	userLink: {
		textDecoration: "none",
		cursor: "pointer",
		color: "#3f51b5",
		"&:hover": {
			color: "#0f0165",	
		},
		"&:visited": {
			color: "#f50057",
			"&:hover": {
				color: "#a50007",	
			},
		},
	},
	tableRow: {
		textDecoration: "none",
	}
	
}));

const useListSupportRequestsStyles = makeStyles((theme) => ({
	filters: {
		marginTop: `${theme.spacing(1)}px`,
		padding: `${theme.spacing(2)}px`,
		backgroundColor: "#f0f0f0",
	},
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(5),
	},
	formControl: {

	},
}));

type State = {
	pageSize: number | undefined;
	from: Date | undefined;
	to: Date | undefined;
	statuses: SupportRequestStatus[] | undefined;
};


export const ListSupportRequests = () => {
	const classes = useListSupportRequestsStyles();
	// const [filters, setFilters] = useState<Filters>({
	// 	status: [SupportRequestStatus.Open, SupportRequestStatus.New],
	// });
	const [savedState] = useState<State | undefined>(() => {
		const savedState = localStorage.getItem("supportRequestListState");
		if (savedState) {
			return JSON.parse(savedState);
		}
		return undefined;
	});
	const [supportRequests, setSupportRequests] = useState<SupportRequestType[]>([]);
	const [loaded, setLoaded] = useState<boolean>(false);
	const [pageSize, setPageSize] = useState<number>(savedState?.pageSize || 50);
	const [lastPageID, setLastPageID] = useState<string>("");
	const [from, setFrom] = useState<Date | undefined>(savedState?.from);
	const [to, setTo] = useState<Date | undefined>(savedState?.to);
	const [statuses, setStatuses] = useState<SupportRequestStatus[]>(savedState?.statuses && savedState.statuses.length > 0 ? savedState.statuses : [SupportRequestStatus.New, SupportRequestStatus.Open]);
	const [moreAvailable, setMoreAvailable] = useState<boolean>(true);

	useEffect(() => {
		if (statuses.length === 0) {
			setSupportRequests([]);
			return
		}
		getSupportRequests({ pageSize: pageSize, statuses: statuses , from: from, to: to, lastPageID: lastPageID})
			.then((newSupportRequests) => {
				if (newSupportRequests.length === 0) {
					setMoreAvailable(false);
					return;
				}
				setSupportRequests(supportRequests.concat(newSupportRequests));
			})
			.then(() => setLoaded(true));
	}, [lastPageID]);

	useEffect(() => {
		let state = {
			pageSize: pageSize,
			statuses: statuses,
			from: from,
			to: to,
		} as State;
		localStorage.setItem("supportRequestListState", JSON.stringify(state));
		if (statuses.length === 0) {
			setSupportRequests([]);
			return
		}
		getSupportRequests({ pageSize: pageSize, statuses: statuses , from: from, to: to, lastPageID: ""})
			.then(setSupportRequests)
			.then(() => setLoaded(true))
			.then(() => setMoreAvailable(true));
	}, [pageSize,from,to,statuses]);

	const toggleStatusFilter = (status: SupportRequestStatus) => {
		const activeStatusFilters = [...statuses];

		var index = activeStatusFilters.indexOf(status);

		index === -1
			? activeStatusFilters.push(status)
			: activeStatusFilters.splice(index, 1);
		setStatuses(activeStatusFilters);
	};

	const handleChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, property: string) => {
		if (event) {
			let value = (event.target as HTMLInputElement).value
			setPageSize(parseInt(value));
		}
	  };

	if (loaded) {
		return (
			<>
				<Paper variant="outlined" className={classes.filters}>
					<Grid container justifyContent="space-between">
						<Grid item>
							<Grid container>								
								<Grid item>
									<FormControlLabel
										label="Open"
										control={
											<Checkbox
												onChange={() => toggleStatusFilter(SupportRequestStatus.Open)}
												checked={statuses.includes(SupportRequestStatus.Open)}
												color="primary"
											/>
										}
									/>
								</Grid>
								<Grid item>
									<FormControlLabel
										label="New"
										control={
											<Checkbox
												onChange={() => toggleStatusFilter(SupportRequestStatus.New)}
												checked={statuses.includes(SupportRequestStatus.New)}
												color="primary"
											/>
										}
									/>
								</Grid>
								<Grid item>
									<FormControlLabel
										label="Closed"
										control={
											<Checkbox
												onChange={() => toggleStatusFilter(SupportRequestStatus.Closed)}
												checked={statuses.includes(SupportRequestStatus.Closed)}
												color="primary"
											/>
										}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
						<FormControl className={classes.formControl}>
							<Select
								labelId="page-size-select-label"
								variant="standard"
								id="page-size-select"
								value={pageSize} onChange={(e)=>handleChange(e,"set_page_size")}>
								<MenuItem value={0}>All cases</MenuItem>
								<MenuItem value={50}>50 cases</MenuItem>
								<MenuItem value={100}>100 cases</MenuItem>
								<MenuItem value={150}>150 cases</MenuItem>
								<MenuItem value={200}>200 cases</MenuItem>
								<MenuItem value={250}>250 cases</MenuItem>
							</Select>
							</FormControl>
						</Grid>
					</Grid>
				</Paper>
				<TableContainer component={Paper} className={classes.paper}>
					<Table aria-label="Support requests" size="small" >
						<TableHead>
							<TableRow>
								<TableCell>Status</TableCell>
								<TableCell>Case Id</TableCell>
								<TableCell>User Id</TableCell>
								<TableCell align="center">Platform</TableCell>
								<TableCell>Version</TableCell>
								<TableCell align="right">Modified</TableCell>
								<TableCell align="right">Created</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{supportRequests.map((request, idx)=>
								<SupportRequest key={idx} supportRequest={request} />
							)}

						</TableBody>
						
						<TableFooter>
							<TableRow>
								<TableCell colSpan={7} align="center">
									{ moreAvailable ?
									<Button variant="contained" color="primary" onClick={() => setLastPageID(supportRequests[supportRequests.length - 1].id)}>
										Load more
									</Button>
									:
									<Typography variant="body2" color="textSecondary" component="p">
										No more cases
									</Typography>
									}
								</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
					
				</TableContainer>
			</>
		);
	}

	return <Loader />;
};

export const SupportRequest = ({ supportRequest }: { supportRequest: SupportRequestType }) => {
	const classes = useSupportRequestStyles();

	const isToday = (timeStamp:number) => {
		if (timeStamp === 0) {
			return false;
		}
		
		const today = new Date();
		const someDate = new Date(timeStamp * 1000);
		
		const isItToday = someDate.getDate() === today.getDate() &&
			someDate.getMonth() === today.getMonth() &&
			someDate.getFullYear() === today.getFullYear();

		return isItToday;
	}

	let modified: number = (supportRequest.modified_at !== 0) ? supportRequest.modified_at : (supportRequest.modified.length !== 0) ? new Date(supportRequest.modified).getTime(): 0;
	let created: number = (supportRequest.created_at !== 0) ? supportRequest.created_at : (supportRequest.created.length !== 0) ? new Date(supportRequest.created).getTime(): 0;

	if (modified === created) {
		modified = 0;
	}

	return (
		<TableRow hover className={classes.tableRow}>
			<TableCell width={40}>
					<SupportStatus status={supportRequest.status} />
			</TableCell>
			<TableCell width={50}>
					<Chip 
						component={Link}
						className={classes.caseNumber} 
						to={`/support-requests/${supportRequest.id}`}
						color="default" 
						size="small" 
						label={supportRequest.case_number} />
			</TableCell>
			<TableCell>
				<Link className={classes.userLink} to={`/users/${supportRequest.user_id}`}>
						{supportRequest.user_id}
				</Link>
			</TableCell>
			<TableCell align="center" width={30}>
				<PlatformIcon platform={supportRequest.app_platform} />
			</TableCell>
			<TableCell width={30}>
				<Typography variant="button">{supportRequest.app_version ? supportRequest.app_version : "N/A"}</Typography>
			</TableCell>
			<TableCell align="right">
				<Typography variant="caption" color={isToday(modified)?"error":"primary"}>
					{modified !== 0 && <DateLabel inputDate={modified} />}
					{modified === 0 && "-"}
				</Typography>
			</TableCell>
			<TableCell align="right">
				<Typography variant="caption" color={isToday(created)?"error":"inherit"}>
					{created !== 0 && <DateLabel inputDate={created} />}
					{created === 0 && "-"}
				</Typography>
			</TableCell>
		</TableRow>
	);
};