import { MouseEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ActiveIcon from "@material-ui/icons/CheckCircleOutline";
import UpcomingIcon from "@material-ui/icons/Alarm";
import DisabledIcon from "@material-ui/icons/HighlightOff";
import ExpiredIcon from "@material-ui/icons/AlarmOff";
import FilterListIcon from "@material-ui/icons/FilterList";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

import type { Campaign, CouponSpec } from "../../api/campaigns";
import { CouponDiscountType } from "../../api/campaigns";

type CampaignListProps = {
	rows: Array<Campaign>;
};

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
});

export const CampaignList = ({ rows }: CampaignListProps) => {
	const classes = useStyles();
	const history = useHistory();

	const handleCampaignClick = (event: MouseEvent<HTMLTableRowElement | HTMLAnchorElement>, id: string) => {
		if (!(event.target instanceof HTMLAnchorElement)) {
			history.push(`/v2/campaigns/${id}`);
		}
	}

	const statusIcon = (status: string) => {
		switch (status) {
			case "inactive":
				return null
			case "active":
				return <ActiveIcon style={{ color: "green" }} />;
			case "upcoming":
				return <UpcomingIcon style={{ color: "gray" }} />;
			case "disabled":
				return <DisabledIcon style={{ color: "red" }} />;
			case "expired":
				return <ExpiredIcon style={{ color: "orange" }} />;
		}
	}

	const specFilter = (spec: CouponSpec) => {
		let filters = [];
		if (spec.operator !== "") {
			filters.push(spec.operator);
		}
		if (spec.transportationType !== "") {
			filters.push(spec.transportationType);
		}

		switch (filters.length) {
			case 0:
				return "<any>";
			default:
				return filters.join(", ");
		}
	}

	const specDiscount = (spec: CouponSpec) => {
		switch (spec.discount.discountType) {
			case CouponDiscountType.Percent:
				return `${spec.discount.percent}%`;
			default:
				const amount = spec.discount.amount / spec.discount.scaleDivider;
				return `${amount} ${spec.discount.currency}`;
		}
	}

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell/>
						<TableCell>
							Start / End
						</TableCell>
						<TableCell>Coupon Specs</TableCell>
						<TableCell align="right">
							Created / Modified
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow
							hover
							onClick={(e) => handleCampaignClick(e, row.id)}
							key={row.id}
							style={{ cursor: "pointer" }}
						>
							<TableCell>
								{statusIcon(row.status)}
							</TableCell>
							<TableCell>
								{format(row.startDate, "yyyy/MM/dd")}<br/>
								<Typography color="textSecondary">
									{format(row.endDate, "yyyy/MM/dd")}
								</Typography>
							</TableCell>
							<TableCell>
								<Table>
									<TableBody>
										{row.couponSpecs.map((spec) => (
											<TableRow key={spec.id}>
												<TableCell>
													<Typography color="textSecondary">
														<FilterListIcon style={{
															fontSize: "small",
															marginRight: "10px",
														}} />
														{specFilter(spec)}
													</Typography>
												</TableCell>
												<TableCell>
													{specDiscount(spec)}
												</TableCell>
												<TableCell>
													limit: {spec.limit}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableCell>
							<TableCell align="right">
								{format(row.created, "yyyy/MM/dd kk:mm:ss")}
								<br/>
								<Typography color="textSecondary">
									{format(row.updated, "yyyy/MM/dd kk:mm:ss")}
								</Typography>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
