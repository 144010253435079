import { baseUrl } from "./config";
import { getToken } from "./index";
import { checkErrors } from "./utils";

export const getOperator = async (operator_code: string): Promise<Operator> => {
	return fetch(`${baseUrl}/api/v2/operators/${operator_code}`, {
			headers: {
				Authorization: `Bearer ${await getToken()}`,
			},
		}).then(checkErrors)
		.then((response) => response.json());
};

export const getOperators = async (): Promise<Operator[] | undefined> => {
	return fetch(`${baseUrl}/api/v2/operators`, {
			headers: {
				Authorization: `Bearer ${await getToken()}`,
			},
		}).then(checkErrors)
		.then((response) => response.json())
		.then((data) => {
			if (data?.operators) return data.operators;
			throw new Error("invalid payload");
		});
};

export const saveOperator = async (operator: Operator): Promise<Response> => {
	return fetch(`${baseUrl}/api/v2/operators/${operator.id}`, {
			method: 'PUT',
			headers: {
				Authorization: `Bearer ${await getToken()}`,
			},
			body: JSON.stringify(operator),
		}).then(checkErrors);
};

export type Operator = {
	id: string;
	code: string;
	name: string;
	icon: string;
	info_url: string;
	bob?: OperatorBoBInfo;
	location?: OperatorLocation;
	price_groups: OperatorPriceGroup[];
	product_types: OperatorProductType[];
	zones: OperatorZone[];

	resources?:  {
		[language:string]: {[key: string]: string}
	};
};

export type OperatorBoBInfo = {
	participant_id?: string;
	bob_version?: string;
}

export type OperatorLocation = {
	latitude?: number;
	longitude?: number;
}

export type OperatorPriceGroup = {
	id: string;
	sort: number;
	bob?: OperatorPriceGroupBoB;
	resources?:  {
		[language:string]: {[key: string]: string}
	};
}

export type OperatorPriceGroupBoB = {
	traveller_category?: string;
}

export type OperatorProductType = {
	id: string;
	sort: number;
	zones?: string[];
	price_groups?: string[];
	resources?:  {
		[language:string]: {[key: string]: string}
	};
}

export type OperatorZone = {
	id: string;
	sort: number;
	time_zone?: string;
	bob?: OperatorZoneBoB;
	resources?:  {
		[language:string]: {[key: string]: string}
	};
}

export type OperatorZoneBoB = {
	zone_ids: string[];
}
