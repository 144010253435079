import { Typography, Box } from "@material-ui/core";
import PaymentIcon from '@material-ui/icons/Payment';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from "@material-ui/core/Toolbar";

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import type { PaymentCard } from "../../api/user";

export const UserPaymentCards = ({ payment_cards = [] }: { payment_cards?: PaymentCard[] }) => {
	if (payment_cards === undefined) {
		return <></>;
	}
	if (Object.keys(payment_cards).length === 0) {
		return <></>;
	}
	return (
		<>
		<Box display="flex" flexDirection="column" flexGrow={1}>
		<Toolbar>
			<Typography variant="h6">
				Cards
			</Typography>
		</Toolbar>
		<TableContainer component={Paper}>
			<Table size="small" aria-label="Payment cards">
				<TableHead>
					<TableRow>
						<TableCell align="left" width={30} />
						<TableCell>Issuer</TableCell>
						<TableCell align="right">Masked PAN</TableCell>
						<TableCell align="right">Expiry</TableCell>
						<TableCell align="right">Country</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
				{payment_cards.map((payment_card) => (
					<TableRow key={payment_card.id} hover>
						<TableCell>
							<PaymentCardAvatar issuer={payment_card.issuer} />
						</TableCell>
						<TableCell>{payment_card.issuer}</TableCell>
						<TableCell align="right">{payment_card.masked_pan}</TableCell>
						<TableCell align="right">{payment_card.expiry_date}</TableCell>
						<TableCell align="right">{payment_card.issuer_country}</TableCell>
					</TableRow>
				))}
				</TableBody>
			</Table>
		</TableContainer>
		</Box>
		</>
	);
};

export const PaymentCardAvatar = ({issuer}:{issuer:string}) => {
	var issuers: { [identifier: string]: string } = {
		"AMERICAN_EXPRESS": "logos_american_express",
		"DINERS_CLUB": "logos_dinerclub",
		"UNION_PAY": "logos_union_pay",
		"DISCOVER": "logos_discover",
		"JCB": "logos_jcb",
		"MASTERCARD": "logos_mastercard",
		"VISA": "logos_visa",
	};

	var selectedIssuer = issuers[issuer.toUpperCase()];
	if (selectedIssuer === undefined) {
		return <Avatar><PaymentIcon /></Avatar>;
	}

	var targetURL = "https://storage.googleapis.com/travis-assets-prod/" + selectedIssuer + "@xxhdpi.png";

	return <><Avatar src={targetURL} /></>;
}