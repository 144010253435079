import { start } from "repl";
import { baseUrl } from "./config";
import { getToken } from "./index";
import { checkErrors, responseError, headersToString } from "./utils";

export type OrderV2 = {
    id: string;
    order_type: string;
    state: string;
    order_lines: OrderLine[];
    price: PriceV2;
    credit_orders?: CreditOrder[];
    payment?: Payment;
    created: number;
    updated: number;
};

export type OrderLine = {
    operator_id: string;
    zone: string;
    product_id: string;
    product_type_id: string;
    price_groups: PriceGroupTuple[];
    price: PriceV2;
};

export type PriceGroupTuple = {
    price_group: string;
    quantity: number;
    price: PriceV2;
};

export type PriceV2 = {
    currency_type: string;
    including_vat: Currency;
    excluding_vat: Currency;
    amount_vat: Currency;
    percent_vat: number;
};

export type Payment = {
    id: string,
    transaction_id: string;
    state: PaymentState;
    amount: number;
    captured: number;
    credited: number;
    currency: string;
    method: string;
    created: number;
    updated: number;
};

export type Currency = {
    currency: string;
    cents: number;
    formatted: string;
};


export type CreditOrder = {
    id: string;
    user_id: string;
    parent_order_id: string;
    creditor_id: string;
    source: string;
    reason: string;
    amount: PriceV2;
    created: number;
    updated: number;
};

export enum OrderState {
    Allocated = "allocated",
    Ready = "ready",
    Unpaid = "unpaid",
    Payment = "payment",
    Fulfillment = "fulfillment",
    Complete = "complete",
    Cancelled = "cancelled",
}

export enum PaymentState {
    Unknown = "unknown",
    Unsettled = "unsettled",
    Settled = "settled",
    Reserved = "reserved",
    Annulled = "annulled",
    Credited = "credited",
    Failed = "failed",
}


export const creditPayment = async (payment: Payment, amount: number, reason: string): Promise<void> => {
    return fetch(`${baseUrl}/api/v2/payments/${payment.id}`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
        body: JSON.stringify({
            source: "Support tool",
            amount: amount,
            reason: reason,
        }),
    })
        .then((res) => {
            checkErrors(res);
        });
};

export const generateReceipts = async (userId: string, from: string, to: string): Promise<void> => {
    console.log("Generate receipts start %s end %s", from, to);
    const fromEncoded = encodeURIComponent(from);
    const toEncoded = encodeURIComponent(to);
    try {
        const response = await fetch(`${baseUrl}/api/v2/users/${userId}/receipts?from=${fromEncoded}&to=${toEncoded}`, {
            headers: {
                Authorization: `Bearer ${await getToken()}`,
            },
        });
    
        // Error handling 
        checkErrors(response);

        if (response.status == 204) {
            throw responseError(new Response('{"error": "No orders found in range"}'), new Error(`Error handling request: No orders found in range`));
        }
                
        // Get the content from the response headers
        const defaultContentFileName = "invoices_travis";
        const headersAsString = headersToString(response.headers);
        const fileName = extractFilename(headersAsString, defaultContentFileName);
        if (!fileName) {
            throw responseError(new Response('{"error": "Unable to download file, check response headers"}'), new Error(`Error handling request: Failed to download receipt`));

        }

        // Retrieve the blob data from the response
        const blobData = await response.blob();

        // Initiate the download
        downloadFile(blobData, fileName);
    } catch (error) {
        throw error
    }
};

function downloadFile(blobData: Blob, fileName: string) {
    const url = URL.createObjectURL(blobData);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = fileName;
    anchor.click();
    URL.revokeObjectURL(url); // Clean up the object URL after download
}

export function extractFilename(headerStr: string, defaultFilename: string): string | null {
    const filenameRegex = /filename="?([^";]+)"?;?/;
    const contentTypeRegex = /content-type: ([^\s;]+)/i;
    // Check if Content-Disposition header exists
    if (headerStr.includes('content-disposition')) {
        const filenameMatch = headerStr.toLowerCase().match(filenameRegex);
        if (filenameMatch && filenameMatch[1]) {
            return filenameMatch[1].trim();
        }
    }

    // Check if Content-type header exists
    if (headerStr.includes('content-type')) {
        const contentTypeMatch = headerStr.match(contentTypeRegex);
        if (contentTypeMatch) {
            const fileType = contentTypeMatch[1].split('/')[1];
            return `${defaultFilename}.${fileType}`;
        }
    }
    return null;
}