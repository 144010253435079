import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import {
	Button,
	Chip,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	InputLabel,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Paper,
	Select,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@material-ui/core";
import {
	AlarmOff as ExpiredIcon,
	CheckCircleOutline as ActiveIcon,
	CheckCircleOutline as UsedIcon,
	Close as InactiveIcon,
	Close as CancelledIcon,
	Delete as DeleteIcon,
	FileCopy as CopyIcon,
	Flare as NewIcon,
	Mail as DeliveredIcon,
	Report as ErrorIcon,
	SvgIconComponent,
} from "@material-ui/icons";
import * as Colors from "@material-ui/core/colors";
import {
	Contest,
	ContestCode,
	ContestCodeStatus,
	getContestCode,
	getContests,
	newContest,
	postCodeBatch,
	putContest,
	putContestCode,
} from "../../api/contest";
import { getProducts, Product } from "../../api/products";
import { ResponseError } from "../../api/utils";

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	}
});

type ContestPageParams = {
	contestId: string;
}

export const ContestPage = () => {
	const classes = useStyles();
	const { contestId } = useParams<ContestPageParams>();

	const [contest, setContest] = useState<Contest>(newContest());
	const [products, setProducts] = useState<Array<Product>>([]);
	const [saving, setSaving] = useState(false);

	useEffect(() => {
		getProducts().then(products =>
			setProducts(products.filter(p => p.type == "flexticket")));
	}, []);

	useEffect(() => {
		setSaving(true);
		getContest()
			.then(() => {
				setSaving(false);
			});
	}, [contestId]);

	const enableDisableContest = () => {
		saveContest({ ...contest, active: !contest.active });
	}

	const getContest = () => {
		return getContests().then(contests => {
			const contest = contests.find(c => c.id == contestId);
			if (contest != null) {
				console.log("got contest", contest);
				setContest(contest);
			}
		});
	}

	const saveContest = (c: Contest) => {
		setSaving(true);
		putContest(c).then(() => {
			getContest().then(() => {
				setSaving(false);
			});
		});
	}

	const codesUpdate = () => {
		setSaving(true);
		return getContest().then(() => {
			setSaving(false);
		})
	}

	const handleRewardChange = (e: any) => {
		const update = { ...contest, reward_product_id: e.target.value };
		console.log("sending update", update);
		saveContest(update);
	}

	const formatCodes = (): string => {
		let total = 0;
		let delivered = 0;
		let used = 0;
		let error = 0;

		for (const code of contest.codes) {
			total++;
			switch (code.status) {
				case ContestCodeStatus.DeliveredContestCode:
					delivered++;
					break;
				case ContestCodeStatus.UsedContestCode:
					used++;
					break;
				case ContestCodeStatus.ErrorContestCode:
					error++;
					break;
			}
		}

		if (delivered > 0 || used > 0 || error > 0) {
			let extras = [];
			if (delivered > 0) {
				extras.push(`${delivered} delivered`);
			}
			if (used > 0) {
				extras.push(`${used} used`);
			}
			if (error > 0) {
				extras.push(`${error} with errors`);
			}

			return `${total} (${extras.join(", ")})`;
		} else {
			return `${total}`;
		}
	}

	const activeItem = () => {
		let icon = null;
		let color = "gray"
		if (contest.active) {
			color = "green";
			icon = (<ActiveIcon style={{ color: color }} />);
		} else {
			color = "gray";
			icon = (<InactiveIcon style={{ color: color }} />);
		}

		return (
			<ListItem>
				<ListItemIcon>
					{icon}
				</ListItemIcon>
				<ListItemText
					primary="Status"
					secondary={contest?.active ? "Active" : "Inactive"}
					style={{ color: color }}
				/>
				<Button
					onClick={() => enableDisableContest()}
					style={{ marginLeft: "30px" }}
					disabled={saving}
				>
					{contest?.active ? "Disable" : "Enable"}
				</Button>
			</ListItem>
		);
	}

	return (
		<Grid container spacing={4}>
			<Grid item container direction="row" wrap="nowrap" justifyContent="space-around">
				<Grid item>
					<List>
						<ListItem>
							<ListItemText
								primary="ID"
								secondary={contest.id}
							/>
						</ListItem>
						<ListItem>
							<FormControl>
								<InputLabel>Reward</InputLabel>
								<Select
									value={contest.reward_product_id}
									disabled={saving}
									style={{minWidth: 240}}
									onChange={handleRewardChange}
									label="Reward"
								>
									{products.map(p => (
										<MenuItem value={p.product_id}>
											{p.product_description.price_category_single_en} {p.product_description.title_en}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</ListItem>
						<ListItem>
							<ListItemText
								primary="Codes"
								secondary={formatCodes()}
							/>
						</ListItem>
					</List>
				</Grid>
				<Grid item>
					<List>
						<ListItem>
							<ListItemText
								primary="Created"
								secondary={format(contest?.created, "yyyy/MM/dd kk:mm:ss")}
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								primary="Updated"
								secondary={format(contest?.updated, "yyyy/MM/dd kk:mm:ss")}
							/>
						</ListItem>
						{activeItem()}
					</List>
				</Grid>
			</Grid>
			<Grid item style={{ width: "100%", paddingBottom: 0 }}>
				<AddGetCodes contest={contest} onUpdate={codesUpdate} />
			</Grid>
			<Grid item style={{ width: "100%" }}>
				<ContestCodeList contest={contest} onUpdate={codesUpdate} />
			</Grid>
		</Grid>
	);
}

type AddGetCodesProps = {
	contest: Contest;
	onUpdate: () => void;
}

export const AddGetCodes = ({ contest, onUpdate }: AddGetCodesProps) => {
	const [create, setCreate] = useState(false);
	const [fetching, setFetching] = useState(false);
	const [saving, setSaving] = useState(false);
	const [code, setCode] = useState("");
	const [numCodes, setNumCodes] = useState(10);
	const [error, setError] = useState<ResponseError | null>(null);

	const handleCreateCheck = () => {
		setCreate(!create);
	}

	const handleGetCodeClick = () => {
		setFetching(true);
		setError(null);
		getContestCode(contest.id, create)
			.then((c) => {
				setCode(c.code);
				setFetching(false);
				onUpdate();
			}, ((err: ResponseError) => {
				setCode("");
				setError(err);
				setFetching(false);
			}));
	}

	const handleCodeClick = () => {
		let tempTextArea = document.createElement('textarea');
		tempTextArea.innerText = code;
		document.body.appendChild(tempTextArea);
		tempTextArea.select();
		document.execCommand('copy');
		tempTextArea.remove();
	}

	const handleAddCodes = () => {
		setSaving(true);
		postCodeBatch(contest.id, numCodes)
			.then(() => {
				onUpdate();
				setSaving(false);
			});
	}

	return (
		<Grid container direction="row">
			<Grid item container alignItems="center" xs={6}>
				<FormControlLabel
					control={(<Switch
						checked={create}
						onChange={handleCreateCheck}
						disabled={fetching}
						inputProps={{ "aria-label": "controlled" }}
					/>)}
					label="Force create"
				/>
				<Button
					style={{ marginRight: "10px" }}
					onClick={handleGetCodeClick}
					disabled={fetching}
				>
					Get Code
				</Button>
				{code != "" &&
					<Chip
						label={code}
						variant="outlined"
						onClick={handleCodeClick}
						onDelete={handleCodeClick}
						deleteIcon={<CopyIcon />}
						disabled={fetching}
					/>
				}
				{error &&
					<Chip
						label={error.response.status == 404 ? "No available codes" : error.err.message}
						variant="outlined"
						style={{
							color: error.response.status == 404 ? "orange" : "red",
							borderColor: error.response.status == 404 ? "orange" : "red",
						}}
					/>
				}
			</Grid>
			<Grid container item alignItems="baseline" justifyContent="flex-end" xs={6}>
				<span>Add Codes:</span>
				<TextField
					label="Amount"
					type="number"
					InputLabelProps={{ shrink: true }}
					value={numCodes}
					disabled={saving}
					onChange={
						(e) => {
							setNumCodes(parseInt(e.target.value));
						}
					}
					style={{ width: "60px", margin: "0 10px" }}
				/>
				<Button
					onClick={handleAddCodes}
					disabled={saving}
					color="primary"
					variant="contained"
				>
					Add
				</Button>
			</Grid>
		</Grid>
	);
}

type ContestCodeListProps = {
	contest: Contest;
	onUpdate: () => Promise<void>;
}
export const ContestCodeList = ({ contest, onUpdate }: ContestCodeListProps) => {
	const classes = useStyles();

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table}>
				<TableHead>
					<TableRow>
						<TableCell>
							Code
						</TableCell>
						<TableCell />
						<TableCell>
							Status
						</TableCell>
						<TableCell>
							User / Reward ID
						</TableCell>
						<TableCell align="right">
							Created / Updated
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{contest.codes.map(code => <ContestCodeRow code={code} onUpdate={onUpdate} />)}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

type ContestCodeRowProps = {
	code: ContestCode;
	onUpdate: () => Promise<void>;
}

export const ContestCodeRow = ({ code, onUpdate }: ContestCodeRowProps) => {
	const [updating, setUpdating] = useState(false);

	const handleOnCancel = () => {
		setUpdating(true);
		const codeUpdate = { ...code, status: ContestCodeStatus.CancelledContestCode };
		putContestCode(codeUpdate.contest_id, codeUpdate)
			.then(() => {
				onUpdate().then(() => {
					setUpdating(false);
				});
			});
	}

	const handleOnRedeem = () => {
		setUpdating(true);
	}

	const statusItem = (code?: ContestCode) => {
		if (!code) {
			return null
		}

		let icon: SvgIconComponent | undefined = undefined;
		let color: string = Colors.blueGrey[300];
		let text = "";
		let bottomText = "";
		switch (code.status) {
			case ContestCodeStatus.NewContestCode:
				text = "New";
				color = Colors.cyan[300];
				icon = NewIcon;
				break;
			case ContestCodeStatus.DeliveredContestCode:
				if (new Date() > code.expires) {
					text = "Expired";
					bottomText = format(code.expires, "yyyy/MM/dd kk:mm:ss");
					color = Colors.amber[400];
					icon = ExpiredIcon
				} else {
					text = "Delivered";
					bottomText = `expires ${format(code.expires, "yyyy/MM/dd kk:mm:ss")}`;
					color = Colors.teal[300];
					icon = DeliveredIcon
				}
				break;
			case ContestCodeStatus.UsedContestCode:
				text = "Used";
				color = Colors.green[300];
				icon = UsedIcon
				break;
			case ContestCodeStatus.ErrorContestCode:
				text = "Error";
				bottomText = code.reason;
				color = Colors.deepOrange[500];
				icon = ErrorIcon
				break;
			case ContestCodeStatus.CancelledContestCode:
				text = "Cancelled";
				color = Colors.blueGrey[300];
				icon = CancelledIcon
				break;
		}

		if (updating) {
			color = Colors.grey[400];
		}

		const iconElement = React.createElement(
			icon,
			{
				style: {
					color: color,
					verticalAlign: "middle",
					marginRight: "5px",
				}
			},
		)

		return (
			<TableCell>
				<Grid container direction="row" wrap="nowrap" alignItems="center">
					<Grid item>{iconElement}</Grid>
					<Grid item container direction="column">
						<Grid item style={{ color: color }}>
							{text}
						</Grid>
						{bottomText != "" &&
							<Grid item style={{ color: color }}>
								{bottomText}
							</Grid>
						}
					</Grid>
				</Grid>
			</TableCell>
		)
	}

	const showCancel = (code.status == ContestCodeStatus.NewContestCode || (
		code.status == ContestCodeStatus.DeliveredContestCode &&
		new Date() <= code.expires
	));

	return (
		<TableRow>
			<TableCell>
				{code.code}
			</TableCell>
			<TableCell style={{ padding: "16px 0" }}>
				{showCancel &&
					<IconButton
						onClick={handleOnCancel}
						disabled={updating}
					>
						<DeleteIcon />
					</IconButton>
				}
			</TableCell>
			{statusItem(code)}
			<TableCell>
				<Link to={`/users/${code.user_id}`}>
					{code.user_id}
				</Link>
				<Typography color={"textSecondary"}>
					{code.reward_id}
				</Typography>
			</TableCell>
			<TableCell align="right">
				{format(code?.created, "yyyy/MM/dd kk:mm:ss")}
				<Typography color="textSecondary">
					{format(code?.updated, "yyyy/MM/dd kk:mm:ss")}
				</Typography>
			</TableCell>
		</TableRow>
	)
}