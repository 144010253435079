import { Product } from "./products";

export enum OrderStatus {
	Unknown = -1,
	Placed = 0,
	Registered = 1,
	Authorized = 2,
	Completed = 3,
	Unpaid = 11,
	Credited = 98,
	Cancelled = 99,
}

export type Price = {
	amount: number;
	currency: string;
	vat_amount: number;
	vat_percent: number;
	total: number;
};

export type OrderItem = {
	type: string;
	price: Price;
	ref_id?: string;
};

export type Order = {
	id: string;
	order_items?: OrderItem[];
	transaction_id?: string;
	status: OrderStatus;
	error_code?: number;
	created: number;
	updated: number;
	price: Price;
	products: Product[];
	payment_method: string;
	platform?: string;
	masked_pan?: string;
};
