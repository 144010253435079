import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { moveFlexTicket } from "../../api/flextickets";

import {
    Dialog, 
    DialogTitle,
    DialogActions,
    Button,
    Typography,
    Box
} from "@material-ui/core";

import { Loader } from "../loader";
import { UserDevicesSelect } from "./UserDevicesSelect"

import { getUser, User } from "../../api/user";

type SelectDeviceProps = {
    isOpen: boolean;
    userId: string;
    flexTicketId: string;
    fromDeviceId: string;
    onClose: (orderId: string, device: string | undefined) => void;
}


export const FlexTicketMove = ({isOpen, userId, flexTicketId, fromDeviceId, onClose}: SelectDeviceProps) => {
    const params = useParams<{ user_id: string }>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [statusTitle, setStatusTitle] = useState<string>("");
    const [statusMessage, setStatusMessage] = useState<string>("");
    const [user, setUser] = useState<User>({});
    const [toDeviceId, setToDeviceId] = useState<string>(fromDeviceId);

    const clearStatus = () => {
        setStatusTitle("");
        setStatusMessage("");
    }

    useEffect(() => {
        setIsLoading(true);
        getUser(params.user_id)
            .then((usr)=>{
                // If we only get one key in the resulting dictionary,
                // it is most likely not a real user object - clear it out.
                if (Object.keys(usr).length === 1) {
                    usr = {};
                    setStatusTitle("Could not find user");
                    setStatusMessage("")
                } 
                
                setUser(usr);
            })
            .then(() => setIsLoading(false))
            .catch(err=>{
                setUser({});
                setStatusTitle("Could not find user");
                if (err.response) {
                    setStatusMessage("'" + err.response.statusText + "' occured while attempting to get user '" + params.user_id + "'");
                }
                
                setIsLoading(false);
            });

    }, [params.user_id]);

    const handleClose = () => {
        onClose(flexTicketId, undefined);
    }
    
    const handleMoveTicket = () => {
        setIsLoading(true);
        moveFlexTicket(userId, flexTicketId, fromDeviceId, toDeviceId).then((res) => {
            onClose(flexTicketId, res?.device?.id);
            setIsLoading(false);
        }).catch((err) => {
            setIsLoading(false);
            setStatusTitle("Could not move ticket");
            setStatusMessage("Error: " + err.err ? err.err.message : err.message);
        });
    }

    const deviceIdSelected = (deviceId: string) => {
        setToDeviceId(deviceId);
    }

    return (<>
        {isOpen && 
        <Dialog fullWidth maxWidth="xl" open={isOpen} onClose={handleClose}>
            <DialogTitle>
                {isLoading && <Loader />}
                {statusTitle && <Typography variant="h6">{statusTitle}</Typography>}
                {(!isLoading && !statusTitle) && <Typography variant="h6">Move Flex Ticket</Typography>}
            </DialogTitle>
            {statusMessage && 
                <><Box m={4}><Typography variant="body1" component="h2">{"" + statusMessage}</Typography></Box></> 
            }
            {(!statusMessage && !statusTitle) && <UserDevicesSelect currentDeviceId={fromDeviceId}  devices={user.devices} onSelectDevice={deviceIdSelected}/>}
            <DialogActions>
                {(statusMessage || statusTitle) && <Button onClick={clearStatus}>OK</Button>}
                {!statusMessage &&
                    <Button onClick={handleMoveTicket} color="primary" disabled={toDeviceId === undefined || toDeviceId === "" || toDeviceId === fromDeviceId}>
                        Move
                    </Button>
                }
                {!statusMessage && 
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                }
                
            </DialogActions>

        </Dialog>   
        
        } 
    </>)
}