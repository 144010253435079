import React, { SetStateAction, useEffect, useState } from "react";

import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Loader } from "../loader";

import { useParams } from "react-router-dom";
import { getProducts, saveProduct, Product, ProductDescription } from "../../api/products";
import { Button, makeStyles, TextField } from "@material-ui/core";
import { Grid, Tabs, Tab, Box } from "@material-ui/core";
import { DurationPicker } from "./DurationPicker";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Switch from "@material-ui/core/Switch";


const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(5),
	},
	footer: {
		backgroundColor: theme.palette.grey[100],
		padding: `${theme.spacing(2)}px`,
		textAlign: 'right',
	},
	tabs: {
		borderBottom: "1px solid #ccc",
	},
	tabPanel: {
		padding: `${theme.spacing(2)}px`,
	}
}));


export const ProductDetail = () => {
	const classes = useStyles();
	const params = useParams<{ product_id: string }>();
	const [products, setProducts] = useState<Product[]>([]);
	const [product, setProduct] = useState<Product>();
	const [productDescription, setProductDescription] = useState<ProductDescription>({});
	const [loaded, setLoaded] = useState<boolean>(false);
	const [tabIndex, setTabIndex] = useState<number>(0);
	const [canSave, setCanSave] = useState<boolean>(false);

	const handleChange = (event: any, value: SetStateAction<number>) => {
	  setTabIndex(value);
	};
	
	const onChangeProductDescriptionText = (ev: React.ChangeEvent<HTMLInputElement>) => {
		if (!product) {
			return;
		}

		const newDescription = {...productDescription, [ev.target.id]: ev.target.value};
		setProductDescription(newDescription);
		setCanSave(true);
	}

	const onClickSave = () => {
		if (!product) {
			return;
		}

		const newProduct = {...product, product_description: productDescription};

		saveProduct(newProduct)
			.then((res) => {
				setProduct(newProduct);
				setCanSave(false);
			})
			.catch((err) => {
				console.log('failed to update product, ', err);
				alert('Failed to update product');
			});
	};

	useEffect(() => {
		getProducts()
			.then((products) => {
				setProducts(products);
				products.map((p)=>{
					if (p.product_id === params.product_id) {
						setProduct(p);
						setProductDescription(p.product_description);
					}
					return p;
				});
				setLoaded(true)
			});
	}, [params.product_id]);

	if (loaded) {
		return (
			<>
				<Toolbar>
					<Typography variant="h6">Product {product?.name}</Typography>
				</Toolbar>	
				<Paper className={classes.paper}>
					<Tabs
						onChange={handleChange}
						value={tabIndex}
						indicatorColor="primary"
						textColor="primary"
						variant="standard"
						className={classes.tabs}
						aria-label="product information"
						>
						<Tab label="Info" />
						<Tab label="Operator" />
						<Tab label="Price" />
						<Tab label="Description" />
						<Tab label="Flex settings" />
						<Tab label="BoB settings" />
						<Tab label="Urgency" />
					</Tabs>
					<Box className={classes.tabPanel} hidden={(tabIndex !== 0)}>
						<Grid container spacing={4}>
							<Grid item sm={4}>
								<TextField id="name" label="Name" value={product?.name} />
							</Grid>
							<Grid item sm={4}>
								<TextField id="group" label="Group" value={product?.group} />
							</Grid>
							<Grid item sm={4}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Type</FormLabel>
									<RadioGroup aria-label="product.type.label" name="product.type" value={product?.type} row>
										<FormControlLabel value="ticket" control={<Radio />} label="Ticket" />
										<FormControlLabel value="flexticket" control={<Radio />} label="Flexticket" />
									</RadioGroup>
								</FormControl>
							</Grid>
							<Grid item sm={12}>
								<TextField id="name" label="Max quantity" value={product?.product_settings?.max_quantity} />
							</Grid>
							<Grid item sm={12}>
								<FormControl component="fieldset">
									<FormControlLabel
										control={<Switch checked={product?.product_settings?.is_active} name="product.product_settings.is_active" />}
										label="Activated"
										/>									
								</FormControl>
							</Grid>
							<Grid item sm={12}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Available from</FormLabel>
									<TextField type="datetime-local" aria-describedby="product.product_settings.available_from.helper" id="product_settings.available_from" value={product?.product_settings?.available_from} />
									<FormHelperText id="product_settings.available_from.helper">Timestamp to control when a product is made available</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item sm={12}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Activation duration</FormLabel>
									<DurationPicker aria-describedby="product.product_settings.activation_period_length.helper" secs={product?.product_settings?.activation_period_length} />
									<FormHelperText id="product_settings.activation_period_length.helper">Duration after purchase when the product must be activated within</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item sm={12}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Validity duration</FormLabel>
									<DurationPicker aria-describedby="product.validity_period.helper" secs={product?.validity_period} />
									<FormHelperText id="validity_period.helper">Duration the product is valid from the moment it is activated</FormHelperText>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
					<Box className={classes.tabPanel} hidden={(tabIndex !== 1)}>
						<Grid container spacing={4}>
							<Grid item sm={12}>
								<TextField id="operator.name" label="Name" value={product?.operator?.name} />
							</Grid>
      						<Grid item sm={12}>
								<TextField id="operator.code" label="Code" value={product?.operator?.code} />
							</Grid>
							<Grid item sm={12}>
								<TextField id="operator.icon" label="Icon" value={product?.operator?.icon} />
							</Grid>
						</Grid>
					</Box>
					<Box className={classes.tabPanel} hidden={(tabIndex !== 2)}>
						<Grid container spacing={4}>
							<Grid item sm={12}>
								<TextField id="price.currency" label="Currency" value={product?.price?.currency} />
							</Grid>
      						<Grid item sm={3}>
								<TextField fullWidth id="price.amount" label="Amount" value={product?.price?.amount} />
							</Grid>
							<Grid item sm={3}>
								<TextField fullWidth id="price.vat_percent" label="VAT percent" value={product?.price?.vat_percent} />
							</Grid>
							<Grid item sm={3}>
								<TextField fullWidth id="price.vat_amount" label="VAT amount" value={product?.price?.vat_amount} />
							</Grid>
							<Grid item sm={3}>
								<TextField fullWidth id="price.total" label="Total" value={product?.price?.total} />
							</Grid>
						</Grid>

					</Box>
					<Box className={classes.tabPanel} hidden={(tabIndex !== 3)}>
						<Grid container spacing={4}>
							<Grid item sm={6}>
								<TextField fullWidth id="title_en" label="Title (en)" defaultValue={product?.product_description?.title_en} onChange={onChangeProductDescriptionText} />
							</Grid>
      						<Grid item sm={6}>
								<TextField fullWidth id="title_sv" label="Title (sv)" defaultValue={product?.product_description?.title_sv} onChange={onChangeProductDescriptionText} />
							</Grid>
							<Grid item sm={6}>
								<TextField fullWidth multiline id="description_en" label="Description (en)" defaultValue={product?.product_description?.description_en} onChange={onChangeProductDescriptionText} />
							</Grid>
							<Grid item sm={6}>
								<TextField fullWidth multiline id="description_sv" label="Description (sv)" defaultValue={product?.product_description?.description_sv} onChange={onChangeProductDescriptionText} />
							</Grid>
							<Grid item sm={6}>
								<TextField fullWidth multiline id="short_description_en" label="Short Description (en)" defaultValue={product?.product_description?.short_description_en} onChange={onChangeProductDescriptionText} />
							</Grid>
							<Grid item sm={6}>
								<TextField fullWidth multiline id="short_description_sv" label="Short Description (sv)" defaultValue={product?.product_description?.short_description_sv} onChange={onChangeProductDescriptionText} />
							</Grid>
							<Grid item sm={6}>
								<TextField fullWidth multiline id="long_description_en" label="Long Description (en)" defaultValue={product?.product_description?.long_description_en} onChange={onChangeProductDescriptionText} />
							</Grid>
							<Grid item sm={6}>
								<TextField fullWidth multiline id="long_description_sv" label="Long Description (sv)" defaultValue={product?.product_description?.long_description_sv} onChange={onChangeProductDescriptionText} />
							</Grid>
							<Grid item sm={6}>
								<TextField fullWidth multiline id="operator_description_en" label="Operator Description (en)" defaultValue={product?.product_description?.operator_description_en} onChange={onChangeProductDescriptionText} />
							</Grid>
							<Grid item sm={6}>
								<TextField fullWidth multiline id="operator_description_sv" label="Operator Description (sv)" defaultValue={product?.product_description?.operator_description_sv} onChange={onChangeProductDescriptionText} />
							</Grid>
							<Grid item sm={6}>
								<TextField fullWidth id="activate_action_title_en" label="Activation action title (en)" defaultValue={product?.product_description?.activate_action_title_en} onChange={onChangeProductDescriptionText} />
							</Grid>
      						<Grid item sm={6}>
								<TextField fullWidth id="activate_action_title_sv" label="Activation action title (sv)" defaultValue={product?.product_description?.activate_action_title_sv} onChange={onChangeProductDescriptionText} />
							</Grid>
							<Grid item sm={3}>
								<TextField fullWidth id="price_category_single_en" label="Price category single (en)" defaultValue={product?.product_description?.price_category_single_en} onChange={onChangeProductDescriptionText} />
							</Grid>
							<Grid item sm={3}>
								<TextField fullWidth id="price_category_multiple_en" label="Price category multiple (en)" defaultValue={product?.product_description?.price_category_multiple_en} onChange={onChangeProductDescriptionText} />
							</Grid>
							<Grid item sm={3}>
								<TextField fullWidth id="price_category_single_sv" label="Price category single (sv)" defaultValue={product?.product_description?.price_category_single_sv} onChange={onChangeProductDescriptionText} />
							</Grid>
							<Grid item sm={3}>
								<TextField fullWidth id="price_category_multiple_sv" label="Price category multiple (sv)" defaultValue={product?.product_description?.price_category_multiple_sv} onChange={onChangeProductDescriptionText} />
							</Grid>
						</Grid>
					</Box>
					<Box className={classes.tabPanel} hidden={(tabIndex !== 4)}>
						<Grid container spacing={4}>
							<Grid item sm={12}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Travel periods included</FormLabel>
									<TextField aria-describedby="product.product_settings.bob_product_title.helper" id="product_settings.travel_periods_included" value={product?.product_settings?.travel_periods_included} />
									<FormHelperText id="product_settings.travel_periods_included.helper">Amount of travel periods included in this product</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item sm={6}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Travel period length</FormLabel>
									<DurationPicker aria-describedby="product.product_settings.travel_period_length.helper" secs={product?.product_settings?.travel_period_length} />
									<FormHelperText id="product_settings.travel_period_length.helper">The duration of a single travel period</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item sm={6}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Travel period, overlap length</FormLabel>
									<DurationPicker aria-describedby="product.product_settings.travel_period_overlap_length.helper" secs={product?.product_settings?.travel_period_overlap_length} />
									<FormHelperText id="product_settings.travel_period_overlap_length.helper">The duration of the overlap for a single travel period</FormHelperText>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
					<Box className={classes.tabPanel} hidden={(tabIndex !== 5)}>
						<Grid container spacing={4}>
							<Grid item sm={12}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Product Title</FormLabel>
									<TextField aria-describedby="product.product_settings.bob_product_title.helper" id="product_settings.bob_product_title" value={product?.product_settings?.bob_product_title} />
									<FormHelperText id="product_settings.bob_product_title.helper">Uniquely identifies BoB-product that corresponds to this Travis-product</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item sm={6}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Product Usage Category</FormLabel>
									<RadioGroup aria-label="Product usage category" name="product.product_settings.bob_product_usage_category" value={product?.product_settings?.bob_product_usage_category} row>
										<FormControlLabel value="" control={<Radio />} label="Regular (75 min)" />
										<FormControlLabel value="1030" control={<Radio />} label="10/30 days" />
										<FormControlLabel value="30d" control={<Radio />} label="30 days" />
									</RadioGroup>
									<FormHelperText id="product_settings.bob_product_usage_category.helper">Usage category groups together BoB-products by their intended usage.</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item sm={6}>
								<FormControl component="fieldset">
									<FormLabel component="legend">BoB Traveller Category</FormLabel>
									<RadioGroup aria-describedby="product.product_settings.bob_product_category.helper" name="product.product_settings.bob_product_category" value={product?.product_settings?.bob_product_category} row>
										<FormControlLabel value="a" control={<Radio />} label="Adult" />
										<FormControlLabel value="r" control={<Radio />} label="Reduced" />
									</RadioGroup>
									<FormHelperText id="product_settings.bob_product_category.helper">Used to denote traveller category in BoB</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item sm={6}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Validity duration</FormLabel>
									<DurationPicker aria-describedby="product.product_settings.bob_product_validity_length.helper" secs={product?.product_settings?.bob_product_validity_length} />
									<FormHelperText id="product_settings.bob_product_validity_length.helper">Something something right here.</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item sm={6}>
								<FormControl component="fieldset">
									<FormLabel component="legend">Grace period duration</FormLabel>
									<DurationPicker aria-describedby="product.product_settings.bob_product_grace_period_length.helper" secs={product?.product_settings?.bob_product_grace_period_length} />
									<FormHelperText id="product_settings.bob_product_grace_period_length.helper">Duration from when a renewal of a ticket is possible.</FormHelperText>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
					<Box className={classes.tabPanel} hidden={(tabIndex !== 6)}>
						<Grid container spacing={4}>
							<Grid item sm={12}>
								<Typography variant="h6">Urgency state properties</Typography>
								These properties are used to calculate timestamps for the urgency state.<br/>
								All calculations are performed by subtracting the duration value from an expiration timestamp.<br/>
							</Grid>
							<Grid item sm={12}>
								<FormControl component="fieldset">
									<FormLabel component="legend">
										Urgency timestamp, pre-activation
									</FormLabel>
									<DurationPicker aria-describedby="product.product_settings.urgent_after_pre_activation.helper" secs={product?.product_settings?.urgent_after_pre_activation} />
									<FormHelperText id="product_settings.urgent_after_pre_activation.helper">
										Duration value.<br/>
										This value is used to calculate the urgency timestamp after a product has been purchased.<br/>
										This specific property is only relevant for manually activated products, such as flex-tickets.
									</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item sm={12}>
								<FormControl component="fieldset">
									<FormLabel component="legend">
										Urgency timestamp, post-activation
									</FormLabel>
									<DurationPicker aria-describedby="product.product_settings.urgent_after_post_activation.helper" secs={product?.product_settings?.urgent_after_post_activation} />
									<FormHelperText id="product_settings.urgent_after_post_activation.helper">
										Duration value.<br/>
										The value is used to calculate the urgency timestamp after a product has been activated.<br/>
										This specific property also applies to all products that are auto-activated, such as single-tickets.
									</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item sm={12}>
								<FormControl component="fieldset">
									<FormLabel component="legend">
										Urgency timestamp for travel periods
									</FormLabel>
									<DurationPicker aria-describedby="product.product_settings.travel_period_urgent_after_length.helper" secs={product?.product_settings?.travel_period_urgent_after_length} />
									<FormHelperText id="product_settings.travel_period_urgent_after_length.helper">
										Duration value.<br/>
										The value is used to calculate the urgency timestamp for a travel period after activation.<br/>
										This specific property is only relevant to products that have travel periods.
									</FormHelperText>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
					{/* <Box className={classes.tabPanel} hidden={(tabIndex !== 7)}>
						<Grid container spacing={4}>
							<Grid item sm={4}>
								<Grid container spacing={4}>
									<Grid item sm={12}>
										<FormControl component="fieldset">
											<FormControlLabel
												control={<Switch checked={product?.product_settings?.is_refundable} name="product.product_settings.is_refundable" />}
												label="Allow refund"
												/>									
										</FormControl>
									</Grid>
									<Grid item sm={12}>
									<FormControl component="fieldset">
										<FormLabel component="legend">Refund period length</FormLabel>
										<DurationPicker aria-describedby="product.product_settings.refund_period_length.helper" secs={product?.product_settings?.refund_period_length} />
										<FormHelperText id="product_settings.refund_period_length.helper">Duration from purchase for when a refund is permitted.</FormHelperText>
									</FormControl>
									</Grid>
								</Grid>
							</Grid>
							<Grid item sm={4}>
								<Grid container spacing={4}>
									<Grid item sm={12}>
										<FormControl component="fieldset">
											<FormControlLabel
												control={<Switch checked={product?.product_settings?.should_auto_refund} name="product.product_settings.should_auto_refund" />}
												label="Automatic refund"
												/>									
										</FormControl>
									</Grid>
									<Grid item sm={6}>
										<FormControl component="fieldset">
											<FormLabel component="legend">Automatic refund period length</FormLabel>
											<DurationPicker aria-describedby="product.product_settings.auto_refund_period_length.helper" secs={product?.product_settings?.auto_refund_period_length} />
											<FormHelperText id="product_settings.auto_refund_period_length.helper">Duration for when refund is automatically performed after purchase.</FormHelperText>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box> */}
					<Box className={classes.footer} color="text.primary">
						<Button disabled={!canSave} variant="contained" color="primary" onClick={onClickSave}>
							Save
						</Button>
					</Box>
				</Paper>
			</>
		);
	}

	return <Loader />;
};

// function TabPanel(props) {
// 	const { children, value, index, ...other } = props;
  
// 	return (
// 	  <div
// 		role="tabpanel"
// 		hidden={value !== index}
// 		id={`simple-tabpanel-${index}`}
// 		aria-labelledby={`simple-tab-${index}`}
// 		{...other}
// 	  >
// 		{value === index && (
// 		  <Box p={3}>
// 			<Typography>{children}</Typography>
// 		  </Box>
// 		)}
// 	  </div>
// 	);
//   }
