import React from "react";

import Chip from "@material-ui/core/Chip";
import { styled } from "@material-ui/core/styles";
import { SupportRequestStatus } from "../../api/support_requests";

const OpenChip = styled(Chip)({
	backgroundColor: "#449944",
	color: "#eee",
});
const ClosedChip = styled(Chip)({
	backgroundColor: "#aaa",
});

export const SupportStatus = ({ status }: { status: SupportRequestStatus }) => {
	switch (status) {
		case SupportRequestStatus.New:
			return <Chip color="primary" size="small" label={status} />;
		case SupportRequestStatus.Open:
			return <OpenChip size="small" label={status} />;
		case SupportRequestStatus.Closed:
			return <ClosedChip size="small" label={status} />;
		default:
			return <Chip color="secondary" size="small" label={status} />;
	}
};
