import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
	getSupportRequest,
	putSupportRequest,
	SupportRequestUpdate,
	SupportRequest as SupportRequestType
} from "../../api/support_requests";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip"

import { Loader } from "../loader";

import DateLabel from "../general/DateLabel";
import SaveIcon from "@material-ui/icons/Save";
import CheckIcon from "@material-ui/icons/Check";
import OpenIcon from "@material-ui/icons/Edit";
import AccountIcon from "@material-ui/icons/AccountBox";
import PlatformIcon from "../general/PlatformIcon";
import { Link } from "react-router-dom";
import { SupportRequestNotes } from "./SupportRequestNotes";

export const SupportRequest = () => {
	const { id } = useParams<{ id: string }>();
	const [supportRequest, setSupportRequest] = useState<SupportRequestType>();
	const [isLoaded, setIsLoaded] = useState<boolean>(false);
	const [formData, setFormData] = useState<SupportRequestUpdate>({
		status: "",
		note: "",
	});
	const [noteText, setNoteText] = useState<string>("");
	
	useEffect(() => {
		getSupportRequest(id)
			.then((data)=>{
				setSupportRequest(data);
				setIsLoaded(true);
			});
	}, [id]);

	useEffect(() => {
		setFormData({
			status: supportRequest?.status,
			note: supportRequest?.note,
		});
	}, [supportRequest?.note, supportRequest?.status]);

	const handleSaveForm = () => {
		putSupportRequest(id, { note: noteText, status: supportRequest?.status })
		.then(setSupportRequest)
		.then((a)=>{
			setNoteText("");
		})
	};

	const handleNoteChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
		setNoteText(ev.target.value);
	};

	const handleResolveClick = () => {
		putSupportRequest(id, { note: noteText, status: "Closed" }).then(
			setSupportRequest,
		);
	};

	const handleOpenClick = () => {
		putSupportRequest(id, { ...formData, status: "Open" }).then(
			setSupportRequest,
		);
	};

	if (!isLoaded) {
		return <Loader />
	}

	return (
		<div>
			<Grid container>
				<Grid item md={4}>
					<List>
						<ListItem>
							<ListItemText primary="Case nr." secondary={supportRequest?.case_number}
								primaryTypographyProps={{
									variant: "subtitle2",
									color: "textSecondary",
								}}
								secondaryTypographyProps={{
									variant: "h5",
									color: "textPrimary",
								}}
							/>
						</ListItem>
						<ListItem>
							<ListItemText primary="Status"
								secondary={
									<Chip label={supportRequest?.status} color="primary" />
								}
								primaryTypographyProps={{
									variant: "subtitle2",
									color: "textSecondary",
								}}
								secondaryTypographyProps={{
									color: "textPrimary",
								}}
							/>
						</ListItem>
					</List>
				</Grid>
				<Grid item md={4}>
					<List>
						<ListItem>
							<ListItemText
								primary="Platform"
								secondary={
									<>
										{supportRequest ? (
											<PlatformIcon platform={supportRequest.app_platform} />
										) : null}
										{supportRequest ? (
											<Chip label={supportRequest?.app_platform + " " + supportRequest?.app_version} />
										) : null}										
									</>
								}
								primaryTypographyProps={{
									variant: "subtitle2",
									color: "textSecondary",
								}}
								secondaryTypographyProps={{
									color: "textPrimary",
								}}
							/>
						</ListItem>
						
					</List>
				</Grid>
				<Grid item md={4}>
					<List>
						<ListItem>
							{supportRequest?.created_at !== 0 &&
								<ListItemText primary="Created"
									secondary={<DateLabel inputDate={supportRequest?.created_at} />}
									primaryTypographyProps={{
										variant: "subtitle2",
										color: "textSecondary",
									}}
									secondaryTypographyProps={{
										color: "textPrimary",
									}} />
							}
							{supportRequest?.created_at === 0 && supportRequest?.created.length !== 0 && 
								<ListItemText
									primary="Created"
									secondary={supportRequest ? new Date(
										supportRequest.created,
									).toLocaleString("sv-SE") : null}
									primaryTypographyProps={{
										variant: "subtitle2",
										color: "textSecondary",
									}}
									secondaryTypographyProps={{
										color: "textPrimary",
									}} />
							}
						</ListItem>
						<ListItem>
							{supportRequest?.modified_at !== 0 &&
								<ListItemText primary="Modified"
									secondary={<DateLabel inputDate={supportRequest?.modified_at} />}
									primaryTypographyProps={{
										variant: "subtitle2",
										color: "textSecondary",
									}}
									secondaryTypographyProps={{
										color: "textPrimary",
									}} />
							}
							{supportRequest?.modified_at === 0 && supportRequest?.modified.length !== 0 && 
								<ListItemText
									primary="Modified"
									secondary={supportRequest ? new Date(
										supportRequest.modified,
									).toLocaleString("sv-SE") : null}
									primaryTypographyProps={{
										variant: "subtitle2",
										color: "textSecondary",
									}}
									secondaryTypographyProps={{
										color: "textPrimary",
									}} />
							}
						</ListItem>
					</List>
				</Grid>
			</Grid>

			<Grid container direction="column" spacing={2}>
				<Grid item>
					{supportRequest?.notes && 
						<SupportRequestNotes notes={supportRequest?.notes} />}
				</Grid>
				<Grid item>
					{supportRequest?.status === "Open" &&
					<TextField
						value={noteText}
						onChange={handleNoteChange}
						multiline
						variant="outlined"
						placeholder="Type your notes here ..."
						label="Note"
						fullWidth
						InputLabelProps={{
							shrink: true,
						  }}
					/>}
					
				</Grid>
				<Grid item container spacing={2} justifyContent="space-between">
					<Grid item>
						<Button
							startIcon={<AccountIcon />}
							component={Link}
							to={"/users/" + supportRequest?.user_id}
							color="primary"
							variant="outlined"
						>
							View User
						</Button>
					</Grid>
					<Grid item>
						<Grid container spacing={2}>
							<Grid item>
								<Button disabled={["Open"].includes(""+supportRequest?.status)} 
										onClick={handleOpenClick} 
										variant="contained" 
										color="secondary" 
										startIcon={<OpenIcon />}>
									Open case
								</Button>
							</Grid>
							<Grid item>
								<Button disabled={["Closed","New"].includes(""+supportRequest?.status)} 
										onClick={handleResolveClick} 
										variant="contained" 
										color="secondary" 
										startIcon={<CheckIcon />}>
									Resolve
								</Button>
							</Grid>
							<Grid item>
								<Button disabled={noteText.length===0}
									onClick={handleSaveForm} 
									variant="contained" 
									color="primary" 
									startIcon={<SaveIcon />}>
									Update
								</Button>
							</Grid>	
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};
