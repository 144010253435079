import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Paper, Toolbar, Typography, TextField, Button, Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid"

import { getAppConfig, updateAppConfig } from "../../api/appconfig";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(5),
    },
}));

export const AppConfig = () => {
    const [tosVersion, setTosVersion] = useState<string>("");
    const [newTosVersion, setNewTosVersion] = useState<string>("");
    const [inputError, setInputError] = useState<string>("");

    const classes = useStyles();

    useEffect(() => {
        fetchAppConfig();
    }, []);

    const fetchAppConfig = () => {
        getAppConfig().then((response) => {
            setTosVersion(response.tos_version);
        }
        ).catch(err => {
            console.error(err);
        });
    }

    const handleConfirmTosUpdate = () => {
        // Check that pattern is n.n.n
        let isValid = newTosVersion.split('.').every((num) => !isNaN(parseInt(num)));
        isValid = isValid && newTosVersion.split('.').length === 3;
        if (!isValid) {
            setInputError("Invalid version format, should be a semantic version with three parts: 1.2.3");
            return;
        }
        
        updateAppConfig(newTosVersion).then(() => {
            setTosVersion(newTosVersion);
            setNewTosVersion("");
        }).catch(err => {
            console.error(err);
        });
    }


    return (
        <div>
            <Toolbar>
                <Typography variant="h4">App Config</Typography>
            </Toolbar>
            <Paper className={classes.paper}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5">Terms of service version: {tosVersion}</Typography>
                    <Box mt={2}>
                        <TextField
                            label="New version (This will force all users to read a new version!!!)"
                            value={newTosVersion}
                            onChange={(e) => {
                                setNewTosVersion(e.target.value)
                                setInputError("");
                            }}
                            error={!!inputError}
                            helperText={inputError}
                            fullWidth
                        />
                    </Box>
                    <Box mt={2}>
                        <Button variant="contained" color="primary" onClick={handleConfirmTosUpdate}>
                            Confirm
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
        </div>
    );
}