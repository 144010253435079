import { baseUrl } from "./config";
import { checkErrors } from "./utils";
import { getToken } from "./index";

export type Contest = {
	id: string;
	active: boolean;
	codes: Array<ContestCode>;
	reward_product_id: string;
	created: Date;
	updated: Date;
}

export const newContest = (data?: any): Contest => {
	return {
		id: data?.id ?? "",
		active: data?.active ?? false,
		codes: data?.codes ? data.codes.map((c: any) => newContestCode(c.contest_id, c)) : [],
		reward_product_id: data?.reward_product_id ?? "",
		created: data?.created ? new Date(data.created) : new Date(),
		updated: data?.created ? new Date(data.updated) : new Date(),
	};
}

export type ContestCode = {
	contest_id: string;
	code: string;
	expires: Date;
	status: ContestCodeStatus;
	user_id: string;
	reward_id: string;
	reason: string;
	created: Date;
	updated: Date;
}

export const newContestCode = (contest_id: string, data?: any): ContestCode => {
	return {
		contest_id: contest_id,
		code: data?.code ?? "",
		expires: data?.expires ? new Date(data.expires) : new Date(),
		status: data?.status ?? ContestCodeStatus.NewContestCode,
		user_id: data?.user_id ?? "",
		reward_id: data?.reward_id ?? "",
		reason: data?.reason ?? "",
		created: data?.created ? new Date(data.created) : new Date(),
		updated: data?.updated ? new Date(data.updated) : new Date(),
	};
}

export enum ContestCodeStatus {
	NewContestCode = 0,
	DeliveredContestCode = 1,
	UsedContestCode = 2,
	CancelledContestCode = 98,
	ErrorContestCode = 99,
}

export type RedeemResponse = {
	status: string;
}

export const postContest = async (contest: Contest): Promise<Response> => {
	const requestUrl = new URL("/api/v1/contests", baseUrl)
	return fetch(requestUrl.toString(), {
		method: "POST",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
		body: JSON.stringify(contest),
	})
		.then(checkErrors);
}

export const getContests = async (): Promise<Array<Contest>> => {
	const requestUrl = new URL("/api/v1/contests", baseUrl);
	return fetch(requestUrl.toString(), {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		}
	})
		.then(checkErrors)
		.then((response) => {
			return response.json().then(r =>
				r.map((c: any[]) => newContest(c)));
		});
}

export const putContest = async (contest: Contest): Promise<Response> => {
	const requestUrl = new URL("/api/v1/contests", baseUrl);
	return fetch(requestUrl.toString(), {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
		body: JSON.stringify(contest),
	})
		.then(checkErrors);
}

export const putContestCode = async (contestId: string, code: ContestCode): Promise<Response> => {
	const requestUrl = new URL(`/api/v1/contests/${contestId}/code`, baseUrl);
	return fetch(requestUrl.toString(), {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
		body: JSON.stringify(code),
	})
		.then(checkErrors);
}

export const getContestCode = async (contestId: string, forceCreate: boolean): Promise<ContestCode> => {
	const requestUrl = new URL(`/api/v1/contests/${contestId}/code?create=${forceCreate}`, baseUrl)
	return fetch(requestUrl.toString(), {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
		.then(checkErrors)
		.then((response) => {
			return response.json().then(r =>
				newContestCode(contestId, r));
		});
}

export const postCodeBatch = async (contestId: string, numberOfCodes: number): Promise<Response> => {
	const requestUrl = new URL(`/api/v1/contests/${contestId}/batch/${numberOfCodes}`, baseUrl);
	return fetch(requestUrl.toString(), {
		method: "POST",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
		.then(checkErrors);
}

export const redeemContestCode = async (userId: string, code: string): Promise<Response> => {
	const requestUrl = new URL(`/api/v1/contests/redeem/${code}/for/${userId}`, baseUrl);
	return fetch(requestUrl.toString(), {
		method: "POST",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	});
}

export const deleteContest = async (contestId: string): Promise<Response> => {
	const requestUrl = new URL(`/api/v1/contests/${contestId}`, baseUrl);
	return fetch(requestUrl.toString(), {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
		.then(checkErrors);
}

export const deleteContestCode = async (contestId: string, code: string): Promise<Response> => {
	const requestUrl = new URL(`/api/v1/contests/${contestId}/code/${code}`, baseUrl);
	return fetch(requestUrl.toString(), {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
		.then(checkErrors);
}