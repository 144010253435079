import React, { useEffect, useState } from "react";
import {
    Avatar,
    Button,
    Menu,
    MenuItem,
    TableCell,
    TableRow,
    Typography,
} from "@material-ui/core";
import {
    KeyboardArrowDown as KeyboardArrowDownIcon,
    ZoomIn as ZoomInIcon,
} from "@material-ui/icons";

import { OrderV2, OrderLine, PriceGroupTuple } from "../../api/orders_v2";
import { FlexTicket, expireFlexTicket } from "../../api/flextickets_v2";
import { Operator } from "../../api/operators";
import { Product } from "../../api/products";

const asset_base = "https://storage.googleapis.com/travis-assets-prod/";

type Props = {
    line: OrderLine,
    order: OrderV2,
    flex?: FlexTicket,
    operators: { [index: string]: Operator }
    onProductChanged: () => void
};

export const UserOrderItemProduct = ({ line, order, flex, operators, onProductChanged }: Props) => {
    const lineOperatorIcon = (operatorId: string) => {
        const operator = operators[operatorId];
        if (!operator) {
            return <></>
        }

        const icon = `${asset_base}${operator.icon}.png`;

        return <Avatar style={{ width: 24, height: 24 }} src={icon} />;
    }

    const onProductAction = (action: string) => {
        if (!flex) {
            return
        }

        switch (action) {
            case "expire":
                expireFlexTicket(flex.user_id, flex.id);
                onProductChanged();
                break;
        }
    }

    return <>
        {line.price_groups.map((group) =>
            <TableRow>
                <TableCell>{lineOperatorIcon(line.operator_id)}</TableCell>
                <TableCell>{line.product_type_id}</TableCell>
                <TableCell>{group.price_group}</TableCell >
                <TableCell align="right">{group.quantity}</TableCell>
                <TableCell align="right">{group.price.excluding_vat.formatted}</TableCell>
                <TableCell align="right">{group.price.amount_vat.formatted}</TableCell>
                <TableCell align="right">{group.price.including_vat.formatted}</TableCell>
                <TableCell align="right">
                    <ProductActions orderLine={line} order={order} flex={flex} onAction={onProductAction} />
                </TableCell>
            </TableRow>
        )}
    </>
}

export const ProductQuantity = ({ product }: { product: Product }) => {
    if (product.type === "rental") {
        return <>{product.quantity} min</>;
    }
    return <>{product.quantity}</>;
}

type productActionProps = {
    orderLine: OrderLine,
    order: OrderV2,
    flex?: FlexTicket,
    onAction: (action: string) => void,
}

export const ProductActions = ({ orderLine, order, flex, onAction }: productActionProps) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleExpireFlexTicket = (event: any) => {
        onAction('expire');
        setAnchorEl(null);
    }

    const handleMoveTicket = (event: any) => {
        onAction('move_ticket');
        setAnchorEl(null);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (orderLine.price_groups.length === 0 && orderLine.price_groups[0].price_group === "rental") {
        if (["se_voi", "se_tier"].includes(orderLine.operator_id)) {
            return (<Button aria-label="show rental"
                href={`/v2/rentals/${orderLine.product_id}`}
                size="small"
                variant="contained"
                color="secondary"
                startIcon={<ZoomInIcon />}>
                View
            </Button>);
        }

        return (<Button aria-label="show rental"
            href={`/v2/rentals/${orderLine.product_id}`}
            size="small"
            variant="contained"
            color="secondary"
            startIcon={<ZoomInIcon />} disabled>
            View
        </Button>);
    }

    if (flex) {
        const expires = new Date(flex.expires * 1000);
        const now = new Date();

        if (expires > now) {
            return <>
                <Button id="expire-button"
                    aria-label="Expire" aria-haspopup="true" aria-controls="expiration-menu" aria-expanded={open ? 'true' : undefined}
                    size="small" variant="contained" color="secondary"
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}>
                    Actions
                </Button>
                <Menu
                    id="expiration-menu"
                    anchorEl={anchorEl} open={open} onClose={handleClose}
                    MenuListProps={{ 'aria-labelledby': 'expire-button' }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <MenuItem key="expire-ticket" onClick={handleExpireFlexTicket}>
                        Expire ticket
                    </MenuItem>
                    <MenuItem key="move-ticket" onClick={handleMoveTicket}>
                        Move ticket
                    </MenuItem>
                </Menu>
            </>;
        }
    }

    return <></>;
}