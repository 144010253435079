
import Toolbar from "@material-ui/core/Toolbar";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Chip from '@material-ui/core/Chip';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { SupportStatus } from "../supportRequest/SupportStatus";
import { SupportRequestStatus } from "../../api/support_requests";
import PlatformIcon from "../general/PlatformIcon";

import { SupportRequest as SupportRequestType } from "../../api/support_requests";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	table: {
	  textDecoration: "none",
	},
  }));

export const UserSupportRequests = ({ requests = [] }: { requests?: SupportRequestType[] }) => {
	const classes = useStyles();
	if (requests === null) {
		return <></>;
	}
	if (requests.length === 0) {
		return <></>;
	}
	return <>
			<Toolbar>
				<Typography variant="h6">
				Support tickets
				</Typography>
			</Toolbar>
			<TableContainer component={Paper}>
				<Table aria-label="Support tickets">
					<TableHead>
						<TableRow>
							<TableCell>Case Id</TableCell>
							<TableCell align="right" width="40">Platform</TableCell>
							<TableCell align="right" width={60}>Version</TableCell>
							<TableCell align="right" width={60}>Status</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
					{requests.map((request) => (
						<TableRow className={classes.table} key={request.id} hover component={Link} to={`/support-requests/${request.id}`}>
							<TableCell component="th" scope="row">
								<Chip color="secondary" label={request.case_number} />
							</TableCell>
							<TableCell>
								{request.app_platform && 
								<PlatformIcon platform={request.app_platform} />}
							</TableCell>
							<TableCell align="right">{request.app_version ? request.app_version : "N/A"}</TableCell>
							<TableCell align="right">
								<SupportStatus status={request.status} />
							</TableCell>
						</TableRow>
					))}
					</TableBody>
				</Table>
			</TableContainer>
		</>;
}