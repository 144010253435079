import React, { useState, useEffect } from "react";

import {
    Box,
    Button,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    Toolbar,
    Typography,
} from "@material-ui/core";
import {
    ArrowLeft,
    ArrowRight,
} from "@material-ui/icons";
import { UserOrderItemV2 } from "./UserOrderItemV2";

import { OrderV2, OrderState } from "../../api/orders_v2";
import { Operator } from "../../api/operators";
import { Product } from "../../api/products";
import { User } from "../../api/user";

type OrderFilter = {
    states: OrderState[];
    text: string,
}

const states = [
    OrderState.Allocated,
    OrderState.Ready,
    OrderState.Payment,
    OrderState.Fulfillment,
    OrderState.Complete,
    OrderState.Unpaid,
    OrderState.Cancelled,
];

type UserOrdersV2Props = {
    orders?: OrderV2[]
    operators: { [index: string]: Operator }
    products: { [index: string]: Product }
    user: User
}

export const UserOrdersV2 = ({ orders, operators, products, user }: UserOrdersV2Props) => {
    const [filters, setFilters] = useState<OrderFilter>({
        states: [...states],
        text: "",
    });
    const [filteredOrders, setFilteredOrders] = useState<OrderV2[]>([]);
    const [paginatedOrders, setPaginatedOrders] = useState<OrderV2[]>([]);
    const [orderPage, setOrderPage] = useState<number>(0);
    const [orderPageHint, setOrderPageHint] = useState<string>("");
    const [isLogInspectorOpen, setIsLogInspectorOpen] = useState<boolean>(false);
    const [logInspectorFromDate, setLogInspectorFromDate] = useState<Date>(new Date());
    const [logInspectorToDate, setLogInspectorToDate] = useState<Date>(new Date());
    const [logInspectorTerms, setLogInspectorTerms] = useState<string[]>([]);
    const ordersPerPage = 5;

    useEffect(() => {
        if (!orders) {
            return
        }

        let filterText = filters.text.toLowerCase();
        let filtered = orders.filter((order) => {
            // An order must match the state filter in order to be considered at all
            if (!filters.states.includes(order.state as OrderState)) {
                return false;
            }

            if (filterText == "") {
                return true;
            }

            if (order.id != "" && order.id.toLowerCase().includes(filterText)) {
                return true;
            }

            if (!order.payment) {
                return false;
            }

            if (order.payment.id != "" && order.payment.id.toLowerCase().includes(filterText)) {
                return true;
            }

            if (order.payment.transaction_id != "" && order.payment.transaction_id.toLowerCase().includes(filterText)) {
                return true;
            }

            return false;
        });

        const from = orderPage * ordersPerPage;
        const to = Math.min((orderPage + 1) * ordersPerPage, filtered.length);
        const paginated = filtered.slice(from, to);

        setFilteredOrders(filtered);
        setPaginatedOrders(paginated);
        setOrderPageHint(`${paginated.length === 0 ? 0 : from + 1}-${to}/${filtered.length}`);
    }, [orderPage, filters, orders]);

    const handleStateFilterChange = (ev: any) => {
        const { target: { value } } = ev;
        let newFilters = typeof value === "string" ? value.split(",") : value;
        console.log("Setting filters", newFilters);
        setFilters({ ...filters, states: newFilters });
    }

    const handlePrevOrderPage = (e: any) => {
        if (orderPage > 0) {
            setOrderPage(orderPage - 1);
        }
    }

    const handleNextOrderPage = (e: any) => {
        if (orderPage < (filteredOrders.length / ordersPerPage) - 1) {
            setOrderPage(orderPage + 1);
        }
    }

    const showLogInspector = (order: OrderV2) => {
        let fromDatePre = new Date(order.created * 1000);
        const fromDate = new Date(Number(fromDatePre));
        fromDate.setDate(fromDatePre.getDate() - 1)

        let toDatePre = new Date(order.updated * 1000);
        const toDate = new Date(Number(toDatePre));
        toDate.setDate(toDatePre.getDate() + 1);

        let terms = [`"order.id:"${order.id}"`];

        setLogInspectorFromDate(fromDate);
        setLogInspectorToDate(toDate);
        setLogInspectorTerms(terms);
        setIsLogInspectorOpen(true);
    };

    const hideLogInspector = () => {
        setIsLogInspectorOpen(false);
    };

    const showMoveFlexTicket = (userId: string, flexTicketId: string, deviceId: string) => { };
    const hideMoveFlexTicket = (flexId: string, newDeviceId: string | undefined) => { };

    const capitalize = (name: string) => {
        return name.charAt(0).toUpperCase() + name.slice(1);
    }

    return <>
        <Toolbar>
            <Box display='flex' flexGrow={1}>
                <Typography variant="h6">
                    V2 Orders
                </Typography>
            </Box>
            <Select
                id="order-state-filter"
                multiple
                value={filters.states}
                onChange={handleStateFilterChange}
            >
                {states.map((state) => (
                    <MenuItem
                        key={state}
                        value={state}
                    >
                        {capitalize(state)}
                    </MenuItem>
                ))}
            </Select>
        </Toolbar>
        <TableContainer component={Paper}>
            <Table aria-label="Orders">
                <TableHead>
                    <TableRow>
                        <TableCell width={20} />
                        <TableCell width={20} />
                        <TableCell>
                            <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                color="primary"
                                placeholder="Order / Payment / Transaction"
                                onChange={(ev) => {
                                    setFilters({ ...filters, text: ev.target.value });
                                }}
                            />
                        </TableCell>
                        <TableCell align="right">VAT</TableCell>
                        <TableCell align="right">Total</TableCell>
                        <TableCell width={20} align="left">Payment</TableCell>
                        <TableCell width={20} />
                        <TableCell align="right">Created / Updated</TableCell>
                        <TableCell align="center" colSpan={2}>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {paginatedOrders.map((order) => (
                        <UserOrderItemV2
                            key={`${user.user_id}-${order.id}`}
                            order={order}
                            user={user}
                            operators={operators}
                            products={products}
                            showLogInspector={showLogInspector}
                            showMoveFlexTicket={showMoveFlexTicket}
                        />
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell align="left">
                            <Button endIcon={<ArrowLeft />} onClick={handlePrevOrderPage} />
                        </TableCell>
                        <TableCell align="center" colSpan={7}>
                            <Typography>{orderPageHint}</Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Button endIcon={<ArrowRight />} onClick={handleNextOrderPage} />
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    </>;
}