import React, { useEffect, useState, SetStateAction } from "react";
import { useParams, useHistory } from "react-router-dom";

import Toolbar from "@material-ui/core/Toolbar";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import { Loader } from "../loader";
import { makeStyles } from "@material-ui/core";

import { getOperator, Operator, OperatorPriceGroup, OperatorPriceGroupBoB, OperatorProductType, OperatorZone, OperatorZoneBoB } from "../../api/operators";
import ZoneIcon from "@material-ui/icons/LocationOn";
import PriceGroupIcon from "@material-ui/icons/People";
import { OperatorIcon } from "../operator_icon";

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(5),
	},
	alert: {
		marginBottom: theme.spacing(3),
	},
	footer: {
		borderTop: "1px solid #ccc",
		backgroundColor: theme.palette.grey[100],
		padding: `${theme.spacing(2)}px`,
		textAlign: 'right',
	},
	tabs: {
		borderBottom: "1px solid #ccc",
	},
	tabPanel: {
		padding: `${theme.spacing(0)}px`,
	},
	operatorIcon: {
		marginLeft: "15px",
	}
}));

export const OperatorDetail = () => {
	const classes = useStyles();
	const history = useHistory();
	const params = useParams<{ operator_id: string }>();
	const [operator, setOperator] = useState<Operator>();
	const [loaded, setLoaded] = useState<boolean>(false);
	const [tabIndex, setTabIndex] = useState<number>(0);

	const onChangeTab = (event: any, value: SetStateAction<number>) => {
		setTabIndex(value);
	};

	useEffect(() => {
		getOperator(params.operator_id)
			.then((operator) => {
				setOperator(operator);
				setLoaded(true);
			});
	}, [params.operator_id]);

	

	if (!loaded) {
		return <Loader />;
	}

	return <>
	<Toolbar>
		{operator?.code && <OperatorIcon operator={operator?.code} />}
		<Typography className={classes.operatorIcon} variant="h6">{operator?.name}</Typography>
	</Toolbar>	
	<Paper className={classes.paper}>
		<Tabs
			onChange={onChangeTab}
			value={tabIndex}
			indicatorColor="primary"
			textColor="primary"
			variant="standard"
			className={classes.tabs}
			aria-label="operator information"
			>
			<Tab label="Info" />
			<Tab label="Zones" />
			<Tab label="Price groups" />
			<Tab label="Product types" />
		</Tabs>
		<Box className={classes.tabPanel} hidden={(tabIndex !== 0)}>
			<Grid container spacing={0}>
				<Grid item sm={12}>
					{operator && 
					<OperatorInfoItem operator={operator} />}
				</Grid>
			</Grid>
		</Box>
		<Box className={classes.tabPanel} hidden={(tabIndex !== 1)}>
			<Grid container>
				<Grid item sm={12}>
					<Table aria-label="Zones">
						<TableHead>
							<TableRow>
								<TableCell width={30}></TableCell>
								<TableCell width={140}>ID</TableCell>
								<TableCell>Name / Description</TableCell>
								<TableCell width={100}>BoB-ID</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
						{operator?.zones.sort((a,b)=>a.sort>b.sort?1:-1).map((zone)=>
							<OperatorZoneItem key={zone.id} zone={zone} />
						)}
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</Box>
		<Box className={classes.tabPanel} hidden={(tabIndex !== 2)}>
			<Grid container>
				<Grid item sm={12}>
					<Table aria-label="Price groups">
						<TableHead>
							<TableRow>
								<TableCell width={30}></TableCell>
								<TableCell width={140}>ID</TableCell>
								<TableCell>Name / Description</TableCell>
								<TableCell width={100}>BoB-ID</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
						{operator?.price_groups.sort((a,b)=>a.sort>b.sort?1:-1).map((priceGroup)=>
							<OperatorPriceGroupItem key={priceGroup.id} priceGroup={priceGroup} />
						)}
						</TableBody>
					</Table>
					
				</Grid>
			</Grid>
		</Box>
		<Box className={classes.tabPanel} hidden={(tabIndex !== 3)}>
			<Grid container spacing={4}>
				<Grid item sm={12}>
					<Table aria-label="Product types">
						<TableHead>
							<TableRow>
								<TableCell width={30}></TableCell>
								<TableCell width={140}>ID</TableCell>
								<TableCell>Name / Description</TableCell>
								<TableCell width={100}>Zones</TableCell>
								<TableCell width={100}>Price groups</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
						{operator?.product_types.sort((a,b)=>a.sort>b.sort?1:-1).map((productType)=>
							<OperatorProductTypeItem key={productType.id} productType={productType} />
						)}
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</Box>
	</Paper>
	</>;
}

interface OperatorInfoProps {
	operator: Operator;
}

export const OperatorInfoItem = (props: OperatorInfoProps) => {
	return <Table aria-label="Info">
			<TableBody>
				<TableRow>
					<TableCell width={200}>Name</TableCell>
					<TableCell><strong>{props.operator.name}</strong></TableCell>
				</TableRow>
				<TableRow>
					<TableCell>ID</TableCell>
					<TableCell><strong>{props.operator.id}</strong></TableCell>
				</TableRow>
				<TableRow>
					<TableCell>Code</TableCell>
					<TableCell><strong>{props.operator.code}</strong></TableCell>
				</TableRow>
				<TableRow>
					<TableCell>Icon</TableCell>
					<TableCell><strong>{props.operator.icon}</strong></TableCell>
				</TableRow>
				<TableRow>
					<TableCell>InfoURL</TableCell>
					<TableCell><strong>{props.operator.info_url}</strong></TableCell>
				</TableRow>
			</TableBody>
		</Table>;
}

interface OperatorPriceGroupProps {
	priceGroup: OperatorPriceGroup;
}

export const OperatorPriceGroupItem = (props: OperatorPriceGroupProps) => {
	var name: string = "";
	var description: string = "";
	var bobTravellerCategory: string = "";

	if (props.priceGroup.resources) {
		let swedish = props.priceGroup.resources["sv"] as { [key: string]: string; };
		name = swedish["pricegroup.title"];
		description = swedish["pricegroup.single"] + " / "  + swedish["pricegroup.multiple"];
	}

	if (props.priceGroup.bob) {
		let bob: OperatorPriceGroupBoB = props.priceGroup.bob;
		bobTravellerCategory = (bob.traveller_category) ? bob.traveller_category : "not set";
	}

	return <TableRow hover>
			<TableCell><PriceGroupIcon /></TableCell>
			<TableCell>{props.priceGroup.id}</TableCell>
			<TableCell>
				<strong>{name}</strong><br/>{description}
			</TableCell>
			<TableCell>{bobTravellerCategory}</TableCell>
			<TableCell></TableCell>
			</TableRow>;			
}

interface OperatorProductTypeProps {
	productType: OperatorProductType;
}

export const OperatorProductTypeItem = (props: OperatorProductTypeProps) => {
	var name: string = "";
	var description: string = "";
	var zones: string = "not set";
	var priceGroups: string = "not set";

	if (props.productType.resources) {
		let swedish = props.productType.resources["sv"] as { [key: string]: string; };
		name = swedish["producttype.title"];
		description = swedish["producttype.description.short"];
	}

	if (props.productType.zones) {
		zones = props.productType.zones.join(", ");
	}

	if (props.productType.price_groups) {
		priceGroups = props.productType.price_groups.join(", ");
	}


	return <TableRow hover>
			<TableCell><ZoneIcon /></TableCell>
			<TableCell>{props.productType.id}</TableCell>
			<TableCell>
				<strong>{name}</strong><br/>{description}
			</TableCell>
			<TableCell>{zones}</TableCell>
			<TableCell>{priceGroups}</TableCell>
			</TableRow>;
}

interface OperatorZoneProps {
	zone: OperatorZone;
}

export const OperatorZoneItem = (props: OperatorZoneProps) => {
	var name: string = "";
	var description: string = "";
	var bobZones: string = "";

	if (props.zone.resources) {
		let swedish = props.zone.resources["sv"] as { [key: string]: string; };
		name = swedish["zone.title"] + " " + swedish["zone.label"];
		description = swedish["zone.description"];
	}

	if (props.zone.bob) {
		let bob: OperatorZoneBoB = props.zone.bob;
		let zIDs : string[] = bob.zone_ids;
		bobZones = zIDs.join(",");
	}

	return <TableRow hover>
			<TableCell><ZoneIcon /></TableCell>
			<TableCell>{props.zone.id}</TableCell>
			<TableCell>
				<strong>{name}</strong><br/>{description}
			</TableCell>
			<TableCell>{bobZones}</TableCell>
			<TableCell></TableCell>
			</TableRow>;
}
