import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import { Link, useHistory } from "react-router-dom";

export const UserSearch = () => {
	const history = useHistory();
	const [searchString, setSearchString] = useState("");

	const handleKeyPress = (ev: React.KeyboardEvent<HTMLDivElement>) => {
		switch (ev.key) {
			case "Enter":
				history.push(`/users/${searchString}`);
				break;
			default:
				break;
		}
	};

	return (
		<>
			<FormControl variant="outlined">
				<InputLabel htmlFor="go-to-user-input">Go to user</InputLabel>
				<OutlinedInput
					labelWidth={75}
					id="go-to-user-input"
					type="text"
					value={searchString}
					onChange={(ev) => setSearchString(ev.target.value)}
					onKeyPress={handleKeyPress}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								component={Link}
								to={`/users/${searchString}`}
							>
								<SearchIcon />
							</IconButton>
						</InputAdornment>
					}
				/>
			</FormControl>
		</>
	);
};
