import { ChangeLogEntry } from '../../api/notifications';
import { DateLabel } from "../general/DateLabel";
import { Table, TableBody, TableHead, TableRow, TableCell } from "@material-ui/core";

interface NotificationChangeLogProps {
	entries: ChangeLogEntry[];
}

export const NotificationChangeLog = (props: NotificationChangeLogProps) => {

	const rowItem = (item:ChangeLogEntry) => {
		return <TableRow hover key={item.created}>
				<TableCell><DateLabel inputDate={item.created} /></TableCell>
				<TableCell>{item.event}</TableCell>
				<TableCell>{item.source}</TableCell>
				</TableRow>;
	}

	if (props.entries.length === 0) {
		return <></>;
	}

	return (
		<>
		<Table>
			<TableHead>
				<TableCell>Timestamp</TableCell>
				<TableCell>Event</TableCell>
				<TableCell>Trigger</TableCell>
			</TableHead>
			<TableBody>
				{props.entries.map(rowItem)}
			</TableBody>
		</Table>
		</>
	);
}


export default NotificationChangeLog;