import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	CouponApplyRule,
	CouponDiscount,
	CouponDiscountType,
	CouponSpec as CouponSpecType,
	Coupon,
	getCouponSpec,
	putCouponSpec,
	getCouponsForCouponSpec, PriceDetails,
} from "../../api/campaigns";
import { TimePicker } from "../TimePicker";
import { format } from "date-fns";
import { startOfDay, endOfDay } from "date-fns";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
	paper: {
		width: "100%",
	},
	grid: {
		minHeight: 50,
		padding: 10,
	},
	table: {
		minWidth: 650,
	},
});

type CouponListProps = {
	coupons: Array<Coupon> | undefined;
};

export const CouponList = ({ coupons }: CouponListProps) => {
	const classes = useStyles();

	const discountText = (discount: PriceDetails): string => {
		return `${discount.value} ${discount.currency}`;
	}

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>
							ID
						</TableCell>
						<TableCell>
							Order ID
						</TableCell>
						<TableCell>
							Redeemed At
						</TableCell>
						<TableCell>
							Discount
						</TableCell>
						<TableCell>
							Created / Updated
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{coupons?.map((coupon) => (
						<TableRow key={coupon.id}>
							<TableCell>
								{coupon.id}
							</TableCell>
							<TableCell>
								{coupon.orderId}
							</TableCell>
							<TableCell>
								{format(coupon.redeemed, "yyyy/MM/dd kk:mm:ss")}
							</TableCell>
							<TableCell>
								{discountText(coupon.discount)}
							</TableCell>
							<TableCell>
								{format(coupon.created, "yyyy/MM/dd kk:mm:ss")}
								<Typography color="textSecondary">
									{format(coupon.updated, "yyyy/MM/dd kk:mm:ss")}
								</Typography>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

type Filters = {
	from: Date;
	to: Date;
};

type CouponSpecParams = {
	specId: string;
};

export const CouponSpec = () => {
	const classes = useStyles();
	const history = useHistory();
	const search = useLocation().search;
	const fromDate = new URLSearchParams(search).get("from");
	const toDate = new URLSearchParams(search).get("to");

	const { specId } = useParams<CouponSpecParams>();
	const [spec, setSpec] = useState<CouponSpecType | null>();
	const [coupons, setCoupons] = useState<Array<Coupon> | null>();
	const [filters, setFilters] = useState<Filters>({
		from: startOfDay(new Date()),
		to: endOfDay(new Date()),
	});

	useEffect(() => {
		getCouponSpec(specId).then(setSpec);
	}, [specId]);

	useEffect(() => {
		getCouponsForCouponSpec(specId, filters.from, filters.to).then(setCoupons);
	}, [specId, filters]);

	useEffect(() => {
		if (fromDate) {
			setFilters({ ...filters, from: new Date(fromDate) });
		}
		if (toDate) {
			setFilters({ ...filters, to: new Date(toDate) });
		}
	}, []);

	const applyRuleText = (rule?: CouponApplyRule): string => {
		if (!rule) {
			return "";
		}

		switch (rule) {
			case CouponApplyRule.AutoAll:
				return "Automatic (all users)"
			case CouponApplyRule.AutoNewUsers:
				return "Automatic (new users)"
			case CouponApplyRule.Code:
				return "Coupon Code"
		}
	}

	const discountText = (discount?: CouponDiscount): string => {
		if (!discount) {
			return "";
		}

		switch (discount.discountType) {
			case CouponDiscountType.Percent:
				return `${discount.percent}%`;
			case CouponDiscountType.SingleAmount:
				return `${discount.amount / discount.scaleDivider} ${discount.currency}`
			case CouponDiscountType.TotalAmount:
				return `${discount.amount / discount.scaleDivider} ${discount.currency} (one-time use)`;
		}
	}

	const handleFromTimeChange = (date: Date | null) => {
		if (!date) {
			date = startOfDay(new Date());
		}

		setFilters({ ...filters, from: date });
		history.push(`/v2/coupon_specs/${specId}?from=${filters.from?.toISOString()}&to=${filters.to?.toISOString()}`);
	}

	const handleToTimeChange = (date: Date | null) => {
		if (!date) {
			date = endOfDay(new Date());
		}

		setFilters({ ...filters, to: date });
		history.push(`/v2/coupon_specs/${specId}?from=${filters.from?.toISOString()}&to=${filters.to?.toISOString()}`);
	}

	console.log("coupons:", coupons);

	return (
		<Grid container spacing={4}>
			<Grid item container direction="row" wrap="nowrap" justifyContent="space-around">
				<Grid item>
					<List>
						<ListItem>
							<ListItemText
								primary="ID"
								secondary={spec?.id}
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								primary="Campaign ID"
								secondary={spec?.campaignId}
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								primary="Code"
								secondary={spec?.code}
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								primary="Limit"
								secondary={spec?.limit}
							/>
						</ListItem>
					</List>
				</Grid>
				<Grid item>
					<ListItem>
						<ListItemText
							primary="Operator"
							secondary={(spec?.operator ?? "") !== "" ? spec?.operator : "<any>"}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary="Transportation Type"
							secondary={(spec?.transportationType ?? "") !== "" ? spec?.transportationType : "<any>"}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary="Apply Rule"
							secondary={applyRuleText(spec?.applyRule)}
						/>
					</ListItem>
					<ListItem>
						<ListItemText
							primary="Discount"
							secondary={discountText(spec?.discount)}
						/>
					</ListItem>
				</Grid>
			</Grid>
			<Grid
				direction="row"
				justifyContent="space-between"
				wrap="nowrap"
				container
				item
			 >
				<Grid xs={2} item container>
					<h2>Coupons</h2>
				</Grid>
				<Grid xs={3} item container>
					<Grid item>
						<TimePicker
							defaultDate={filters.from}
							onChange={handleFromTimeChange}
							label="From"
						/>
					</Grid>
					<Grid item>
						<TimePicker
							defaultDate={filters.to}
							onChange={handleToTimeChange}
							label="To"
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item style={{ width: "100%" }}>
				<CouponList coupons={coupons ?? undefined} />
			</Grid>
		</Grid>
	);
}