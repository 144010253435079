import React from "react";

import Table from  "@material-ui/core/Table";
import { TableHead, TableBody, Typography } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { DateLabel } from "../general/DateLabel";
import { FlexTicket } from "../../api/flextickets";

export const FlexTicketDetail = ({ flex, broken }: { flex?: FlexTicket, broken: Boolean}) => {
	if (broken) {
		return <TableRow>
					<TableCell />
					<TableCell />
					<TableCell align="left" colSpan={6}>
						<strong>Flexticket was not issued.</strong>
					</TableCell>
				</TableRow>;
	}
	if (flex === undefined) {
		return <></>;
	}

	let now = new Date();
	let expires = new Date(flex?.expires * 1000);
	let hasExpired = now > expires;
	let isDeactivated = flex?.deactivated === true;

	return <TableRow >
			<TableCell />
			<TableCell colSpan={7}>
				
				<Table size="small" aria-label="Travel periods">
					<TableHead>
						<TableRow>
							<TableCell colSpan={5}>
								{flex?.id && <>Flexticket ID: {flex?.id}<br/></>}
								{
									flex.travel_periods && flex.travel_periods.map((period, idx)=>
										<>
										Operator Ticket ID(s): 
											{
												period.tickets?.map((ticket, idx)=>
													<>{ticket.ticket_id + " "}</>
												)
											}
										</>
									)
									
								}
								<><br/></>
								{flex?.activated && <>Activated: <DateLabel inputDate={flex?.activated} /></>}{!flex?.activated && <>Not activated</>}
								<br/>
								{hasExpired && 
									<>Expired:&nbsp;
									<Typography style={{color:"#aa3333"}} variant="inherit">
										<DateLabel inputDate={flex?.expires} />
									</Typography></>
								}
								{isDeactivated &&
									<>Deactivated:&nbsp;
									<Typography style={{color:"#f00"}} variant="inherit">
										Ticket has been deactivated by the operator because of fraudulent use.<br />
									</Typography></>
								}
								{!hasExpired && 
									<>Expires:&nbsp;
									<Typography style={{color:"#33aa33"}} variant="inherit">
										<DateLabel inputDate={flex?.expires} />
									</Typography></>
								}
								<br/>
								{flex?.device && 
									<>Device:&nbsp;
									<Typography style={{color:"#33aa33"}} variant="inherit">
										({flex?.device?.platform} client, v{flex?.device?.app_version}) {flex?.device?.id}
									</Typography></>
								}
							</TableCell>
						</TableRow>
						{flex.travel_periods && 
						<TableRow>
							<TableCell>Travel period</TableCell>
							<TableCell>Period started</TableCell>
							<TableCell>Activated at</TableCell>
							<TableCell>Period ends</TableCell>
							<TableCell align="right">Tickets issued within period</TableCell>
						</TableRow>}
					</TableHead>
					{flex.travel_periods && 
						<TableBody>
						{flex.travel_periods?.map((period, idx)=>
							<TableRow key={period.activated}>
								<TableCell><strong>{idx + 1}</strong></TableCell>
								<TableCell><DateLabel inputDate={period.period_started} /></TableCell>
								<TableCell><DateLabel inputDate={period.activated} /></TableCell>
								<TableCell><DateLabel inputDate={period.period_ends} /></TableCell>
								<TableCell align="right">{period.tickets_issued}</TableCell>
							</TableRow>)}
						</TableBody>}
				</Table>	
				<br/>
			</TableCell>
			</TableRow>;
}