import {
	Typography,
	Box,
	Collapse,
	IconButton,
	Paper,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Toolbar,
} from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PushIcon from '@material-ui/icons/Send';

import { by639_1 as codes } from 'iso-language-codes';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Device } from '../../api/device';
import DateLabel from '../general/DateLabel';
import PlatformIcon from '../general/PlatformIcon';
import NotificationTestModal from '../notifications/NotificationTestModal';

const useStyles = makeStyles((theme) => ({
	table: {
		textDecoration: "none",
	},
	red: {
		color: "#f00",
	}
}));


type PushFunction = (event: React.MouseEvent<HTMLElement>, device: Device) => void;

export const UserDevices = ({ devices = [] }: { devices?: Device[] }) => {
	const [isTestOpen, setTestOpen] = useState<boolean>(false);
	const [currentDevice, setCurrentDevice] = useState<Device | undefined>();

	const onCloseTestModal = () => {
		setTestOpen(false);
	}

	const pushTest = (event: React.MouseEvent<HTMLElement>, device: Device) => {
		event.stopPropagation();
		setCurrentDevice(device);
		setTestOpen(true);
	}


	if (devices === null) {
		return <></>;
	}
	if (devices.length === 0) {
		return <></>;
	}
	return <>
		<Toolbar>
			<Typography variant="h6">
				Devices
			</Typography>
		</Toolbar>
		<TableContainer component={Paper}>
			<Table aria-label="Users devices">
				<TableHead>
					<TableRow>
						<TableCell width={20}></TableCell>
						<TableCell width={30}>Platform</TableCell>
						<TableCell>Device</TableCell>
						<TableCell>Client</TableCell>
						<TableCell>Language</TableCell>
						<TableCell width={140} align="right">Registered</TableCell>
						<TableCell width={140} align="right">Last updated</TableCell>
						<TableCell width={50} align="right">Actions</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{devices.map((device, idx) => (
						<UserDevice key={`usr-dev-${idx}`} device={device} pushAction={pushTest} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
		{isTestOpen &&
			<NotificationTestModal isOpen={isTestOpen} device={currentDevice} onClose={onCloseTestModal} />}

	</>;
}

export const UserDevice = ({ device, pushAction }: { device: Device, pushAction: PushFunction }) => {
	const classes = useStyles();
	const [open, setOpen] = useState<boolean>(false);

	return <>
		<TableRow key={"usd_" + device.device_id} className={classes.table} hover>
			<TableCell>
				{device.device_id.length !== 0 &&
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
					</IconButton>}
			</TableCell>
			<TableCell>
				{(device && device.user_agent && device.user_agent.platform) &&
					<PlatformIcon platform={device.user_agent.platform} />}
			</TableCell>
			<TableCell>
				<strong>
					{device.user_agent.model &&
						<>
							{device.user_agent.manufacturer && <>{device.user_agent.manufacturer} </>}
							{device.user_agent.model && <>{device.user_agent.model}<br /></>}
							{device.user_agent.os_version && <>{device.user_agent.os_version}<br /></>}
							{device.user_agent.os_build && <>{device.user_agent.os_build}</>}
						</>
					}
					{!device.user_agent.model && <>Unknown device</>}
				</strong>
			</TableCell>
			<TableCell>
				<strong>
					{device.user_agent.app_name &&
						<span>
							{device.user_agent.app_name && <><span>{device.user_agent.app_name}</span> </>}
							{device.user_agent.app_version && <><span>v{device.user_agent.app_version}</span></>}
							{device.user_agent.app_build && <> <span>(Build {device.user_agent.app_build})</span><br /></>}
						</span>}
					{!device.user_agent.app_name &&
						<span>
							Unknown client
							<br />
						</span>
					}
				</strong>
				<span>
					{device.received_notifications ? device.received_notifications.length + " push notification(s) received" : ""}
					{device.failed &&
						<span className={classes.red}>
							{device.received_notifications && <br />}
							Push failed at <DateLabel inputDate={device.failed} />
						</span>
					}
				</span>
			</TableCell>
			<TableCell>
				{codes[device.language]?.name}
			</TableCell>
			<TableCell align="right">
				<DateLabel inputDate={device.created} />
			</TableCell>
			<TableCell align="right">
				<DateLabel inputDate={device.updated} />
			</TableCell>
			<TableCell align="right">
				{device.token &&
					<IconButton
						size="medium"
						onClick={(e) => pushAction(e, device)}
						color="secondary">
						<PushIcon />
					</IconButton>}
			</TableCell>
		</TableRow>
		{device.device_id.length !== 0 &&
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#f5f5f5" }} colSpan={8}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={3}>
							<p><strong>Device ID</strong></p>
							{device.device_id}
							{device.other_user_ids &&
								<>
									<p><strong>Associated users</strong></p>
									<ul>
										{device.other_user_ids.map((userid, idx) => {
											return <><li key={`other-user-user-ids-${idx}`}>
												<Link to={"/users/" + userid}>{userid}</Link>
											</li>
											</>
										})}
									</ul>
								</>
							}
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>}
	</>;
}