import { baseUrl } from "./config";
import { getToken } from "./index";
import { checkErrors } from "./utils";
import { Price } from  "./orders";

export const getProduct = async (product_id: string): Promise<Product> => {
	return fetch(`${baseUrl}/api/v2/products/${product_id}`, {
			headers: {
				Authorization: `Bearer ${await getToken()}`,
			},
		}).then(checkErrors)
		.then((response) => response.json());
};

export const getProducts = async (): Promise<Product[]> => {
	return fetch(`${baseUrl}/api/v2/products`, {
			headers: {
				Authorization: `Bearer ${await getToken()}`,
			},
		}).then(checkErrors)
		.then((response) => response.json());
};

export const saveProduct = async (product: Product): Promise<Response> => {
	return fetch(`${baseUrl}/api/v2/products/${product.product_id}`, {
			method: 'PUT',
			headers: {
				Authorization: `Bearer ${await getToken()}`,
			},
			body: JSON.stringify(product),
		}).then(checkErrors);
};

export type ProductDescription = {
	title_sv?: string;
	title_en?: string;
	description_sv?: string;
	description_en?: string;
	short_description_sv?: string;
	short_description_en?: string;
	long_description_sv?: string;
	long_description_en?: string;
	operator_description_sv?: string;
	operator_description_en?: string;
	price_category_single_en?: string;
	price_category_single_sv?: string;
	price_category_multiple_en?: string;
	price_category_multiple_sv?: string;
	activate_action_title_sv?: string;
	activate_action_title_en?: string;
}

export type ProductSettings = {
	activation_period_length?: number;
	auto_refund_period_length?: number;
	available_from?: number;
	bob_product_category?: string;
	bob_product_grace_period_length?: number;
	bob_product_id?: string;
	bob_product_title?: string;
	bob_product_usage_category?: string;
	bob_product_validity_length?: number;
	is_active?: boolean;
	is_refundable?: boolean;
	max_quantity?: number;
	refund_period_length?: number;
	should_auto_refund?: boolean;
	travel_period_length?: number;
	travel_period_overlap_length?: number;
	travel_period_urgent_after_length?: number;
	travel_periods_included?: number;
	urgent_after_pre_activation?: number;
	urgent_after_post_activation?: number;
};

export type Product = {
	product_id: string;
	group: string;
	type: string;
	name: string;
	operator?: OperatorInfo;
	quantity: number;
	validity_period: number;
	price: Price;
	product_description: ProductDescription;
	product_settings: ProductSettings;
};

export type OperatorInfo = {
	code: string;
	name: string;
	icon?: string;
}
