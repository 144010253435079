import { getToken } from "./index";
import { baseUrl } from "./config";

export type AppConfigResponse = {
    tos_version: string;
};

export const getAppConfig = async (): Promise<AppConfigResponse> => {
    return fetch(`${baseUrl}/api/v2/app/configs`, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    })
        .then(checkErrors)
        .then((response) => response.json())
        .catch((e) => {
            throw e;
        });
}

export const updateAppConfig = async (tos_version: string): Promise<AppConfigResponse> => {
    return fetch(`${baseUrl}/api/v2/app/configs`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${await getToken()}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ tos_version: tos_version }),
    })
        .then(checkErrors)
        .then((response) => response.json())
        .catch((e) => {
            throw e;
        });
}

function checkErrors(response: Response) {
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return response;
}