
import { baseUrl } from "./config";
import { getToken } from "./index";
import { checkErrors } from "./utils";

export type Position = {
    lat: number;
    lon: number;
};

export type Rental = {
    id: string;
    active: boolean;
    ended: number;
    operator_id: string;
    order_id: string;
    partner_rental_id: string;
    started: number;
    user_id: string;
    vehicle_code: string;
    vehicle_id: string;
    start_position: Position;
    end_position?: Position;
    icon: string;
    transportation_type: string;
    mapbox_layers: Array<string>;
};

export const getRentals = async (vehicleCode?: string, from?: Date, to?: Date): Promise<Array<Rental>> => {

    const url = new URL(`/api/v2/rentalsv2/all`, baseUrl);
    const queryParams = [];

    if (vehicleCode) {
        queryParams.push(["vehicle", vehicleCode]);
    }

    if (from) {
        queryParams.push(["from", (Math.round(from.getTime() / 1000)).toString()]);
    }

    if (to) {
        queryParams.push(["to", (Math.round(to.getTime() / 1000)).toString()]);
    }

    return fetch(`${url.toString()}?${new URLSearchParams(queryParams).toString()}`,
        {
            headers: {
                Authorization: `Bearer ${await getToken()}`,
            }
        })
        .then(checkErrors)
        .then((response) => response.json());
};

export const getRental = async (id: string): Promise<Rental> => {

    const url = new URL(`/api/v2/rentalsv2/id/${id}`, baseUrl);

    return fetch(`${url.toString()}`,
        {
            headers: {
                Authorization: `Bearer ${await getToken()}`,
            }
        })
        .then(checkErrors)
        .then((response) => response.json());
};

export const endRental = async (id: string): Promise<Rental> => {

    const url = new URL(`/api/v2/rentalsv2/id/${id}`, baseUrl);

    return fetch(`${url.toString()}`,
        {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${await getToken()}`,
            }
        })
        .then(checkErrors)
        .then((response) => response.json());
};