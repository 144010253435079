import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { Typography, Box } from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import Toolbar from "@material-ui/core/Toolbar";

import { PartnerConnections } from "../../api/rentals";

const asset_base = "https://storage.googleapis.com/travis-assets-prod/";

export const UserPartners = ({ partners = {} }: { partners?: PartnerConnections }) => {
	if (partners === undefined) {
		return <></>;
	}
	if (Object.keys(partners).length === 0) {
		return <></>;
	}

	return (
		<>
		<Box display="flex" flexDirection="column" flexGrow={1}>
		<Toolbar>
			<Typography variant="h6">
				Partner connections
			</Typography>
		</Toolbar>
		<TableContainer component={Paper}>
			<Table size="small" aria-label="Partner connections">
				<TableHead>
					<TableRow>
						<TableCell align="left" width="30" />
						<TableCell>Partner</TableCell>
						<TableCell align="right">Remote User ID</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
				{Object.keys(partners).map((key) => {
					return <TableRow key={key} hover>
						<TableCell>
							<Avatar alt={key} src={asset_base + key + '@2x.png'} />
						</TableCell>
						<TableCell component="th" scope="row">{partners[key].partner_name}</TableCell>
						<TableCell align="right">{partners[key].remote_id}</TableCell>
					</TableRow>
				})}
				</TableBody>
			</Table>
		</TableContainer>
		</Box>
		</>
	);
};
