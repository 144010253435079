import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { AuthProvider } from "../context/authContext";
import "./App.css";
import { CacheUtils } from "./cache/Cache";
import { Campaign } from "./campaigns/Campaign";
import { Campaigns } from "./campaigns/Campaigns";
import { CouponSpec } from "./campaigns/CouponSpec";
import { ContestPage } from "./contests/Contest";
import { Contests } from "./contests/Contests";
import { Header } from "./Header";
import { History } from "./history";
import { Login } from "./Login";
import { Menu } from "./Menu";
import { NotificationsDetail, NotificationsList } from "./notifications";
import { ListOperators, OperatorDetail } from "./operators";
import { Overview } from "./overview";
import { Permissions, PermissionsV2 } from "./permissions";
import { ListProducts, ProductDetail } from "./products";
import { ProtectedRoute } from "./ProtectedRoute";
import { Rental } from "./rentals/Rental";
import { Rentals } from "./rentals/Rentals";
import { Report } from "./report";
import { ReportsV2 } from "./report/ReportsV2";
import { ListSupportRequests, SupportRequest } from "./supportRequest";
import { UserDetails } from "./user/UserDetail";
import { UserSearch } from "./UserSearch";
import { RentalsV2 } from "./rentals/RentalsV2";
import { RentalV2 } from "./rentals/RentalV2";
import { AppConfig } from "./appconfig/AppConfig";


const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		backgroundColor: theme.palette.background.default,
		minHeight: "100vh",
	},
	paper: {
		width: "100%",
		offset: theme.mixins.toolbar.offset,
		marginTop: theme.spacing(3),
	},
	toolbar: theme.mixins.toolbar,
}));



export const App = () => {
	const location = useLocation();
	const classes = useStyles();


	return (
		<AuthProvider>
			<div className={classes.root}>
				{location.pathname !== "/login" ? (
					<>
						<Menu />
						<Header />
					</>
				) : null}
				<Container className={classes.paper}>
					{location.pathname !== "/login" ? (
						<div className={classes.toolbar} />
					) : null}
					<Switch>
						<Route path="/login" component={Login} />

						<ProtectedRoute
							path="/users/:user_id"
							component={UserDetails}
						/>

						<ProtectedRoute
							claim="rentals"
							path="/v2/rentals/:rental_id"
							component={Rental}
						/>
						<ProtectedRoute
							claim="rentals"
							path="/v2/rentals"
							component={Rentals}
						/>

						<ProtectedRoute
							claim="rentals"
							path="/v2/rentalsV2/:id"
							component={RentalV2}
						/>
						<ProtectedRoute
							claim="rentals"
							path="/v2/rentalsV2"
							component={RentalsV2}
						/>

						<ProtectedRoute
							claim="campaigns"
							path="/v2/campaigns/:campaignId"
							component={Campaign}
						/>
						<ProtectedRoute
							claim="campaigns"
							path="/v2/campaigns"
							component={Campaigns}
						/>

						<ProtectedRoute
							claim="campaigns"
							path="/v2/coupon_specs/:specId"
							component={CouponSpec}
						/>
						<ProtectedRoute
							claim="contests"
							path="/v2/contests/:contestId"
							component={ContestPage}
						/>
						<ProtectedRoute
							claim="contests"
							path="/v2/contests"
							component={Contests}
						/>

						<ProtectedRoute
							path="/history"
							component={History} />

						<ProtectedRoute
							claim="reports"
							path="/reports"
							component={ReportsV2} />

						<ProtectedRoute
							claim="support_requests"
							path="/support-requests/:id"
							component={SupportRequest}
						/>
						<ProtectedRoute
							claim="support_requests"
							path="/support-requests"
							component={ListSupportRequests}
						/>

						<ProtectedRoute
							claim="notifications"
							path="/notifications/:notification_id"
							component={NotificationsDetail} />
						<ProtectedRoute
							claim="notifications"
							path="/notifications"
							component={NotificationsList} />

						<ProtectedRoute
							claim="products"
							path="/products/:product_id"
							component={ProductDetail} />
						<ProtectedRoute
							claim="products"
							path="/products"
							component={ListProducts} />

						<ProtectedRoute
							claim="products"
							path="/operators/:operator_id"
							component={OperatorDetail} />
						<ProtectedRoute
							claim="products"
							path="/operators"
							component={ListOperators} />


						<ProtectedRoute
							claim="permissions"
							path="/permissions"
							component={PermissionsV2}
						/>

						<ProtectedRoute
							path="/user-search"
							component={UserSearch}
						/>
						{/* <ProtectedRoute
							path="/places-tasks"
							component={PlacesTasksView}
						/> */}

						<ProtectedRoute
							claim="cache"
							path="/cache"
							component={CacheUtils}
						/>

						<ProtectedRoute
							claim="appconfig"
							path="/app/settings"
							component={AppConfig}
						/>


						<ProtectedRoute
							path="/"
							exact component={Overview} />
					</Switch>
				</Container>
			</div>
		</AuthProvider>
	);
};
