import { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Rental, getRentals } from "../../api/rentals-new";
import { RentalList } from "./RentalList";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { TimePicker } from "../TimePicker";
import { startOfDay, endOfDay } from "date-fns";
import { useHistory } from "react-router"
import { useLocation } from "react-router-dom";

// Poll the rentals every 5 seconds
const rentalsPollInterval = 5000;

const useStyles = makeStyles({
	grid: {
		paddingTop: 10,
	},
});

type Filters = {
	vehicleCode?: string;
	from: Date | null;
	to: Date | null;
};

export const Rentals = () => {
	const search = useLocation().search;
	const from_date = new URLSearchParams(search).get('from');
	const to_date = new URLSearchParams(search).get('to');
	const history = useHistory();
	const classes = useStyles();
	const [rentals, setRentals] = useState<Array<Rental>>([]);
	const [filters, setFilters] = useState<Filters>({
		from: startOfDay(new Date()),
		to: endOfDay(new Date()),
	});

	useEffect(() => {
		if (from_date) {
			let fromParsed: Date = new Date(from_date);
			//setFilters({ ...filters, from: fromParsed });
			setFilters((prev) => ({ ...prev, from: fromParsed }));
		}
		if (to_date) {
			let toParsed: Date = new Date(to_date);
			//setFilters({ ...filters, to: toParsed });
			setFilters((prev) => ({ ...prev, to: toParsed }));
		}
	}, [from_date, to_date]);

		const fetchRentalsCallback = useCallback(() => {
		getRentals({
			vehicleCode: !!filters.vehicleCode
				? filters.vehicleCode
				: undefined,
			from: !!filters.from ? filters.from : undefined,
			to: !!filters.to ? filters.to : undefined,
		}).then(setRentals);
	}, [filters]);

	useEffect(() => {
		fetchRentalsCallback();

		// Set up a timer to poll the state of the rental.
		const intervalId = setInterval(
			(prev) => {
				if (prev) {
					// Clearing interval
					clearInterval(prev);
				}
				fetchRentalsCallback();
			}, rentalsPollInterval);
		return () => clearInterval(intervalId);
	}, [fetchRentalsCallback]);

	const handleVehicleCodeInputChange = (
		ev: React.ChangeEvent<HTMLInputElement>,
	) => {
		setFilters({ ...filters, vehicleCode: ev.target.value });
	};

	const handleFromTimeChange = (date: Date | null) => {
		setFilters({ ...filters, from: date });
		history.push(`/v2/rentals/?from=${filters.from?.toISOString()}&to=${filters.to?.toISOString()}`);
	};

	const handleToTimeChange = (date: Date | null) => {
		setFilters({ ...filters, to: date });
		history.push(`/v2/rentals/?from=${filters.from?.toISOString()}&to=${filters.to?.toISOString()}`);
	};

	return (
		<Grid className={classes.grid} container direction="column" spacing={2}>
			<Grid
				direction="row"
				justifyContent="space-between"
				wrap="nowrap"
				container
				item
			>
				<Grid xs={2} item>
					<FormControl margin="normal">
						<InputLabel shrink={true} htmlFor="vehicle_code_input">
							Vehicle Code
						</InputLabel>
						<Input
							id="vehicle_code_input"
							onChange={handleVehicleCodeInputChange}
						/>
					</FormControl>
				</Grid>
				<Grid xs={3} justifyContent="flex-start" item container>
					<Grid item>
						<TimePicker
							defaultDate={filters.from}
							onChange={handleFromTimeChange}
							label="From"
						/>
					</Grid>
					<Grid item>
						<TimePicker
							defaultDate={filters.to}
							onChange={handleToTimeChange}
							label="To"
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<RentalList rows={rentals} />
			</Grid>
		</Grid>
	);
};
