import { Payment } from "../../api/orders_v2";
import {
    Box,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    Typography,
    Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { amber, green, red, blue } from "@material-ui/core/colors";
import DateLabel from "../general/DateLabel";
import { useEffect, useState } from "react";
import { UserCreditModal } from "./UserCreditModal";
import { ProtectedComponent } from "../ProtectedComponent";
import { is } from "date-fns/locale";

const asset_base = "https://storage.googleapis.com/travis-assets-prod/";

const useStyles = makeStyles((theme) => ({
    rowHover: {
        cursor: "pointer"
    },
    smallAvatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    createdDate: {
        color: "#777",
    },
    createdDateToday: {
        color: "#B22",
    },
    updatedDate: {
        color: "#333",
    },
    updatedDateToday: {
        color: "#F00",
    },
    chipAllocated: {
        backgroundColor: blue[200],
        color: "#000",
    },
    chipReady: {
        backgroundColor: blue[200],
        color: "#000",
    },
    chipPayment: {
        backgroundColor: amber[200],
        color: "#000",
    },
    chipFulfillment: {
        backgroundColor: amber[200],
        color: "#000",
    },
    chipComplete: {
        backgroundColor: green[500],
        color: "#FFF",
    },
    chipCredited: {
        backgroundColor: amber[500],
        color: "#FFF",
    },
    chipCancelled: {
        backgroundColor: red[500],
        color: "#FFF",
    },
    collapsibleCell: {
        paddingBottom: 0,
        paddingTop: 0,
        backgroundColor: "#f5f5f5",
    },
    hiddenCell: {
        borderWidth: 0,
        paddingBottom: 0,
        paddingTop: 0,
        backgroundColor: "#f5f5f5",
    },
    saleLine: {
        fontWeight: "bold",
        color: green[800],
        margin: 0,
    },
    capturedLine: {
        color: green[800],
        margin: 0,
    },
    creditedLine: {
        color: amber[800],
        margin: 0,
    },
}));

interface UserPaymentProps {
    payment: Payment;
};

export const UserPayment = ({ payment: payment }: UserPaymentProps) => {
    const classes = useStyles();

    const [isCreditable, setIsCreditable] = useState<boolean>(payment.captured - payment.credited > 0);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    useEffect(() => {
        if (!isModalVisible) {
            // // Don't update the creditable state while the modal is open
            setIsCreditable(payment.captured - payment.credited > 0);
        }
    }, [payment, isModalVisible]);

    const isToday = (timestamp: number) => {
        if (timestamp === 0) {
            return false
        }

        const today = new Date();
        const givenDate = new Date(timestamp * 1000);

        return (
            givenDate.getDate() === today.getDate() &&
            givenDate.getMonth() === today.getMonth() &&
            givenDate.getFullYear() === today.getFullYear()
        );
    }

    const paymentAmounts = (payment: Payment) => {
        return [
            { amount: payment.amount, className: classes.saleLine, suffix: "Authorized" },
            { amount: payment.captured, className: classes.capturedLine, suffix: "Captured" },
            { amount: payment.credited, className: classes.creditedLine, suffix: "Credited" },
        ]
            .map(({ amount, className, suffix }) => {
                if (amount > 0) {
                    //return <p className={className}>{amount / 100} {payment.currency}{suffix}</p>
                    return <TableRow>
                        <TableCell align="left" style={{ "borderBottom": "none" }}>
                            <p className={className}>{suffix}</p>
                        </TableCell>
                        <TableCell align="left" style={{ "borderBottom": "none" }}>
                            <p className={className}>{amount / 100} {payment.currency}</p>
                        </TableCell>
                    </TableRow>
                }

                return undefined;
            })
            .filter((elem) => elem !== undefined)
    }

    const onCreditClicked = () => {
        setIsModalVisible(true);

        // Disable the credit button, if the payment is still technically
        // creditable the button will be reactivated when the state of the
        // payment is updated.
        setIsCreditable(false);
    };

    const onModalClose = () => {
        setIsModalVisible(false);
    };

    return (
        <div>
            <Box margin={3}>
                <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                    <Typography variant="h6" gutterBottom component="div">
                        Payment
                    </Typography>
                </Box>
                <Table size="small" aria-label="payments">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="left">Amounts</TableCell>
                            <TableCell align="right">Created / Updated</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <strong>{payment.id}</strong><br />
                                {payment.transaction_id}
                            </TableCell>
                            <TableCell>
                                <Table size="small" padding="none" aria-label="history">
                                    {paymentAmounts(payment).map((elem) => {
                                        return elem;
                                    })}
                                </Table>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="caption">
                                    <span className={isToday(payment.created) ? classes.createdDateToday : classes.createdDate}><DateLabel inputDate={payment.created} /></span><br />
                                    <span className={isToday(payment.updated) ? classes.updatedDateToday : classes.updatedDate}><DateLabel inputDate={payment.updated} /></span>
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <ProtectedComponent claim={"refunds"}>
                                    <Button variant="contained" color="secondary" disabled={!isCreditable || isModalVisible} onClick={onCreditClicked}>Credit</Button>
                                </ProtectedComponent>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
            {isModalVisible &&
                <UserCreditModal payment={payment} onClose={() => onModalClose()} isOpen={isModalVisible} />
            }
        </div >
    );
}