import { useCallback, useEffect, useState } from "react";
import { MouseEvent } from "react";
import { Rental, endRental, getRentals } from "../../api/rentals_v2";
import { Button, FormControl, Grid, Input, InputLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, makeStyles } from "@material-ui/core";
import { OperatorIcon } from "../operator_icon";
import { Link, useHistory, useLocation } from "react-router-dom";
import { endOfDay, format, startOfDay } from "date-fns";
import TimePicker from "../TimePicker";
import { Alert } from "@material-ui/lab";

// Poll the rentals every 5 seconds
const rentalsPollInterval = 5000;

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    grid: {
        paddingTop: 10,
    },
});

type Filters = {
    vehicleCode?: string;
    from: Date | null;
    to: Date | null;
};

export const RentalsV2 = () => {
    const timeFmt = "yyyy/MM/dd kk:mm:ss";

    const history = useHistory();

    const search = useLocation().search; // The query string part
    const fromDate = new URLSearchParams(search).get('from');
    const toDate = new URLSearchParams(search).get('to');
    const [filters, setFilters] = useState<Filters>({
        from: startOfDay(new Date()),
        to: endOfDay(new Date()),
    });

    const classes = useStyles();

    const [alert, setAlert] = useState<string>("");
    const [rentals, setRentals] = useState<Array<Rental>>([]);
    const [now, setNow] = useState(new Date());

    useEffect(() => {
        if (fromDate) {
            let fromParsed: Date = new Date(fromDate);
            setFilters((prev) => ({ ...prev, from: fromParsed }));
        }

        if (toDate) {
            let toParsed: Date = new Date(toDate);
            setFilters((prev) => ({ ...prev, to: toParsed }));
        }

    }, [fromDate, toDate]);

    const fetchRentalsCallback = useCallback(() => {
        setNow(new Date());

        if (filters.from === filters.to) {
            return;
        }

        getRentals(
            filters.vehicleCode,
            filters.from ?? startOfDay(new Date()),
            filters.to ?? endOfDay(new Date())
        )
            .then(setRentals);
    }, [filters]);

    useEffect(() => {
        fetchRentalsCallback()

        // Set up a timer to poll the state of the rental.
        const intervalId = setInterval(
            (prev) => {
                if (prev) {
                    // Clearing interval
                    clearInterval(prev);
                }
                fetchRentalsCallback();
            }, rentalsPollInterval);
        return () => clearInterval(intervalId);
    }, [fetchRentalsCallback]);


    const handleVehicleCodeInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setFilters({ ...filters, vehicleCode: ev.target.value });
    };

    const handleFromTimeChange = (date: Date | null) => {
        try {
            const dateISO = date?.toISOString();
            setFilters({ ...filters, from: date });
            history.push(`/v2/rentalsV2?from=${dateISO}&to=${filters.to?.toISOString()}`);
        } catch (err) { }
    };

    const handleToTimeChange = (date: Date | null) => {
        try {
            const dateISO = date?.toISOString();
            setFilters({ ...filters, to: date });
            history.push(`/v2/rentalsV2?from=${filters.from?.toISOString()}&to=${dateISO}`);
        } catch (err) { }
    };

    const handleEndRideClick = (e: any, id: string): void => {
        endRental(id)
            .then(() => {
                history.push(`/v2/rentalsV2/${id}`);
            })
            .catch((err) => {
                setAlert(`Failed to end ride ${id}`);
            });
    };

    return (
        <Grid container className={classes.grid} direction="column" spacing={2}>
            { /* Alert message */}
            {alert !== "" &&
                <Grid item>
                    <Alert severity="error">{alert}</Alert>
                </Grid>
            }

            { /* Top of page inputs */}
            <Grid container item direction="row" justifyContent="space-between" wrap="nowrap">

                { /* The vehicle code input field */}
                <Grid item>
                    <FormControl margin="normal">
                        <InputLabel shrink={true} htmlFor="vehicle_code_input">
                            Vehicle Code
                        </InputLabel>
                        <Input
                            id="vehicle_code_input"
                            onChange={handleVehicleCodeInputChange}
                        />
                    </FormControl>
                </Grid>

                { /* The time pickers */}
                <Grid item>
                    <Grid item>
                        <TimePicker
                            defaultDate={filters.from}
                            onChange={handleFromTimeChange}
                            label="From"
                        />
                    </Grid>
                    <Grid item>
                        <TimePicker
                            defaultDate={filters.to}
                            onChange={handleToTimeChange}
                            label="To"
                        />
                    </Grid>
                </Grid >
            </Grid >

            { /* The rentals table */}
            <Grid item>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">

                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Code</TableCell>
                                <TableCell>ID</TableCell>
                                <TableCell>User</TableCell>
                                <TableCell>Start/End</TableCell>
                                <TableCell align="right">Duration(m)</TableCell>
                                <TableCell align="right" />
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rentals.map((rental) => (
                                <TableRow key={rental.id} hover>

                                    <TableCell component="th" scope="row">
                                        <Link to={`/v2/rentalsV2/${rental.id}`}>
                                            <OperatorIcon operator={rental.operator_id} />
                                        </Link>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Link to={`/v2/rentalsV2/${rental.id}`}>
                                            {rental.vehicle_code}
                                        </Link>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Link to={`/v2/rentalsV2/${rental.id}`}>
                                            {rental.id}
                                        </Link>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <Link to={`/users/${rental.user_id}`}>
                                            {rental.user_id}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{format(new Date(rental.started * 1000), timeFmt)}</Typography>
                                        {rental.ended !== 0 &&
                                            <>
                                                <br />
                                                <Typography color="textSecondary">{format(new Date(rental.ended * 1000), timeFmt)}</Typography>
                                            </>
                                        }
                                    </TableCell>
                                    <TableCell component="th" scope="row" align="right">
                                        {/* Round time up to nearest minute */}
                                        {rental.active ? Math.ceil(((now.getTime() / 1000) - rental.started) / 60) :
                                            Math.ceil((rental.ended - rental.started) / 60)}

                                    </TableCell>
                                    <TableCell align="right">
                                        {rental.active ? <Button variant="contained" color="secondary" id="end-ride-button"
                                            onClick={(e) => handleEndRideClick(e, rental.id)}>End Ride</Button> : <></>}
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>
            </Grid>
        </Grid >
    );
};