import { ReactNode, useState, createContext, useContext } from 'react';

interface IFlexTicketContext {
    children: ReactNode;
}

export const FlexticketContext = createContext({ 
    flexTicketId: "", 
    setFlexTicketId: (id: string) => {} 
})

export const FlexTicketContextProvider = ({ children }: IFlexTicketContext) => {
    const [flexTicketId, setFlexTicketId] = useState<string>("");

    return (
        <FlexticketContext.Provider value={{ flexTicketId, setFlexTicketId }}>
            {children}
        </FlexticketContext.Provider>
    )
}

export const useFlexTicketContext = () => useContext(FlexticketContext)