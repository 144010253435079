import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, makeStyles } from "@material-ui/core";
import { CreditOrder } from "../../api/orders_v2";
import DateLabel from "../general/DateLabel";

const useStyles = makeStyles((theme) => ({
    createdDate: {
        color: "#777",
    },
    createdDateToday: {
        color: "#B22",
    },
    updatedDate: {
        color: "#333",
    },
    updatedDateToday: {
        color: "#F00",
    },
}));

type CreditOrdersProps = {
    credit_orders: CreditOrder[];
};

export const UserCreditOrders = ({ credit_orders: credit_orders }: CreditOrdersProps) => {
    const classes = useStyles();

    const isToday = (timestamp: number) => {
        if (timestamp === 0) {
            return false
        }

        const today = new Date();
        const givenDate = new Date(timestamp * 1000);

        return (
            givenDate.getDate() === today.getDate() &&
            givenDate.getMonth() === today.getMonth() &&
            givenDate.getFullYear() === today.getFullYear()
        );
    }

    return <Box margin={3}>
        <Typography variant="h6" gutterBottom component="div">
            {"Credit orders"}
        </Typography>
        <Table size="small" aria-label="credit orders">
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Source</TableCell>
                    <TableCell>Creditor</TableCell>
                    <TableCell>Reason</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Created / Updated</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {credit_orders.map((credit_order) =>
                    <TableRow>
                        <TableCell>{credit_order.id}</TableCell>
                        <TableCell>{credit_order.source.length > 0 ? credit_order.source : "-"}</TableCell>
                        <TableCell>{credit_order.creditor_id.length > 0 ? credit_order.creditor_id : "-"}</TableCell>
                        <TableCell>{credit_order.reason}</TableCell>
                        <TableCell align="right">{credit_order.amount.including_vat.formatted}</TableCell>
                        <TableCell align="right">
                            <Typography variant="caption">
                                <span className={isToday(credit_order.created) ? classes.createdDateToday : classes.createdDate}><DateLabel inputDate={credit_order.created} /></span><br />
                                <span className={isToday(credit_order.updated) ? classes.updatedDateToday : classes.updatedDate}><DateLabel inputDate={credit_order.updated} /></span>
                            </Typography>
                        </TableCell>
                    </TableRow>
                )}

            </TableBody>

        </Table>
    </Box>
}