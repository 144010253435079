import { baseUrl } from "./config"
import { getToken } from "./index";

export type Git = {
	version?: string;
	branch?: string;
};

export type AppVersion = {
	git?: Git;
	build_time?: string;
};

export const getAppVersion = async (): Promise<AppVersion> => {
	return fetch(`${baseUrl}/api/v2/version`, {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	}).then((response) => response.json());
};
