import { baseUrl } from "./config";
import { getToken } from "./index";
import { checkErrors } from "./utils";

// getNotifications retrieves all notifications in the currently
// active instance
export const getNotifications = async (): Promise<Notifications> => {
	return fetch(`${baseUrl}/api/v2/notifications`, {
			headers: {
				Authorization: `Bearer ${await getToken()}`,
			},
		})
		.then(checkErrors)
		.then((response) => response.json());
};

// getNotification retrieves a single notification entry
export const getNotification = async (notificationId: string): Promise<Notification> => {
	return fetch(`${baseUrl}/api/v2/notifications/${notificationId}`, {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
	.then(checkErrors)
	.then((response) => response.json())
	.then((data) => {
		if (data.notification) return data.notification;
		throw new Error("invalid payload");
	});
}

// updateNotification updates a single notification
export const updateNotification = async (notificationId: string, notification: Notification): Promise<Notification> => {
	return fetch(`${baseUrl}/api/v2/notifications/${notificationId}`, {
		method: "PATCH",
		body: JSON.stringify(notification),
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
	.then(checkErrors)
	.then((response) => response.json())
	.then((data) => {
		if (data.notification) return data.notification;
		throw new Error("invalid payload");
	});
}

// cancelNotification cancels a single notification
export const cancelNotification = async (notificationId: string, notification: Notification): Promise<Notification> => {
	// State 3 is the cancelled state
	notification.push_notification_state = 3;
	return updateNotification(notificationId, notification)
}

// createNotification creates a new notification
export const createNotification = async (notification: Notification): Promise<Notification> => {
	return fetch(`${baseUrl}/api/v2/notifications`, {
		method: "POST",
		body: JSON.stringify(notification),
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
	.then(checkErrors)
	.then((response) => response.json())
	.then((data) => {
		if (data.notification) return data.notification;
		throw new Error("invalid payload");
	});
}

// deleteNotification deletes a single notification
export const deleteNotification = async (notificationId: string): Promise<Notification> => {
	return fetch(`${baseUrl}/api/v2/notifications/${notificationId}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
	.then(checkErrors)
	.then((response) => response.json());
}


// sendNotification sends a single notification 
export const sendNotification = async (title:string, body:string, token: string): Promise<TestResponse> => {
	let payload = {
		title:title,
		body:body,
		token:token,
	}
	return fetch(`${baseUrl}/api/v2/notifications/test`, {
		method: "POST",
		body: JSON.stringify(payload),
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
	.then(checkErrors)
	.then((response) => response.json())
	.then((data) => {
		if (data) return data;
		throw new Error("invalid payload");
	});
}

export const uploadFile = async (files: File[]): Promise<UploadResponse> => {
	if (files.length === 0) {
		throw new Error("no files selected");
	}

	const file = files[0];
	const req = new FormData();
	req.append("image", file);

	return fetch(`${baseUrl}/api/v2/notifications/image`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
		body: req,
	})
		.then(checkErrors)
		.then((response) => response.json());
}

// Notifications holds the response payload of the endpoint
export type Notifications = {
	notifications: Notification[];
}

// A single notification document
export type Notification = {
	// Info
	notification_id: string;
	title: string
	is_active?: boolean;

	// Delivery: target
	device: string[]; // "ios", "android", ""
	delivery: string[]; // "app"

	// Delivery: schedule
	start_time?: Date;
	end_time?: Date;

	// Unix timestamps, to replace string based timestamps
	start_at?: number;
	end_at?: number;
	created: number;

	// Content: general
	type: string; // "deviations" | "tickets" 
	title_sv?: string;
	title_en?: string;
	title_ar?: string;
	content_sv?: string;
	content_en?: string;
	content_ar?: string;
	image_url?: string;

	// Content: action
	cta_sv?: string;
	cta_en?: string;
	cta_ar?: string;
	target?: string; // "payments"
	target_protocol?: string; // "travis://"

	target_version_comparator?: string;
	target_version?: string;

	change_log: ChangeLogEntry[];
	push_notification_state?: number;
}

export type ChangeLogEntry = {
	source:string;
	event:string;
	created:number;
}

export type TestResponse = {
	result:string;
	error:string;
}

export type UploadResponse = {
	link: string;
}