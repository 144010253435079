import react from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { creditPayment, Payment } from '../../api/orders_v2';
import { Table, TableCell, TableRow, Typography } from '@material-ui/core';
import { ResponseError } from '../../api/utils';

type UserCreditModalProps = {
    payment: Payment;
    onClose: () => void;
    isOpen: boolean;
};

export const UserCreditModal = ({ payment: payment, onClose: onClose, isOpen: isOpen }: UserCreditModalProps) => {

    // Display progress spinner
    const [inProgress, setInProgress] = react.useState<boolean>(false);

    // Display error message if any
    const [alertMessage, setAlertMessage] = react.useState<string>("");

    // The maximum creditable amount
    const [creditableAmount, setCreditableAmount] = react.useState<number>(0);

    // The amount the user wishes to credit
    const [creditAmount, setCreditAmount] = react.useState<number>(0);

    // The amount the user wishes to credit in string representation
    const [creditAmountString, setCreditAmountString] = react.useState<string>("");

    // The reason for the credit
    const [reason, setReason] = react.useState<string>("");

    // Whether the credit button is enabled, depending on the validity of the amount and reason
    const [isCreditable, setIsCreditable] = react.useState<boolean>(false);

    const amountStringToCents = (stringAmount: string): number => {
        return Math.trunc(parseFloat(stringAmount) * 100);
    };

    react.useEffect(() => {
        const moneyLeft = payment.captured - payment.credited;
        setIsCreditable(reason.length > 0 && creditAmount > 0 && creditAmount <= creditableAmount);
        setCreditableAmount(moneyLeft);
    }, [payment]);

    react.useEffect(() => {
        if (!isOpen) {
            return;
        }

        /* Reset all fields when the modal is opened */
        const moneyLeft = payment.captured - payment.credited;
        setCreditAmountString(`${moneyLeft / 100}`);
        setCreditAmount(moneyLeft);
        setReason("");
        setInProgress(false);
        setAlertMessage("");
    }, [isOpen]);

    react.useEffect(() => {
        setIsCreditable(reason.length > 0 && creditAmount > 0 && creditAmount <= creditableAmount);
    }, [reason, creditAmount]);

    const onModalClose = () => {
        onClose();
    };

    const onModalCreditClicked = () => {
        setAlertMessage("");
        setInProgress(true);

        creditPayment(payment, creditAmount, reason)
            .then(() => {
                onModalClose();
            })
            .catch((err: ResponseError) => {
                err.response.json().then((body) => setAlertMessage("Failed to credit order because: " + body['error_message']));
            })
            .finally(() => {
                setInProgress(false);
            });
    };

    const onModalCreditAmountChanged = (value: string) => {
        setCreditAmountString(value);
        setCreditAmount(0);

        if (!/^\d+(\.\d+)*$/.test(value)) {
            setAlertMessage("Invalid amount");
            return;
        }

        const amount = amountStringToCents(value);
        if (isNaN(amount)) {
            setAlertMessage("Invalid amount");
            return
        }

        if (amount > creditableAmount) {
            setAlertMessage("Credit amount exceeds creditable amount");
            return;
        }

        setAlertMessage("");
        setCreditAmount(amount);
    };

    const onModalCreditReasonChanged = (value: string) => {
        setReason(value);
    };

    return (
        <>
            <Dialog open={true} aria-labelledby="form-dialog-title" >
                <DialogTitle id="form-dialog-title">Credit payment</DialogTitle>
                <DialogContent>
                    {inProgress &&
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CircularProgress />
                        </div>
                    }
                    {alertMessage.length > 0 &&
                        <Typography color="secondary">{alertMessage}</Typography>
                    }
                    {!inProgress &&
                        <Table>
                            <TableRow>
                                <TableCell>
                                    <Typography>Amount</Typography>
                                </TableCell>
                                <TableCell align='left'>
                                    <TextField
                                        type="text"
                                        value={creditAmountString}
                                        color={isNaN(creditAmount) ? "secondary" : "primary"}
                                        variant="filled"
                                        onChange={(ev) => onModalCreditAmountChanged(ev.target.value)}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography>Reason</Typography>
                                </TableCell>
                                <TableCell align='left'>
                                    <TextField
                                        placeholder="State reason"
                                        type="text"
                                        fullWidth
                                        value={reason}
                                        variant="outlined"
                                        onChange={(ev) => onModalCreditReasonChanged(ev.target.value)} />

                                </TableCell>
                            </TableRow>
                        </Table>
                    }
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Button disabled={inProgress} color="secondary" onClick={onModalClose}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={inProgress || !isCreditable}
                                variant="contained"
                                onClick={onModalCreditClicked}
                                color="primary">
                                Credit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
}