import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import { Table, TableHead, TableBody, TableCell, TableRow, Grid } from "@material-ui/core";
import { Entry, getLogs, LogResult } from "../../api/log";
import { DateLabel } from "../general/DateLabel";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import PlatformIcon from './PlatformIcon';
import {
	MuiPickersUtilsProvider,
	DatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";


interface LogInspectorProps {
	from: Date;
	to: Date;
	terms: string[];
	open: boolean;
	onClose: Function;
}

export const LogInspector = (props: LogInspectorProps) => {
	const [logData, setLogData] = useState<LogResult>();
	const [loading, setLoading] = useState<boolean>(false);
	const [terms, setTerms] = useState<string[]>(props.terms);
	const [colors, setColors] = useState<{[id: string]: string}>({});
	const [fromDate, setFromDate] = useState<Date>(props.from);
	const [toDate, setToDate] = useState<Date>(props.to);

	const handleFromDateChange = (date: any, value?: string | null | undefined) => {
		if (date !== undefined) {
			setFromDate(date);
		}
	};

	const handleToDateChange = (date: any, value?: string | null | undefined) => {
		if (date !== undefined) {
			setToDate(date);
		}
	};
	
	const handleClose = () => {
		setLogData({});
		props.onClose();
	};

	const requestIdColor = function (requestId?: string): string {
		if ((requestId === undefined) || (requestId === null)) {
			return "";
		}

		let col: string = colors[requestId];
		if (col !== undefined) {
			return col;
		}
		
		let color: string = 'rgb('+Math.round(150+Math.random()*100)+','+Math.round(150+Math.random()*100)+','+Math.round(150+Math.random()*100)+')';
		let copyColors = colors;
		copyColors[requestId] = color;
		setColors(copyColors);

		return color;
	}

	const didChangeValue = (
		event: React.ChangeEvent<{}>,
		value: (string | string[])[],
		reason: string) => {
		// reason = One of "create-option", "select-option", "remove-option", "blur" or "clear".
		console.log(reason, value);
		let val = value as string[];
		setTerms(val);
	}

	const addIcon = (term:string|undefined) => {
		if (term === undefined) {
			return;
		}

		if (!terms.includes(term)) {
			return <AddCircleIcon />;
		}

		if (terms.includes(term)) {
			return <RemoveCircleIcon />;
		}

		return <></>;
	}

	const toggleTerm = (term:string|undefined) => {
		const spanIdTag = "@dd.span_id:"
		if (term === undefined) {
			return;
		}
		
		let copyTerms: string[] = terms;
		if (copyTerms.includes(term)) {
			copyTerms.splice(copyTerms.indexOf(term), 1);
		} else {
			copyTerms.push(`${spanIdTag}"${term}"`);
		}

		setTerms(copyTerms);
		console.log("New terms", copyTerms);
		
		getLogs(fromDate,toDate, "OR", terms.join(","))
			.then((data)=>{
				console.log("Got log data");
				
				setLogData(data);
			}).catch((err)=>{
				
				console.log("Failed retrieving logs", err)
			})
	}

	const getLabel = (entry:Entry):string => {
		if (entry.line && entry.file) { return entry.file + ":" + entry.line; }
		if (entry.file) { return entry.file }
		if (entry.module) { return entry.module }
		return "n/a";
	}

	const getRequestLabel = (entry:Entry):string => {
		if (entry.request_id) { 
			if (entry.request_id.length > 20) {
				return "..." + entry.request_id.slice(entry.request_id.length-6)
			}
			return entry.request_id; 
		}
		return "n/a";
	}

	const highlightMessage = (input?:string) => {
		if (input) {
			return input;
		} else {
			return "";
		}
	}

	const LogInspectorRow = ({entry}: {entry:Entry}) => {

		const [isOpen, setIsOpen] = useState<boolean>(false);

		return (<>
				<TableRow hover onClick={() => setIsOpen(!isOpen)}>
					<TableCell style={{fontSize:"9pt",whiteSpace:"nowrap",fontWeight:"bold"}}>
						<DateLabel inputDate={entry.timestamp} inputFormat="nano" />
					</TableCell>
					<TableCell style={{textOverflow:"ellipsis", overflow:"hidden"}}>
						{highlightMessage(entry.message)}
					</TableCell>
					<TableCell>
						{entry.user_agent && entry.user_agent.platform && entry.user_agent.app_version &&
						<>
						<Chip 
							size="small" 
							style={{fontSize:"8pt",backgroundColor:"#efefff"}}
							avatar={<PlatformIcon 
										style={{fontSize:"10pt",paddingLeft:"5px"}} 
										platform={entry.user_agent.platform} />} 
							label={entry.user_agent.app_version} />
						</>}
					</TableCell>
					<TableCell style={{textAlign:"right", whiteSpace:"nowrap"}}>
						<Chip 
							size="small" 
							label={getLabel(entry)} 
							style={{fontSize:"8pt"}}
							/>
					
						<Chip 
							size="small"
							label={getRequestLabel(entry)} 
							style={{marginLeft:"6px",fontSize:"8pt",backgroundColor: requestIdColor(entry.request_id)}}
							onDelete={()=>toggleTerm(entry.request_id)}
							deleteIcon={addIcon(entry.request_id)}
							/>
					</TableCell>
				</TableRow>
				{isOpen && 
				<TableRow style={{ borderTop:0, paddingBottom: 0, paddingTop: 0, backgroundColor: "#f5f5f5" }}>
					<TableCell></TableCell>
					<TableCell>
						{entry.user_agent && 
						<Table size="small">
							<TableBody>
								{entry.user_agent.os_version &&
								<TableRow>
									<TableCell width={30}>OS</TableCell>
									<TableCell>{entry.user_agent.os_version} {entry.user_agent.os_build}</TableCell>
								</TableRow>}
								{entry.user_agent.manufacturer &&
								<TableRow>
									<TableCell>Device</TableCell>
									<TableCell>{entry.user_agent.manufacturer} {entry.user_agent.model}</TableCell>
								</TableRow>}
								{entry.user_agent.app_agent &&
								<TableRow>
									<TableCell>Agent</TableCell>
									<TableCell>{entry.user_agent.app_agent}</TableCell>
								</TableRow>}
								{entry.user_agent.app_build &&
								<TableRow>
									<TableCell>Build</TableCell>
									<TableCell>{entry.user_agent.app_build}</TableCell>
								</TableRow>}
								{entry.user_agent.app_version &&
								<TableRow>
									<TableCell>Client</TableCell>
									<TableCell>{entry.user_agent.app_name} {entry.user_agent.app_version}</TableCell>
								</TableRow>}
							</TableBody>
						</Table>}
					</TableCell>
					<TableCell></TableCell>
					<TableCell></TableCell>
				</TableRow>}
				</>);
	}

	useEffect(() => {
		if (terms.length === 0) {
			setLogData({});
			return;
		}

		let startDate: Date = fromDate;
		let endDate: Date = toDate;
		let condition: string = "OR";
		let query: string = terms.join(",");

		setLoading(true);
		getLogs(startDate,endDate,condition,query)
			.then((data)=>{
				setLoading(false);
				setLogData(data);
			}).catch((err)=>{
				setLoading(false);
				console.log("Failed retrieving logs", err)
			})
		
	}, [fromDate, toDate, terms]);

	useEffect(()=> {
		setTerms(props.terms);
		setFromDate(props.from);
		setToDate(props.to);

	},[props.terms, props.from, props.to]);

	return (
		<>
		{props.open && 
		<Dialog fullWidth={true} maxWidth="xl" open={props.open} onClose={handleClose}>
			<DialogTitle>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<Grid container spacing={2} justifyContent="space-between">
						<Grid item xs={8}>
							<Autocomplete
								multiple
								id="tags-filled"
								options={[]}
								defaultValue={[]}
								value={terms}
								onChange={didChangeValue}
								freeSolo
								renderTags={(value: readonly string[], getTagProps) =>
								value.map((option: string, index: number) => (
									<Chip 
										variant="default" 
										label={option} {...getTagProps({ index })} />
								))
								}
								renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label="Filter"
									placeholder="Type here ..."
									/>
								)}
								/>
							</Grid>
							<Grid item xs={2}>									
								<DatePicker
									inputVariant="outlined"
									id="from-date"
									label="From"
									format="yyyy-MM-dd"
									value={fromDate}
									onChange={handleFromDateChange}
									autoOk={true}
									disableToolbar={true}
									/>
							</Grid>
							<Grid item xs={2}>
								<DatePicker
									inputVariant="outlined"
									id="to-date"
									label="To"
									format="yyyy-MM-dd"
									value={toDate}
									onChange={handleToDateChange}
									autoOk={true}
									disableToolbar={true}
									/>
							</Grid>
					</Grid>
				</MuiPickersUtilsProvider>
			</DialogTitle>
			<DialogContent>
			<Box>
			{loading &&
				<Grid container justifyContent="center">
					<CircularProgress />
				</Grid>}

			{logData && !loading &&  
				<Table size="small">
					<TableHead>
						<TableCell width={130}>Timestamp</TableCell>
						<TableCell>Message</TableCell>
						<TableCell>Client</TableCell>
						<TableCell width={20} style={{textAlign:"right"}}>Span ID</TableCell>
					</TableHead>
					<TableBody>
						{logData.entries?.map((entry, idx) => 
						<LogInspectorRow 
							key={idx + "-" + entry.timestamp + "-" + entry.request_id}
							entry={entry} 
							/>)}
					</TableBody>
				</Table>
			}
			</Box>
			</DialogContent>
        	<DialogActions>
				<Button onClick={handleClose} variant="outlined" size="large">
					Close
				</Button>
			</DialogActions>
		</Dialog>}
	</>
	);
}



export default LogInspector