import { baseUrl } from "./config";
import { getToken } from "./index";

export enum SupportRequestStatus {
	New = "New",
	Open = "Open",
	Closed = "Closed",
}

export type SupportRequest = {
	id: string;
	case_number: string;
	user_id: string;
	app_version: string;
	app_platform: string;
	note?: string;
	notes: SupportRequestNote[];
	status: SupportRequestStatus;
	created: string;
	created_at: number;
	modified: string;
	modified_at: number;
};

export type SupportRequestNote = {
	id: string;
	note: string;
	user: string;
	created: number;
	is_system: boolean;
}

export type SupportRequestUpdate = {
	status?: string;
	note?: string;
};

export type SupportRequestQuery = {
	pageSize: number;
	lastPageID: string;
	statuses: SupportRequestStatus[];
	from: Date | undefined;
	to: Date | undefined;
};

export const getSupportRequests = async (query: SupportRequestQuery): Promise<SupportRequest[]> => {
	let url = `${baseUrl}/api/v1/support_requests?page_size=${query.pageSize}&last_id=${query.lastPageID}&statuses=${query.statuses.join(",")}`
	if (query.from) {
		url += `&from=${query.from.toISOString()}`
	}
	if (query.to) {
		url += `&to=${query.to.toISOString()}`
	}

	return fetch(url, {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	}).then((response) => response.json());
};

export const getSupportRequest = async (id: string): Promise<SupportRequest | undefined> => {
	if (!id) throw new Error(`No id passed to getSupportRequest`);

	return fetch(`${baseUrl}/api/v1/support_requests/${id}`, {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
		.then((response) => response.json())
		.then((data) => {
			if (data.support_requests) {
				if (data.support_requests.length > 0) {
					return data.support_requests[0];
				}
			}
			throw new Error("invalid payload");
		});
};

export const getSupportRequestByCaseId = async (id: string): Promise<SupportRequest[] | undefined> => {
	if (!id) throw new Error(`No Case ID passed to getSupportRequestByCaseId`);

	return fetch(`${baseUrl}/api/v1/support_requests/${id}`, {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	}).then((response) => response.json())
	.then((data) => {
		if (data.support_requests) return data.support_requests;
		throw new Error("invalid payload");
	});
};

export const putSupportRequest = async (id: string, data: SupportRequestUpdate): Promise<SupportRequest> => {
	if (!id) throw new Error(`No id passed to getSupportRequest`);

	if (!data.status) throw new Error(`No status supplied to PUT on ${id}`);

	return fetch(`${baseUrl}/api/v1/support_requests/${id}`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
		body: JSON.stringify(data),
	}).then((response) => response.json());
};

export const getSupportRequestsByUserId = async (id: string) => {
	if (!id) throw new Error(`No id passed to getSupportRequest`);

	return fetch(`${baseUrl}/api/v1/users/${id}/support_requests`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	}).then((response) => response.json());
};
