import { useState } from "react";
import {
	Grid,
	Box,
	Button,
	Chip,
	Toolbar,
	Typography,
	TextField,
} from "@material-ui/core";
import {
	Redeem as RedeemIcon,
} from "@material-ui/icons";
import { ResponseError } from "../../api/utils";
import { RedeemResponse, redeemContestCode } from "../../api/contest";

type UserRedeemProps = {
	userId: string | undefined;
}

export const UserRedeem = ({ userId }: UserRedeemProps) => {
	const [code, setCode] = useState("");
	const [redeeming, setRedeeming] = useState(false);
	const [error, setError] = useState("");
	const [status, setStatus] = useState("");

	const handleRedeemClick = () => {
		if (!userId) {
			return
		}

		setRedeeming(true);
		setError("");
		setStatus("");
		redeemContestCode(userId, code)
			.then(async (response: Response) => {
				const redeemResponse: RedeemResponse = await response.json();
				setStatus(redeemResponse?.status);
				setRedeeming(false);
			});
	}

	const displayResult = () => {
		if (status != "") {
			let color: string;
			let message: string;
			if (status == "complete") {
				color = "green"
				message = "Code redeemed!"
			} else if (status == "expired") {
				color = "orange";
				message = "Code expired";
			} else if (status == "not_found") {
				color = "orange";
				message = "Code not found";
			} else {
				color = "red";
				message = "Unknown error";
			}

			return (
				<Chip
					label={message}
					style={{
						color: color,
						borderColor: color
					}}
					variant="outlined"
				/>
			);
		}

		if (error != "") {
			return (
				<Chip
					label={error}
					style={{
						color: "red",
						borderColor: "red",
					}}
					variant="outlined"
				/>
			);
		}
	}

	return (
		<Toolbar>
		<Grid container direction="row" spacing={2} alignItems="flex-end">
			<Grid item>
				<Typography variant="h6" style={{ marginRight: "5px" }}>
					Redeem
				</Typography>
			</Grid>
			<Grid item>
				<TextField
					label="Redeem Code"
					size="small"
					value={code}
					onChange={(e) => setCode(e.target.value)}
				/>
			</Grid>
			<Grid item>
				<Button endIcon={<RedeemIcon />} onClick={handleRedeemClick}>Redeem</Button>
			</Grid>
			<Grid item>
				{displayResult()}
			</Grid>
		</Grid>
		</Toolbar>
	);
}