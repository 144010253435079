import { MouseEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { OperatorIcon } from "../operator_icon";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";

import type { Rental } from "../../api/rentals-new";
import { deleteActiveRental } from "../../api/active_rentals";

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
});

type RentalListProps = {
	rows: Array<Rental>;
};

export const RentalList = ({ rows }: RentalListProps) => {
	const classes = useStyles();
	const history = useHistory();

	const handleTableRowClick = (event: MouseEvent<HTMLTableRowElement | HTMLAnchorElement>, id: string): void => {
		if (!(event.target instanceof HTMLAnchorElement)) {
			history.push(`/v2/rentals/${id}`);
		}
	};

	const endRide = (rental_id: string) => {
		deleteActiveRental(rental_id).then(() => {

		});
	}

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell></TableCell>
						<TableCell></TableCell>
						<TableCell>Vehicle</TableCell>
						<TableCell>User</TableCell>
						<TableCell align="right">
							Created
							<br /> Modified
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (

						<TableRow
							hover
							onClick={(e) => handleTableRowClick(e, row.id)}
							key={row.id}
						>
							<TableCell component="th" scope="row">
								<OperatorIcon operator={row.operator} />
							</TableCell>
							<TableCell>
								{row.active ? (
									<Button
										component="a"
										onClick={endRide.bind(
											this,
											row["id"],
										)}
										color="secondary"
										aria-label="End Ride">
										End Ride
									</Button>)
									: "Finished"
								}

							</TableCell>
							<TableCell>{row.vehicle.properties.code}</TableCell>
							<TableCell>
								<Link to={`/users/${row.user_id}`}>
									{row.user_id}
								</Link>
							</TableCell>
							<TableCell align="right">
								{format(
									new Date(row.created),
									"yyyy/MM/dd kk:mm:ss",
								)}
								<br />
								<Typography color="textSecondary">
									{format(
										new Date(row.modified),
										"yyyy/MM/dd kk:mm:ss",
									)}
								</Typography>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
