import { baseUrl } from "./config";
import { getToken } from "./index";
import { checkErrors } from "./utils";
import type { GeoPoint } from "../types/geo";
import type { Vehicle } from "../types/vehicle_rental";
import { formatISO } from "date-fns";

export enum EventType {
	PreStartVerifyEventType = "PreStartVerifyEvent",
	PreStartReserveEventType = "PreStartReserveEvent",
	StartEventType = "StartEvent",
	EndEventType = "EndEvent",
	PaymentEventType = "PaymentEvent",
	PaymentRegisterUnpaidEventType = "PaymentRegisterUnpaidEvent",
}

export type RentalEvent = {
	created: string;
	location: GeoPoint;
	type: EventType;
	successful: boolean;
	error: string;
	order_id?: string;
	duration?: number;
	source?: string;
};

export type Rental = {
	id: string;
	partner_rental_id: string;
	user_id: string;
	operator: string;
	events: Array<RentalEvent>;
	vehicle: Vehicle;
	active: boolean;
	created: string;
	modified: string;
};

export const getRental = async (id: string): Promise<Rental> => {
	if (!id) {
		throw new Error("No ID supplied to getRental");
	}
	const requestUrl = new URL(`/api/v2/rentals/${id}`, baseUrl);

	return fetch(requestUrl.toString(), {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
		.then(checkErrors)
		.then((response) => response.json());
};

type GetRentalsProps = {
	order?: string;
	count?: number;
	vehicleCode?: string;
	from?: Date;
	to?: Date;
};

export const getRentals = async (
	options: GetRentalsProps,
): Promise<Array<Rental>> => {
	const DEFAULT_OPTS = {
		order: "modified",
		count: 100,
		vehicle_code: "",
	};

	const requestUrl = new URL("/api/v1/rental_history", baseUrl);
	const queryParams = [];

	const { order, count, vehicleCode, from, to } = {
		...DEFAULT_OPTS,
		...options,
	};

	if (order) queryParams.push(["order", order]);
	if (count) queryParams.push(["count", count.toString()]);
	if (vehicleCode) queryParams.push(["vehicleCode", vehicleCode]);
	if (from) queryParams.push(["from", formatISO(from)]);
	if (to) queryParams.push(["to", formatISO(to)]);

	return fetch(
		`${requestUrl.toString()}?${new URLSearchParams(
			queryParams,
		).toString()}`,
		{
			headers: {
				Authorization: `Bearer ${await getToken()}`,
			},
		},
	)
		.then(checkErrors)
		.then((response) => response.json());
};
