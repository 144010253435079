import React, { useContext, useEffect, useState } from "react";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Avatar from "@material-ui/core/Avatar";
import { Menu, MenuItem } from "@material-ui/core";

import ZoomInIcon from '@material-ui/icons/ZoomIn';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { Typography, Button } from "@material-ui/core";
import { Order, OrderItem } from "../../api/orders";
import { User } from "../../api/user";
import { Product } from "../../api/products";
import { FlexTicket, getFlexTicket, expireFlexTicket } from "../../api/flextickets";
import { FlexTicketDetail } from "./FlexTicketDetail";
import { useFlexTicketContext } from "./FlexTicketContext";

const asset_base = "https://storage.googleapis.com/travis-assets-prod/";

type UserOrderItemProps = {
	order: Order,
	product: Product,
	index: number,
	user: User,
	showMoveFlexTicket: (userId: string, flexTicketId: string, flexTicketDeviceId: string) => void,
}

export const UserOrderItemProduct = ({ order, product, user, index, showMoveFlexTicket }: UserOrderItemProps) => {
	const { flexTicketId } = useFlexTicketContext();

	const [flex, setFlex] = useState<FlexTicket>();
	const [flexBroken, setFlexBroken] = useState<Boolean>(false);
	let operator_icon = (product.operator?.icon) ? asset_base + product.operator.icon + "@2x.png" : "";


	useEffect(() => {
		updateFlexTicket();
	}, [user, product, order, index]);

	useEffect(() => {
		updateFlexTicket();
	}, [flexTicketId])

	const updateFlexTicket = () => {
		if (product.type === "flexticket") {
			let userId = (user.user_id) ? user.user_id : "";
			if (order.order_items === undefined) {
				return;
			}

			var item: OrderItem = order.order_items[index];


			if (item.ref_id && item.ref_id.length > 0) {
				getFlexTicket(userId, item.ref_id)
					.then((flx) => setFlex(flx));
			} else {
				setFlexBroken(true);
			}
		}
	}

	const onProductAction = (action: string) => {
		if ((product.type === "flexticket") && (action === "expire")) {
			if (flex) {
				expireFlexTicket(flex.user_id, flex.id)
					.then((flx) => setFlex(flx));
			}
		} else if ((product.type === "flexticket") && (action === "move_ticket")) {
			if (flex) {
				if (flex.device) {
					showMoveFlexTicket(flex.user_id, flex.id, flex.device.id);
				}
			}
		}
	}

	return <>
		<TableRow>
			<TableCell width="30"><Avatar src={operator_icon} /></TableCell>
			<TableCell width="50">{product.type}</TableCell>
			<TableCell width="260">
				<Typography variant="button" display="block">
					<ProductName product={product} />
				</Typography>
				<Typography variant="caption">

					({(product.product_id.length > 40) ? product.product_id.substr(0, 40) + '...' : product.product_id})

				</Typography>
			</TableCell>
			<TableCell width="50" align="right">
				<ProductQuantity product={product} />
			</TableCell>
			<TableCell width="120" align="right">{product.price.amount / 100.0} {product.price.currency}</TableCell>
			<TableCell width="120" align="right">{product.price.vat_amount / 100.0} {product.price.currency} ({product.price.vat_percent}%)</TableCell>
			<TableCell width="120" align="right">{product.price.total / 100.0} {product.price.currency}</TableCell>
			<TableCell width="120" align="right">
				<ProductActions product={product} order={order} flex={flex} flexBroken={flexBroken} onAction={onProductAction} />
			</TableCell>
		</TableRow>
		<FlexTicketDetail flex={flex} broken={flexBroken} />
	</>;
}

export const ProductName = ({ product }: { product: Product }) => {
	var outputName = product.name == null ? "No product name" : product.name;

	if (product.name === "pt_sl_full") {
		outputName = "Full price ticket";
	}

	if (product.name === "pt_sl_reduced") {
		outputName = "Discounted price ticket";
	}

	if (product.product_description !== undefined) {
		if (product.product_description.title_en !== undefined) {
			if (product.product_description.title_en.length > 0) {
				outputName = product.product_description.title_en;
			}
		}
		if (product.product_description.title_sv !== undefined) {
			if (product.product_description.title_sv.length > 0) {
				outputName = product.product_description.title_sv;
			}
		}
	}

	return <span>{outputName}</span>;
}

export const ProductQuantity = ({ product }: { product: Product }) => {
	if (product.type === "rental") {
		return <>{product.quantity} min</>;
	}
	return <>{product.quantity}</>;
}

export const ProductActions = ({ product, order, flex, flexBroken, onAction }: { product: Product, order: Order, flex?: FlexTicket, flexBroken: Boolean, onAction: ((action: string) => void) }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	const handleExpireFlexTicket = (event: any) => {
		onAction('expire');
		setAnchorEl(null);
	}

	const handleMoveTicket = (event: any) => {
		onAction('move_ticket');
		setAnchorEl(null);
	}

	const handleClose = () => {
		setAnchorEl(null);
	};

	if ((product.name === "se_voi_scooter") || (product.name === "se_tier_scooter")) {
		return (<Button aria-label="show rental" href={`/v2/rentals/${product.product_id}`} size="small" variant="contained" color="secondary" startIcon={<ZoomInIcon />}>
			View
		</Button>);
	}

	if (product.name === "faux_scooter_scooter") {
		return (<Button aria-label="show rental" href={`/v2/rentals/${product.product_id}`} size="small" variant="contained" color="secondary" startIcon={<ZoomInIcon />} disabled>
			View
		</Button>);
	}

	if ((product.type === "flexticket") && (!flexBroken) && (flex)) {
		const expires = new Date(flex.expires * 1000);
		const now = new Date();

		if (expires > now) {
			return <>
				<Button id="expire-button"
					aria-label="Expire" aria-haspopup="true" aria-controls="expiration-menu" aria-expanded={open ? 'true' : undefined}
					size="small" variant="contained" color="secondary"
					onClick={handleClick}
					endIcon={<KeyboardArrowDownIcon />}>
					Actions
				</Button>
				<Menu
					id="expiration-menu"
					anchorEl={anchorEl} open={open} onClose={handleClose}
					MenuListProps={{ 'aria-labelledby': 'expire-button' }}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
					<MenuItem key="expire-ticket" onClick={handleExpireFlexTicket}>
						Expire ticket
					</MenuItem>
					<MenuItem key="move-ticket" onClick={handleMoveTicket}>
						Move ticket
					</MenuItem>
				</Menu>
			</>;
		}
	}

	return <></>;
}