import React, { useState } from "react";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";

import IconButton from '@material-ui/core/IconButton';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AppleIcon from "@material-ui/icons/Apple";

import { Typography } from "@material-ui/core";
import { User } from "../../api/user";
import { Order, OrderStatus } from "../../api/orders";
import { getFlexTicket, expireFlexTicket } from "../../api/flextickets";
import { DateLabel } from "../general/DateLabel";

import { amber, green, red, blue } from "@material-ui/core/colors";

import { makeStyles } from "@material-ui/core/styles";

import { UserOrderItemProduct } from "./UserOrderItemProduct";
import BugReportIcon from '@material-ui/icons/BugReport';

const asset_base = "https://storage.googleapis.com/travis-assets-prod/";


const useStyles = makeStyles((theme) => ({
	filters: {
		marginTop: `${theme.spacing(1)}px`,
		padding: `${theme.spacing(2)}px`,
		backgroundColor: "#f0f0f0",
	},
	rowHover: {
		cursor: "pointer",
	},
	root: {
		minWidth: "100%",
	},
	smallAvatar: {
		width: theme.spacing(4),
		height: theme.spacing(4),
	},
	paymentAvatar: {
		width: theme.spacing(4),
		height: theme.spacing(4),
	},
	chip_0: {
		backgroundColor: blue[200],
		color: "#fff",
	},
	chip_1: {
		backgroundColor: green[200],
		color: "#fff",
	},
	chip_2: {
		backgroundColor: green[500],
		color: "#fff",
	},
	chip_3: {
		backgroundColor: green[500],
		color: "#fff",
	},
	chip_11: {
		backgroundColor: amber[500],
		color: "#000",
	},
	chip_98: {
		backgroundColor: amber[500],
		color: "#000",
	},
	chip_99: {
		backgroundColor: red[500],
		color: "#fff",
	},
	createdDate: {
		color: "#777",
	},
	createdDateToday: {
		color: "#b22",
	},
	updatedDate: {
		color: "#333",
	},
	updatedDateToday: {
		color: "#f00",
	},
}));

type UserOrderItemProps = {
	order: Order
	user: User
	showLogInspector: Function
	showMoveFlexTicket: (userId: string, flexTicketId: string, deviceId: string) => void
};

export const UserOrderItem = ({ order, user, showLogInspector, showMoveFlexTicket }: UserOrderItemProps) => {
	const [open, setOpen] = useState<boolean>(false);
	const classes = useStyles();
	const orderStatus = order.status === -1 ? 0 : order.status;

	if (order === undefined) {
		return <></>;
	}

	const orderStatusClassNames = {
		0: classes.chip_0,
		1: classes.chip_1,
		2: classes.chip_2,
		3: classes.chip_3,
		11: classes.chip_11,
		98: classes.chip_98,
		99: classes.chip_99,
	};

	const isToday = (timeStamp: number) => {
		if (timeStamp === 0) {
			return false;
		}

		const today = new Date();
		const someDate = new Date(timeStamp * 1000);

		const isItToday = someDate.getDate() === today.getDate() &&
			someDate.getMonth() === today.getMonth() &&
			someDate.getFullYear() === today.getFullYear();

		return isItToday;
	}

	// orderType returns an array of URLs to operator icons, 
	// based on the orders products
	const orderType = (order: Order): [string, string][] => {
		var opCodes: [string, string][] = [];
		order.products.forEach(product => {
			if (product.operator !== undefined) {
				let op = product.operator;
				let icon = op.icon;
				let type = product.type;
				let operator_icon = asset_base + icon + "@2x.png";
				if (opCodes.indexOf([type, operator_icon]) === -1) {
					opCodes.push([type, operator_icon]);
				}
			}
		});
		return opCodes;
	}

	const inspectLog = (event: React.MouseEvent<HTMLElement>, order: Order) => {
		event.stopPropagation();
		showLogInspector(order);
	}

	return <>
		<TableRow hover className={classes.rowHover} onClick={() => setOpen(!open)}>
			<TableCell>
				<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
					{open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
				</IconButton>
			</TableCell>
			<TableCell>
				<AvatarGroup max={2}>
					{orderType(order).map(([type, operatorIcon], idx) =>
						<Avatar key={user.user_id + "-" + order.id + "-avatar-" + idx} alt={type} className={classes.smallAvatar} src={operatorIcon} />
					)}
				</AvatarGroup>
			</TableCell>
			<TableCell>
				<strong>{order.id}</strong><br />
				{order.transaction_id ? order.transaction_id : "N/A"}
			</TableCell>
			<TableCell align="right">{order.price.vat_amount / 100.0} {order.price.currency} ({order.price.vat_percent}%)</TableCell>
			<TableCell align="right">{order.price.total / 100.0} {order.price.currency}</TableCell>
			<TableCell align="center"><PaymentMethod className={classes.paymentAvatar} method={order.payment_method} /></TableCell>
			<TableCell align="center">
				<IconButton
					style={{ backgroundColor: "#fdd" }}
					size="small"
					onClick={(e) => inspectLog(e, order)}
					color="secondary">
					<BugReportIcon />
				</IconButton>
			</TableCell>
			<TableCell align="right">
				<Typography variant="caption">
					<span className={!isToday(order.created) ? classes.createdDate : classes.createdDateToday}><DateLabel inputDate={order.created} /></span><br />
					<span className={!isToday(order.updated) ? classes.updatedDate : classes.updatedDateToday}><DateLabel inputDate={order.updated} /></span>
				</Typography>
			</TableCell>
			<TableCell width="40" align="center">
				<Chip label={OrderStatus[order.status]} className={orderStatusClassNames[orderStatus]} />
			</TableCell>
		</TableRow>
		<TableRow>
			<TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#f5f5f5" }} colSpan={9}>
				<Collapse in={open} timeout="auto" unmountOnExit>
					<Box margin={3}>
						<Typography variant="h6" gutterBottom component="div">
							Products
						</Typography>
						<Table size="small" aria-label="purchases">
							<TableHead>
								<TableRow>
									<TableCell>Operator</TableCell>
									<TableCell>Type</TableCell>
									<TableCell>Name</TableCell>
									<TableCell align="right">Quantity</TableCell>
									<TableCell width="50" align="right">Amount</TableCell>
									<TableCell align="right">VAT</TableCell>
									<TableCell align="right">Sum</TableCell>
									<TableCell align="right">Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{order.products.map((product, idx) => <UserOrderItemProduct
									key={user.user_id + "-" + order.id + "-" + product.product_id}
									order={order}
									index={idx}
									product={product}
									user={user}
									showMoveFlexTicket={showMoveFlexTicket}
								/>)}
							</TableBody>
						</Table>
					</Box>
				</Collapse>
			</TableCell>
		</TableRow>
	</>;
}

export const PaymentMethod = ({ className, method }: { className: string, method: string }) => {
	let m: string = method.toLowerCase();
	// https://storage.googleapis.com/travis-assets-prod/logos_swish%40xxxhdpi.png
	if (m === "swish") {
		return <Avatar
			className={className}
			src="https://storage.googleapis.com/travis-assets-prod/logos_swish%40xxxhdpi.png"
			alt="Swish"
		/>;
	}

	if (m === "apple_pay") {
		return <Avatar className={className} alt="Apple Pay">
			<AppleIcon />
		</Avatar>;
	}

	if ((m === "credit_card") ||
		(m === "credit-card") ||
		(m === "card")) {
		return <Avatar className={className} alt="Credit card">
			<CreditCardIcon />
		</Avatar>;
	}

	return <>{m}</>;
}
