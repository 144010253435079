import React, { useEffect, useContext, useState } from "react";

import { useHistory } from "react-router-dom";
import { signOut } from "../api/user";
import { AuthContext } from "../context/authContext";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from '@material-ui/core/Chip';
import InputBase from "@material-ui/core/InputBase";

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PublicIcon from '@material-ui/icons/Public';
import SearchIcon from '@material-ui/icons/Search';
import GitHubIcon from '@material-ui/icons/GitHub';

import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { env } from "../api/config";

import { AppVersion, getAppVersion } from "../api/app";
import { getSupportRequestByCaseId } from "../api/support_requests";

const useStyles = makeStyles((theme) => ({
	paper: {
		width: "100%",
		backgroundColor: theme.palette.background.default,
		offset: theme.mixins.toolbar.offset,
		marginTop: theme.spacing(3),
	},
	toolbar: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		backgroundColor: "#001e46",
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	logOut: {
		color: "#fff",
		backgroundColor: "rgba(255,255,255,.1);",
	},
	logo: {
		width: "15%",
		maxWidth: "100px",
		flexShrink: 1,
		marginRight: theme.spacing(2),
	},
	tagChip: {
		marginRight: theme.spacing(1),
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: `rgba(255,255,255, .15)`,
		'&:hover': {
		  backgroundColor: `rgba(255,255,255, 0.25)`,
		},
		marginRight: theme.spacing(2),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
		  marginLeft: theme.spacing(1),
		  width: 'auto',
		},
	  },
	  searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	  },
	  inputRoot: {
		color: 'inherit',
	  },
	  inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
		  width: '30ch',
		  '&:focus': {
			width: '40ch',
		  },
		},
	  },
}));


export const Header = () => {
	const user = useContext(AuthContext);
	const history = useHistory();
	const classes = useStyles();
	const [searchString, setSearchString] = useState("");
	const [version, setVersion] = useState<AppVersion>();
	const [alertOpen, setAlertOpen] = useState<boolean|undefined>(false);
	const [alertMessage, setAlertMessage] = useState<String>("");

    useEffect(() => {
        getAppVersion()
			.then(setVersion)
			.catch((err) => {
				console.log("Failed retrieving version data", err);
			});
    }, []);

	const handleKeyPress = (ev: React.KeyboardEvent<HTMLDivElement>) => {
		let search: string = searchString.trim();

		switch (ev.key) {
			case "Enter":
				setSearchString("");
				if (search.length === 0) { return; }
				if (search.length === 4) {
					getSupportRequestByCaseId(search)
					.then((reqs)=>{
						if ((reqs !== undefined) && (reqs.length > 0)) {

							history.push(`/support-requests/`+reqs[0].id);
						} else {
							setAlertMessage('Could not find support case \'' + search + '\'');
							setAlertOpen(true);
						}
					});
				} else {
					history.push(`/users/${search}`);
				}
				
				break;
			default:
				break;
		}
	};

	const handleCloseAlert = (event?: React.SyntheticEvent<any, Event>, reason?: String) => {
		if (reason === 'clickaway') {
			setAlertOpen(false);
		  return;
		}
	
		setAlertOpen(false);
	  };
	
	const logOut = () => {
		signOut().then(()=>{
			user.userData = null;
			user.loggedIn = false;
			history.push("/");
		});
	}
	
	return (
		<AppBar position="fixed" className={classes.appBar}>
			<Toolbar className={classes.toolbar}>
				<img src={process.env.PUBLIC_URL + '/travis_navbar_dark_bg.svg'} className={classes.logo} alt="Travis Support Tool" />
				<Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
					<Box className={classes.search} flexGrow={1}>
						<Box className={classes.searchIcon}>
							<SearchIcon />
						</Box>
						<InputBase
							id="search"
							placeholder="Search by user, email or order..."
							classes={{
								root: classes.inputRoot,
								input: classes.inputInput,
							}}
							value={searchString}
							onChange={(ev) => setSearchString(ev.target.value)}
							onKeyPress={handleKeyPress}
							inputProps={{ 'aria-label': 'search' }}
							/>
					</Box>
					{env && 
					<Box>
						
							<Chip 
								className={classes.tagChip} 
								label={"Environment: " + env?.toUpperCase() } 
								color="secondary" 
								icon={<PublicIcon />} />
						
					</Box>}
					{version?.git && 
					<Box>
						<Tooltip title={
							<>
								Tag: {version?.git?.version}<br/>
								Branch: {version?.git?.branch}<br/>
								Build: {version?.build_time}
							</>
							}>
							<Chip 
								className={classes.tagChip} 
								label={version?.git?.version} 
								color="primary" 
								icon={<GitHubIcon />} />
						</Tooltip>
					</Box>}
					<Box>
						{user.loggedIn ? (
							<IconButton aria-label="log out" onClick={logOut} className={classes.logOut}>
								<ExitToAppIcon />
							</IconButton>
						) : (
							<Redirect to="/login" />
						)}
					</Box>
				</Box>
			</Toolbar>
			<Snackbar 
					open={alertOpen} 
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					autoHideDuration={6000} 
					onClose={handleCloseAlert}>
				<Alert onClose={handleCloseAlert} variant="filled" severity="error">
					{alertMessage}
				</Alert>
			</Snackbar>
		</AppBar>		
	);
};
