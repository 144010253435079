import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import { Loader } from "../loader";
import NotificationTestModal from "./NotificationTestModal";

import NotificationsListItem from "./NotificationsListItem";
import { getNotifications, cancelNotification, Notification } from "../../api/notifications";

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(5),
	}
}));

export const NotificationsList = () => {
	const classes = useStyles();
	const navigate = useHistory();
	const [notifications, setNotifications] = useState<Notification[]>([]);
	const [loaded, setLoaded] = useState<boolean>(false);
	const [failed, setFailed] = useState<boolean>(false);
	const [isTestOpen, setTestOpen] = useState<boolean>(false);


	const onClickNew = () => {
		navigate.push("/notifications/new");
	}

	const onClickTest = () => {
		setTestOpen(true);
	}

	const onCloseTestModal = () => {
		setTestOpen(false);
	}

	useEffect(()=>{

		getNotifications()
		.then((data)=>{
			setNotifications(data.notifications);
			setLoaded(true);
		})
		.catch((err)=>{
			setFailed(true);
			console.log("Failed loading notifications", err);
		});

	},[])

	const handleOnCancel = (notification: Notification) => {
		console.log("Cancel ", notification);
		if (window.confirm("Are you sure?")) {
			// State 3 is the cancelled state
			notification.push_notification_state = 3;

			cancelNotification(notification.notification_id, notification)
			.then((data)=>{
				console.log("cancelled notification", notification.notification_id, data);
				navigate.push("/notifications");
			})
			.catch((err)=>{
				console.log("failed cancelling notification", err);
				alert('Failed cancelling notification');
			});
		}
	}

	if (failed) {
		return <>
		<Typography variant="h5">
			Failed loading notifications
		</Typography>
		</>;
	}

	if (!loaded) {
		return <Loader />;
	}

	return <>
			<Toolbar>
				<Grid container justifyContent="space-between">
					<Grid item>
						<Typography variant="h6">Notifications</Typography>
					</Grid>
					<Grid item>
						<Grid container spacing={2}>
							<Grid item>
								<Button variant="contained" color="secondary" onClick={onClickTest}>
									Test push notification
								</Button>
							</Grid>
							<Grid item>
								<Button variant="contained" color="primary" onClick={onClickNew}>
									New notification
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Toolbar>	
			<Paper className={classes.paper}>
				<Table aria-label="Notifications" size="small">
					<TableHead>
						<TableRow>
							<TableCell></TableCell>
							<TableCell>Title</TableCell>
							<TableCell>Device</TableCell>
							<TableCell>Delivery</TableCell>
							<TableCell>State</TableCell>
							<TableCell>Start/End</TableCell>
							<TableCell>Created</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{notifications.map((notification)=>
							<NotificationsListItem notification={notification} onCancel={handleOnCancel}/>
						)}
					</TableBody>
				</Table>
			</Paper>
			{isTestOpen &&
			<NotificationTestModal isOpen={isTestOpen} onClose={onCloseTestModal} />}
		</>;
}

export default NotificationsList;