import { baseUrl } from "./config";
import { getToken } from "./index";
import { checkErrors } from "./utils";

export type FlexTicket = {
    id: string;
    user_id: string;
    device_id: string;
    created: number;
    activated: number;
    updated: number;
    deactivated: boolean;
    expires: number;
    urgent_after: number;
    transfer_status: TransferStatus;
    travel_periods_remaining: number;
    travel_periods_included: number;
    travel_periods: Array<TravelPeriod>;
    product: Product;
}

export type TravelPeriod = {
    period_started: number;
    period_ends: number;
    period_urgent_after: number;
    activated: number;
    tickets_issued: number;
    operator_ticket_ids: Array<string>;
}

export type Product = {
    product_id: string;
    name: string;
    operator_name: string;
}

export type TransferStatus = {
    valid: boolean;
    transfers_left: number;
    next_available_transfer_at: Date;
}

export const getFlexTicketsForOrder = async (userId: string, orderId: string): Promise<FlexTicket[]> => {
    if (!userId) throw new Error("No userID for getFlexTicketsForOrder");
    if (!orderId) throw new Error("No orderID for getFlexTicketsForOrder");

    return fetch(`${baseUrl}/api/v2/users/${userId}/orders/${orderId}/flextickets`, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    })
        .then((response: Response) => {
            if (response.status == 404) {
                return Promise.resolve([]);
            }

            checkErrors(response);
            return response.json();
        });
};

export const expireFlexTicket = async (userId: string, flexTicketId: string): Promise<FlexTicket | undefined> => {
    if (!userId) throw new Error(`No userId passed to expireFlexTicket`);
    if (!flexTicketId) throw new Error(`No flexTicketId passed to expireFlexTicket`);

    return fetch(`${baseUrl}/api/v2/flextickets/${userId}/${flexTicketId}/expire`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    })
        .then(checkErrors)
        .then((response): Promise<FlexTicket> => response.json());
}
