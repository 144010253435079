import { useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";

import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";

import {
	MuiPickersUtilsProvider,
	KeyboardDateTimePicker,
} from "@material-ui/pickers";

export const TimePicker = ({
	label,
	id,
	emptyLabel,
	defaultDate,
	onChange,
	disabled,
	minDate,
}: {
	id?: string;
	label?: string;
	emptyLabel?: string;
	defaultDate?: Date | null;
	onChange?: (date: Date | null) => void;
	disabled?: boolean;
	minDate?: Date | null;
}) => {
	const [dateTime, setDateTime] = useState<Date | null>();

	const handleClear = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setDateTime(null);
		if (onChange) onChange(null);
	}

	useEffect(() => {
		if (!defaultDate){
			setDateTime(null);
		} else {
			setDateTime(defaultDate);
		}
	}, [defaultDate]);

	// console.log("render timepicker:", defaultDate, dateTime);

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDateTimePicker
				disableToolbar
				variant="inline"
				margin="normal"
				id="date-picker-inline-{id}"
				label={label}
				value={dateTime}
				ampm={false}
				autoOk={true}
				format="yyyy-MM-dd HH:mm:ss"
				minDate={minDate}
				emptyLabel={emptyLabel}
				disabled={disabled}
				onChange={(date) => {
					//console.log(date);
					setDateTime(date);
					if (onChange) onChange(date);
				}}
				KeyboardButtonProps={{
					"aria-label": "change date",
				}}
				InputProps={{
					startAdornment: <InputAdornment position="start">
						<>
						{dateTime && 
							<IconButton onClick={(e)=>handleClear(e)} size="small">
								<ClearIcon />
							</IconButton>}
							</>
						</InputAdornment>
				  }}
			/>
			

		</MuiPickersUtilsProvider>
	);
};

export default TimePicker;