import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/authContext";
import { CustomClaimsKey } from "./permissions/PermissionsV2";

type ProtectedComponentProps = {
    claim: string;
    children: JSX.Element;
};

export const ProtectedComponent = (props: ProtectedComponentProps) => {

	const user = useContext(AuthContext);
	const [hasClaim, setHasClaim] = useState<boolean>(false);

    useEffect(() => {
		user.userData?.getIdTokenResult()
			.then((result) => {
				const claims = result.claims[CustomClaimsKey]

				if (!claims) {
                    setHasClaim(false);
					return;
				}

				const userHasClaim = claims.includes(props.claim);
				setHasClaim(userHasClaim);
			});
    }, [props.claim, user]);

    if (hasClaim) {
        return props.children;
    }

    return (<></>);
};