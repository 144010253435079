import React from "react";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
} from "@material-ui/core";
import { DateLabel } from "../general/DateLabel";
import { FlexTicket, TravelPeriod, TransferStatus } from "../../api/flextickets_v2";

const bgRowColorHighlight = "#fff"; // Define highlight bg row color 
const textColorDefault = "#33aa33"; // Define default text color 
const textColorDanger = "#aa3333"; // Define text color for danger state

function extractOperatorIDs(flex: FlexTicket): string[] {
    let operatorIDs: string[] = [];
    flex.travel_periods?.forEach((period) => {
        operatorIDs = operatorIDs.concat(period.operator_ticket_ids);
    });
    return operatorIDs;
}

const FlexTicketId = ({ id }: { id: String }) => (
    <TableRow style={{ backgroundColor: bgRowColorHighlight }}>
        <TableCell className="fixedFlexTicketTableCell" >
            <strong>ID</strong>
        </TableCell>
        <TableCell colSpan={4}>
            <strong>{id}</strong>
        </TableCell>
    </TableRow>
);

const FlexTicketOperatorInfo = ({ operatorIDs }: { operatorIDs: String[] }) => (
    <TableRow>
        <TableCell >
            <strong>Operator Ticket ID(s)</strong>
        </TableCell>
        <TableCell colSpan={4}>
            <Typography variant="inherit">
                <strong>{operatorIDs.join(", ")}</strong>
            </Typography>
        </TableCell>
    </TableRow>
);

const FlexTicketActivatedInfo = ({ activatedAt }: { activatedAt: number }) => (
    <TableRow>
        <TableCell><strong>Activated</strong></TableCell>
        <TableCell colSpan={4}>
            {activatedAt
                ? <DateLabel inputDate={activatedAt} />
                : (
                    <Typography variant="inherit" style={{ color: textColorDanger }}>
                        <strong>Not activated</strong>
                    </Typography>
                )
            }
        </TableCell>
    </TableRow>
);

const FlexTicketExpiryInfo = ({ expiresAt }: { expiresAt: number }) => {
    const now = new Date();
    const then = new Date(expiresAt * 1000);
    const hasExpired = now > then;
    const title = hasExpired ? "Expired" : "Expires"
    const descriptionColor = hasExpired ? textColorDanger : textColorDefault
    return (
        <TableRow>
            <TableCell><strong>{title}</strong></TableCell>
            <TableCell colSpan={4}>
                <Typography variant="inherit" style={{ color: descriptionColor }}>
                    <DateLabel inputDate={expiresAt} />
                </Typography>
            </TableCell>
        </TableRow>
    )
};

const FlexTicketDeactivatedInfo = (
    <TableRow>
        <TableCell>
            <strong>Deactivated</strong>
        </TableCell>
        <TableCell  >
            <Typography variant="inherit" style={{ color: textColorDanger }} >
                <strong>Ticket has been deactivated by the operator becauase of fraudulent usage</strong>
            </Typography>
        </TableCell>
    </TableRow>
);

const FlexTicketDeviceTransferInfo = (
    {
        deviceId,
        nextAvailableTransfer,
        transfersLeft,
    }: {
        deviceId: string,
        nextAvailableTransfer: Date,
        transfersLeft: number,
    }) => {
    return (
        <>
            <TableRow style={{ backgroundColor: bgRowColorHighlight }}>
                <TableCell>
                    <strong>Device</strong>
                </TableCell>
                <TableCell>
                    <strong>ID</strong>
                </TableCell>
                <TableCell>
                    {<strong>Next available transfer</strong>}
                </TableCell>
                <TableCell />
                <TableCell>
                    <strong>Transfers left</strong>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell />
                <TableCell>
                    {deviceId}
                </TableCell>
                <TableCell>
                    <Typography variant="inherit">
                        {transfersLeft > 0 ? <DateLabel inputDate={nextAvailableTransfer} /> : "-"}
                    </Typography>
                </TableCell>
                <TableCell />
                <TableCell>
                    {transfersLeft}
                </TableCell>
            </TableRow>
        </>
    )
};

const FlexTicketTravelPeriodsInfo = ({ travelPeriods, updatedAt }: { travelPeriods: TravelPeriod[], updatedAt: number }) => (
    <>
        <TableRow style={{ backgroundColor: bgRowColorHighlight }}>
            <TableCell>
                <strong>Travel Periods</strong>
            </TableCell>
            <TableCell>
                <strong>Started at</strong>
            </TableCell>
            <TableCell>
                <strong>Ends</strong>
            </TableCell>
            <TableCell>
                <strong>Updated</strong>
            </TableCell>
            <TableCell>
                <strong>Tickets Issued</strong>
            </TableCell>
        </TableRow>
        {travelPeriods.map((travelPeriod) => <TableRow>
            <TableCell />
            <TableCell><DateLabel inputDate={travelPeriod.period_started} /></TableCell>
            <TableCell><DateLabel inputDate={travelPeriod.period_ends} /></TableCell>
            <TableCell><DateLabel inputDate={updatedAt} /></TableCell>
            <TableCell>{travelPeriod.tickets_issued}</TableCell>
        </TableRow>)}
    </>
);


export const FlexTicketDetail = ({ flex }: { flex?: FlexTicket }) => {
    if (flex === undefined) {
        return <></>;
    }

    const isFlexTicketActivated = flex.activated > 0
    const operatorIDs = extractOperatorIDs(flex);

    return (
        <Table size="small">
            <TableBody className="tableBodyWithPadding">
                <FlexTicketId id={flex.id} />
                {isFlexTicketActivated && <FlexTicketOperatorInfo operatorIDs={operatorIDs} />}
                <FlexTicketActivatedInfo activatedAt={flex.activated} />
                <FlexTicketExpiryInfo expiresAt={flex.expires} />
                {flex.deactivated && FlexTicketDeactivatedInfo}
                {isFlexTicketActivated && (<FlexTicketDeviceTransferInfo
                    deviceId={flex.device_id}
                    nextAvailableTransfer={flex.transfer_status.next_available_transfer_at}
                    transfersLeft={flex.transfer_status.transfers_left} />
                )}
                {isFlexTicketActivated &&
                    <FlexTicketTravelPeriodsInfo travelPeriods={flex.travel_periods} updatedAt={flex.updated} />
                }
            </TableBody>
        </Table>);
}