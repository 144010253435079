export type ResponseError = {
	err: Error,
	response: Response,
}

export const responseError = (response: Response, error: Error): ResponseError => {
	return {
		err: error,
		response: response,
	};
}

export const checkErrors = (response: Response) => {
	if (response.status >= 400) {
		throw responseError(response, new Error(`Error handling request: ${response.status}, ${response.statusText}`));
	}

	return response;
};

export const titleCase = (str: string): string => {
	return str.replace(
		/\w\S*/g,
		(txt) => {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		}
	);
}

// Convert Headers object into a string representation
export const headersToString = (headers: Headers): string  => {
    let result = '';
    headers.forEach((value, key) => {
        result += `${key}: ${value}; `;
    });
    return result;
}