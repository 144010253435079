import { useCallback, useEffect, useState } from "react";
import { Rental, getRental } from "../../api/rentals_v2";
import { Link, useParams } from "react-router-dom";
import { Grid, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { OperatorIcon } from "../operator_icon";
import { format } from "date-fns";
import Map from "../map";

// Poll the rental every 5 seconds
const rentalPollInterval = 5000;

type RentalParams = {
    id: string;
};

const getPoints = (rental: Rental): Array<{ type: string, desc: string, lat: number, lng: number }> => {
    const points = [{
        type: rental.icon,
        desc: "Start",
        lat: rental.start_position.lat,
        lng: rental.start_position.lon,
    }];

    if (rental.end_position) {
        points.push({
            type: rental.icon,
            desc: "End",
            lat: rental.end_position.lat,
            lng: rental.end_position.lon,
        });
    }

    return points;
}

export const RentalV2 = () => {

    const { id: rentalId } = useParams<RentalParams>();
    const [rental, setRental] = useState<Rental | null>(null);

    const fetchRentalCallback = useCallback(() => {
        if (!rentalId) {
            return;
        }

        getRental(rentalId)
            .then(setRental)
            .catch((err) => {
                console.log("An error occured while fetching a rental:", err);
            });
    }, [rentalId]);

    useEffect(() => {
        // Immediately fetch the rental
        fetchRentalCallback();


        // Set up a timer to poll the state of the rental.
        const intervalId = setInterval(
            (prev) => {
                if (prev) {
                    // Clearing interval
                    clearInterval(prev);
                }
                fetchRentalCallback();
            }, rentalPollInterval);
        return () => clearInterval(intervalId);
    }, [fetchRentalCallback]);


    return (
        <>

            {rental &&
                <Grid container spacing={4} direction="column">
                    <Grid item container direction="row" wrap="nowrap" justifyContent="space-around">
                        <Grid item>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        {rental ? <OperatorIcon operator={rental.operator_id} /> : null}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Partner Rental ID"
                                        secondary={rental.partner_rental_id}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Rental ID"
                                        secondary={rental.id}
                                    />
                                </ListItem>
                                <ListItem
                                    component={Link}
                                    to={`/users/${rental.user_id}`}
                                >
                                    <ListItemText
                                        primary="User ID"
                                        secondary={rental.user_id}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Order ID"
                                        secondary={rental.order_id}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item>
                            <List>
                                <ListItem>
                                    <ListItemText
                                        primary="Vehicle ID"
                                        secondary={rental.vehicle_id}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Vehicle Code"
                                        secondary={rental.vehicle_code}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Started"
                                        secondary={format(new Date(rental.started * 1000), "yyyy/MM/dd kk:mm:ss")}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Ended"
                                        secondary={rental.ended !== 0 ? format(new Date(rental.ended * 1000), "yyyy/MM/dd kk:mm:ss") : "Not Ended"}
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    < Grid style={{ position: "relative" }} item>
                        <Map
                            layers={rental.mapbox_layers}
                            points={getPoints(rental)}
                        />
                    </Grid>
                </Grid >
            }
        </>
    );
};