import { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TimePicker from "../TimePicker";
import { Typography } from '@material-ui/core';

import { endOfDay, startOfDay } from "date-fns";

import { User } from '../../api/user';
import { generateReceipts } from "../../api/orders_v2";
import { ResponseError } from '../../api/utils';
import { toISOStringWithTimezone } from '../../api/time_utils';

type UserGetReceiptsModalProps = {
    user: User;
    onClose: () => void;
    isOpen: boolean;
}

export const UserGetReceiptsModal = ({ user: user, onClose: onClose, isOpen: isOpen }: UserGetReceiptsModalProps) => {
    // Display progress spinner
    const [inProgress, setInProgress] = useState<boolean>(false);

    // Display error message if any
    const [alertMessage, setAlertMessage] = useState<string>("");

    // The start date 
    const [startDate, setStartDate] = useState<Date>(startOfDay(new Date()));

    // The end date 
    const [endDate, setEndDate] = useState<Date>(endOfDay(new Date()));

    // Whether the date range is valid
    const [isValidDateRange, setIsValidDateRange] = useState<boolean>(false);

    useEffect(() => {
        // Check if endDate is not before startDate
        const isDateRangeValid = endDate > startDate;
        setIsValidDateRange(isDateRangeValid);
    }, [startDate, endDate]);

    useEffect(() => {
        if (!isValidDateRange) {
            setAlertMessage("End date must be before start date.");
        } else {
            setAlertMessage("");
        }
    }, [isValidDateRange]);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        /* Reset all fields when the modal is opened */
        setAlertMessage("");
        onHideProgress();
    }, [isOpen]);

    const onModalClose = () => {
        onClose();
    };

    const onHideProgress = () => {
        setInProgress(false);
    };

    const handleFromTimeChange = (newDate: Date | null) => {
        try {
            if (newDate == null) return;
            setStartDate(newDate);
            if (newDate > endDate) {
                const newEndDate = endOfDay(newDate);
                setEndDate(newEndDate);
            }
        } catch (err) { }
    };

    const handleToTimeChange = (date: Date | null) => {
        try {
            if (date == null) return;
            if (endDate.getSeconds() === 59) {
                setEndDate(endOfDay(date));
            } else {
                setEndDate(date);
            }
        } catch (err) { }
    };

    const onModalGetReceiptsClicked = () => {
        if (!user.user_id ) {
            setAlertMessage("User ID is null");
            return;
        }
        setAlertMessage("");
        setInProgress(true);
        
        generateReceipts(user.user_id , toISOStringWithTimezone(startDate), toISOStringWithTimezone(endDate))
            .then(() => {
                onModalClose();
            })
            .catch((err: ResponseError) => {
                err.response.json().then((body) => setAlertMessage("Failed to generate order receipts because: " + body['error']));
            })
            .finally(onHideProgress);
    };

    return (
        <>
            <Dialog open={true} aria-labelledby="form-dialog-title" >
                <DialogTitle id="form-dialog-title">Get receipts</DialogTitle>
                <DialogContent>
                    {inProgress &&
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CircularProgress />
                        </div>
                    }
                    {alertMessage.length > 0 &&
                        <Typography color="secondary">{alertMessage}</Typography>
                    }
                    {!inProgress &&
                        <Grid container item direction="row" justifyContent="space-between" wrap="nowrap">
                            { /* The time pickers */}
                            <Grid item>
                                <Grid item>
                                    <TimePicker
                                        defaultDate={startDate}
                                        onChange={handleFromTimeChange}
                                        label="From"
                                    />
                                </Grid>
                                <Grid item>
                                    <TimePicker
                                        defaultDate={endDate}
                                        onChange={handleToTimeChange}
                                        label="To"
                                        minDate={startDate} // Set the minimum selectable date to the start date
                                    />
                                </Grid>
                            </Grid >
                        </Grid >
                    }
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Button disabled={inProgress} color="secondary" onClick={onModalClose}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={inProgress || !isValidDateRange}
                                variant="contained"
                                onClick={onModalGetReceiptsClicked}
                                color="primary">
                                Get receipts
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
}