import React, { useContext, useEffect, useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import { UserOrderItem } from "./UserOrderItem";

import { TableFooter, Typography } from "@material-ui/core";
import { User } from "../../api/user";
import { Order, OrderStatus } from "../../api/orders";

import { ArrowLeft, ArrowRight } from "@material-ui/icons";

import LogInspector from "../general/LogInspector";
import { FlexTicketMove } from "../general/FlexTicketMove";
import { useFlexTicketContext } from "./FlexTicketContext";

type FilterType = {
	status: OrderStatus[];
	freetext: string;
};

type MoveFlexTicketProps = {
	isOpen: boolean;
	userId: string | undefined;
	flexTicketId: string | undefined;
	deviceId: string | undefined;
}

export const UserOrders = ({ orders, user }: { orders?: Order[], user: User }) => {
	const { flexTicketId, setFlexTicketId } = useFlexTicketContext();
	const [isHidden, setIsHidden] = useState<boolean>(false);
	const [moveFlexTicketProps, setMoveFlexTicketProps] = useState<MoveFlexTicketProps>({ isOpen: false, userId: undefined, flexTicketId: undefined, deviceId: undefined });
	const [isLogInspectorOpen, setIsLogInspectorOpen] = useState<boolean>(false);
	const [logInspectorFromDate, setLogInspectorFromDate] = useState<Date>(new Date());
	const [logInspectorToDate, setLogInspectorToDate] = useState<Date>(new Date());
	const [logInspectorTerms, setLogInspectorTerms] = useState<string[]>([]);
	const [filters, setFilters] = useState<FilterType>({
		status: [99, 3, 98, -1],
		freetext: "",
	});

	const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);
	const [paginatedOrders, setPaginatedOrders] = useState<Order[]>([]);
	const [orderPage, setOrderPage] = useState<number>(0);
	const [orderPageHint, setOrderPageHint] = useState<string>("");
	const ordersPerPage = 5;


	useEffect(() => {
		if (!orders) {
			return;
		}

		let searchString = filters.freetext.toLowerCase();
		let filtered = orders.filter((sr) => {
			var includeInResult = false;

			if (filters.status.includes(sr.status)) {
				includeInResult = true;
			} else if (filters.status.includes(-1)) {
				if (sr.status !== 3 && sr.status !== 98 && sr.status !== 99) {
					includeInResult = true;
				}
			}

			if (!sr.transaction_id) {
				sr.transaction_id = "";
			}

			if (searchString !== "" && !sr.transaction_id.toLowerCase().includes(searchString)) {
				includeInResult = false;
			}

			if (searchString !== "" && sr.id.toLowerCase().includes(searchString)) {
				includeInResult = true;
			}

			return includeInResult;
		});

		const from = orderPage * ordersPerPage;
		const to = Math.min((orderPage + 1) * ordersPerPage, filtered.length);
		const sliced = filtered.slice(from, to);

		setFilteredOrders(filtered);
		setPaginatedOrders(sliced);
		//setOrderPageHint(`Orders ${sliced.length === 0 ? 0 : from + 1} through ${to} of ${filtered.length}`);
		setOrderPageHint(`${sliced.length === 0 ? 0 : from + 1}-${to}/${filtered.length}`);
	}, [orderPage, filters, orders]);

	if ((orders === undefined) || ((orders !== undefined) && (orders.length === 0))) {
		return <></>;
	}

	const toggleStatusFilter = (status: OrderStatus) => {
		const activeStatusFilters = [...filters.status];

		var index = activeStatusFilters.indexOf(status);

		index === -1
			? activeStatusFilters.push(status)
			: activeStatusFilters.splice(index, 1);

		setFilters({ ...filters, status: activeStatusFilters });
	};

	const hideLogInspector = () => {
		setIsLogInspectorOpen(false);
	}

	const showLogInspector = (order: Order) => {
		console.log("Log inspector", order);
		const orderTag: string = "@order.id:"
		const orderTransactionTag: string = "@order.transaction_id:"
		const orderItemRefTag: string = "@order.item_ref_id:"

		let fromDatePre: Date = new Date(order.created * 1000);
		const fromDate: Date = new Date(Number(fromDatePre))
		fromDate.setDate(fromDatePre.getDate() - 1)

		let toDatePre: Date = new Date(order.updated * 1000);
		const toDate: Date = new Date(Number(toDatePre))
		toDate.setDate(toDatePre.getDate() + 1)

		let terms: string[] = [`@order.id:"${order.id}"`];
		if (order.transaction_id) {
			terms.push(`@order.transaction_id:"${order.transaction_id}"`);
		}

		if (order.order_items) {
			for (let orderItem of order.order_items) {
				if (orderItem.ref_id) {
					terms.push(`@order.item_ref_id:"${orderItem.ref_id}"`);
				}
			}
		}

		setLogInspectorFromDate(fromDate)
		setLogInspectorToDate(toDate)
		setLogInspectorTerms(terms);
		setIsLogInspectorOpen(true);
	}

	const showMoveFlexTicket = (userId: string, flexTicketId: string, deviceId: string) => {
		setMoveFlexTicketProps({ isOpen: true, userId: userId, flexTicketId: flexTicketId, deviceId: deviceId });
	};

	const hideMoveFlexTicket = (flexId: string, newDeviceId: string | undefined) => {
		setMoveFlexTicketProps({ isOpen: false, userId: undefined, flexTicketId: undefined, deviceId: undefined });
		setFlexTicketId(flexId);
		return
	}

	const handlePrevOrderPage = (e: any) => {
		if (orderPage > 0) {
			setOrderPage(orderPage - 1);
		}
	};

	const handleNextOrderPage = (e: any) => {
		if (orderPage < (filteredOrders.length / ordersPerPage) - 1) {
			setOrderPage(orderPage + 1);
		}
	};

	return (<>
		<Toolbar>
			<Box display='flex' flexGrow={1}>
				<Typography variant="h6">
					V1 Orders
					{!isHidden &&
						<Button onClick={() => setIsHidden(true)} style={{ marginLeft: "10px" }}>
							Hide
						</Button>
					}
				</Typography>
			</Box>

			{!isHidden && <>
				<FormControlLabel
					label="Completed"
					control={<Checkbox onChange={() => toggleStatusFilter(OrderStatus.Completed)} checked={filters.status.includes(OrderStatus.Completed)} size="small" color="primary" />}
				/>
				<FormControlLabel
					label="Cancelled"
					control={<Checkbox onChange={() => toggleStatusFilter(OrderStatus.Cancelled)} checked={filters.status.includes(OrderStatus.Cancelled)} size="small" color="primary" />}
				/>
				<FormControlLabel
					label="Credited"
					control={<Checkbox onChange={() => toggleStatusFilter(OrderStatus.Credited)} checked={filters.status.includes(OrderStatus.Credited)} size="small" color="primary" />}
				/>
				<FormControlLabel
					label="Other"
					control={<Checkbox onChange={() => toggleStatusFilter(OrderStatus.Unknown)} checked={filters.status.includes(OrderStatus.Unknown)} size="small" color="primary" />}
				/>
			</>}
		</Toolbar>
		<TableContainer component={Paper} hidden={!isHidden}>
			<Table aria-label="HiddenOrders">
				<TableBody>
					<TableRow>
						<TableCell>
							<Link onClick={() => setIsHidden(false)} style={{ cursor: "pointer" }}>Show</Link>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
		<TableContainer component={Paper} hidden={isHidden}>
			<Table aria-label="Orders">
				<TableHead>
					<TableRow>
						<TableCell width={20} />
						<TableCell width={20} />
						<TableCell>
							<TextField
								fullWidth
								size="small"
								variant="outlined"
								color="primary"
								placeholder="Order / Transaction Id"
								onChange={(ev) => {
									setFilters({ ...filters, freetext: ev.target.value });
								}}
							/>
						</TableCell>
						<TableCell align="right">VAT</TableCell>
						<TableCell align="right">Sum</TableCell>
						<TableCell width={20} align="left">Payment</TableCell>
						<TableCell width={20}></TableCell>
						<TableCell align="right">Created / Updated</TableCell>
						<TableCell align="center">Status</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{paginatedOrders.map((order) =>
						<UserOrderItem
							key={user.user_id + "-" + order.id}
							order={order}
							user={user}
							showLogInspector={showLogInspector}
							showMoveFlexTicket={showMoveFlexTicket}
						/>
					)}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TableCell align="left">
							<Button endIcon={<ArrowLeft />} onClick={handlePrevOrderPage} />
						</TableCell>
						<TableCell align="center" colSpan={7}>
							<Typography>{orderPageHint}</Typography>
						</TableCell>
						<TableCell align="right">
							<Button endIcon={<ArrowRight />} onClick={handleNextOrderPage} />
						</TableCell>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
		<LogInspector
			open={isLogInspectorOpen}
			from={logInspectorFromDate}
			to={logInspectorToDate}
			terms={logInspectorTerms}
			onClose={hideLogInspector}
		/>
		<FlexTicketMove
			isOpen={moveFlexTicketProps.isOpen}
			fromDeviceId={moveFlexTicketProps.deviceId!}
			onClose={hideMoveFlexTicket}
			// onMove={moveTicket}
			userId={moveFlexTicketProps?.userId || ""}
			flexTicketId={moveFlexTicketProps?.flexTicketId || ""}
		/>
	</>
	);
};