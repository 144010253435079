import React, { useEffect, useState, SetStateAction } from "react";
import { useParams, useHistory } from "react-router-dom";

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';

import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Alert from "@material-ui/lab/Alert";
import { DropzoneDialog } from "material-ui-dropzone";

import TimePicker from "../TimePicker";
import NotificationChangeLog from "./NotificationsChangeLog";

import { Loader } from "../loader";
import { makeStyles } from "@material-ui/core";

import {
	getNotification,
	updateNotification,
	deleteNotification,
	createNotification,
	uploadFile,
	Notification,
	UploadResponse
} from "../../api/notifications";

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(5),
	},
	alert: {
		marginBottom: theme.spacing(3),
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '20%',
		flexShrink: 0,
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary,
	},
	footer: {
		borderTop: "1px solid #ccc",
		backgroundColor: theme.palette.grey[100],
		padding: `${theme.spacing(2)}px`,
		textAlign: 'right',
	},
	tabs: {
		borderBottom: "1px solid #ccc",
	},
	tabPanel: {
		padding: `${theme.spacing(2)}px`,
	}
}));

type Item = {
	value: string;
	label: string;
}

export const NotificationsDetail = () => {
	const classes = useStyles();
	const history = useHistory();
	const [alertMessage, setAlertMessage] = useState<string>("");
	const { notification_id } = useParams<{notification_id: string}>();
	const [tabIndex, setTabIndex] = useState<number>(0);
	const [loaded, setLoaded] = useState<boolean>(false);
	const [canSave, setCanSave] = useState<boolean>(false);
	const [canDelete, setCanDelete] = useState<boolean>(false);
	const [dropzoneOpen, setDropzoneOpen] = useState<boolean>(false);
	const [isEditable, setIsEditable] = useState<boolean>(true);
	
	const [failed, setFailed] = useState<boolean>(false);
	const [notification, setNotification] = useState<Notification>();

	const toggleTabs = (event: any, value: SetStateAction<number>) => {
		setTabIndex(value);
	};

	const deviceTargets: Item[] = [
		{value:"ios",label:"iOS"},
		{value:"android",label:"Android"},
	];

	const deliveryMechanisms: Item[] = [
		{value:"app",label:"In-app notification"},
		{value:"push",label:"Push notification"},
	];

	const notificationTypes: Item[] = [
		{value:"deviations", label:"Deviations"},
		{value:"tickets", label:"Tickets"},
		{value:"ads", label:"Ads"},
		{value:"info", label:"Information"},
		{value:"unknown", label:"Unknown"},
	];

	useEffect(() => {
		if (notification_id === "new") {
			let newNotification: Notification = {
				created: 0,
				title: "New notification",
				notification_id: "",
				device: ["ios","android"],
				delivery: [],
				change_log: [],
				type: "deviations",
			};

			setNotification(newNotification);
			setLoaded(true);
			setCanSave(true);
			setCanDelete(false);
			setIsEditable(true);
			return;
		}

		getNotification(notification_id)
		.then((data)=>{
			setNotification(data);
			setLoaded(true);
			setCanDelete(true);
			// Editable if idle or active (0, 1)
			// Not editable if sent or cancelled (2, 3)
			if (data.push_notification_state === 0 || data.push_notification_state === 1) {
				setIsEditable(true);
			} else {
				setIsEditable(false);
				if (data.push_notification_state === 2) {
					setAlertMessage("Not editable, this notification has been sent");
				} else if(data.push_notification_state === 2) {
					setAlertMessage("Not editable, this notification was cancelled");
				}
			}
			console.log("Retrieved notification", data);
		})
		.catch((err)=>{
			setFailed(true);
			setCanDelete(false);
			console.log("Failed loading notification", notification_id, err);
		});
	}, [notification_id]);

	useEffect(()=> {
		if (!notification) return;
		
		setAlertMessage("");

		if (notification.delivery && notification.delivery.includes("push") && ((notification.start_at === undefined) || (notification.start_at === 0))) {
			setAlertMessage("Push notifications must have a start date set in order to be sent.");
		}

	},[notification])

	if (failed) {
		return <>
			<Typography variant="h5">
				Failed loading notification
			</Typography>
		</>;
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!notification) return
		console.log("handleChange", event.target.id, event.target.value);
		setCanSave(true);
		setNotification({ ...notification, [event.target.id]: event.target.value });
	}

	const handleSelectChange = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, property: string) => {
		if (!notification) return;

		console.log("handleSelectChange", property, event.target.value);
		let value:string = event.target.value as string;
		setCanSave(true);
		setNotification({ ...notification, [property]: value});
	}

	const handleStartTimePickerChange = (inDate: Date | null) => {
		if (!notification) return;
		setCanSave(true);

		if (!inDate) {
			setNotification({ ...notification, start_time: undefined, start_at: undefined })
			return;
		}

		let start_at: number = inDate.getTime() / 1000;
		setNotification({ ...notification, start_time: inDate, start_at: start_at })
	}

	const handleEndTimePickerChange = (inDate: Date | null) => {
		if (!notification) return;
		setCanSave(true);
		
		if (!inDate) {
			setNotification({ ...notification, end_time: undefined, end_at: undefined })
			return;
		}

		setCanSave(true);
		let end_at: number = inDate.getTime() / 1000;
		setNotification({ ...notification, end_time: inDate, end_at: end_at });
	}

	const handleChangeList = (event:React.ChangeEvent<HTMLInputElement>, checked: boolean, listId:string, list:string[]) => {
		if (!notification) return;
		

		// Toggle setting
		let myList = list;
		if (myList === null) myList = [];
		
		if (!checked) {
			myList = myList.filter(v => v !== event.target.value); 
		} else {
			myList.push(event.target.value);
		}

		setCanSave(myList.length > 0)
		setNotification({...notification, [listId]: myList});
	}

	const isChecked = (list: string[], value: string): boolean =>  {
		if ((list === null) || (list === undefined)) return false;
		return list.includes(value);
	}

	const checkDate = (input: number | undefined | null): (Date | null) => {
		if ((input === undefined) || (input === null) || (input === 0)) return null;

		// multiplier is to adjust for the backend using unix timestamp with seconds
		// whereas the js Date object uses milliseconds
		let multiplier : number = 1000;
		return new Date(input * multiplier);
	}

	const onClickSave = () => {
		if (!notification) return;
		setCanSave(false);

		if (notification.notification_id.length > 0) {
			
			console.log("updating existing notification", notification.notification_id);
			updateNotification(notification.notification_id, notification)
			.then((updatedNotification)=>{
				console.log("updated existing notification", updatedNotification);
				setNotification(updatedNotification);
				history.push("/notifications");
			})
			.catch((err)=>{
				setCanSave(true);
				console.log("failed updating notification", err);
				alert('Failed updating notification');
			});
			return;
		} 

		console.log("creating new notification");
		createNotification(notification)
		.then((newNotification)=> {
			console.log("created new notification", newNotification);
			setNotification(newNotification);
			history.push("/notifications");
		})
		.catch((err)=>{
			setCanSave(true);
			console.log("failed creating notification", err);
			alert('Failed creating new notification');
		});
	}

	const onClickDelete = () => {
		console.log("clickDelete", notification);
		if (!notification) return;
		if (notification?.notification_id === "new") return;
		setCanSave(false);
		setCanDelete(false);

		if (window.confirm("Are you sure?")) {
			deleteNotification(notification?.notification_id)
			.then((data)=>{
				console.log("deleted notification", notification.notification_id, data);
				history.push("/notifications");
			})
			.catch((err)=>{
				setCanDelete(true);
				console.log("failed deleting notification", err);
				alert('Failed deleting notification');	
			});
		}
	}

	const handleOpenDropzone = () => {
		setDropzoneOpen(true);
	}

	const handleCloseDropzone = () => {
		setDropzoneOpen(false);
	}

	const handleDropzoneSave = (files: File[]) => {
		if (!notification) return;

		const result = uploadFile(files)
			.then((res) => {
				setNotification({...notification, image_url: res.link});
				setDropzoneOpen(false);
			})
			.catch((err)=>{
				setCanDelete(true);
				console.log("failed uploading notification image:", err);
				alert('Failed uploading notification image');
			});
	}

	if (!loaded) {
		return <Loader />;
	}

	if (!notification) {
		return <></>;
	}

	return <>
			<Box className={classes.alert}>
				{alertMessage && 
					<Alert severity="warning">{alertMessage}</Alert>
				}
			</Box>
			<TextField fullWidth variant="outlined" id="title" label="Title" value={notification?.title} InputProps={{readOnly: !isEditable}} onChange={handleChange} />
			<Paper className={classes.paper}>			
				<Tabs onChange={toggleTabs} value={tabIndex} className={classes.tabs} aria-label="Notification details">
					<Tab label="Content" />
					<Tab label="Delivery" />
					{notification.change_log.length > 0 && 
					<Tab label="Change log" />}
				</Tabs>
				<Box className={classes.tabPanel} hidden={(tabIndex !== 0)}>
					<Accordion>
						<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						>
						<Typography className={classes.heading}>English</Typography>
						<Typography className={classes.secondaryHeading}>{notification?.title_en}</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container spacing={4}>
								<Grid item sm={12}>
									<TextField fullWidth id="title_en" label="Title (en)" value={notification?.title_en} InputProps={{readOnly: !isEditable}} onChange={handleChange} />
								</Grid>
								<Grid item sm={12}>
									<TextField fullWidth multiline id="content_en" label="Content (en)" value={notification?.content_en} InputProps={{readOnly: !isEditable}} onChange={handleChange} />
								</Grid>
								<Grid item sm={12}>
									<TextField fullWidth id="cta_en" label="Button title (en)" value={notification?.cta_en} InputProps={{readOnly: !isEditable}} onChange={handleChange} />
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
						>
						<Typography className={classes.heading}>Swedish</Typography>
						<Typography className={classes.secondaryHeading}>{notification?.title_sv}</Typography>
						</AccordionSummary>
						<AccordionDetails>
						<Grid container spacing={4}>
								<Grid item sm={12}>
									<TextField fullWidth id="title_sv" label="Title (sv)" value={notification?.title_sv} InputProps={{readOnly: !isEditable}} onChange={handleChange} />
								</Grid>
								<Grid item sm={12}>
									<TextField fullWidth multiline id="content_sv" label="Content (sv)" value={notification?.content_sv} InputProps={{readOnly: !isEditable}} onChange={handleChange} />
								</Grid>
								<Grid item sm={12}>
									<TextField fullWidth id="cta_sv" label="Button title (sv)" value={notification?.cta_sv} InputProps={{readOnly: !isEditable}} onChange={handleChange} />
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>
					<Accordion>
						<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3a-content"
						id="panel3a-header"
						>
						<Typography className={classes.heading}>Arabic</Typography>
						<Typography className={classes.secondaryHeading}>{notification?.title_ar}</Typography>
						</AccordionSummary>
						<AccordionDetails>
						<Grid container spacing={4}>
								<Grid item sm={12}>
									<TextField fullWidth id="title_ar" label="Title (ar)" value={notification?.title_ar} InputProps={{readOnly: !isEditable}} onChange={handleChange} />
								</Grid>
								<Grid item sm={12}>
									<TextField fullWidth multiline id="content_ar" label="Content (ar)" value={notification?.content_ar} InputProps={{readOnly: !isEditable}} onChange={handleChange} />
								</Grid>
								<Grid item sm={12}>
									<TextField fullWidth id="cta_ar" label="Button title (ar)" value={notification?.cta_ar} InputProps={{readOnly: !isEditable}} onChange={handleChange} />
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>
					<Grid container spacing={4}>
						<Grid item sm={12}>&nbsp;</Grid>
						<Grid item sm={12}>
							<FormControl component="fieldset">
								<InputLabel htmlFor="target-protocol-label">Link protocol</InputLabel>
								<Select style={{width:"150px"}} onChange={(e)=>handleSelectChange(e,"target_protocol")} id="target_protocol" defaultValue="" inputProps={{readOnly: !isEditable}} value={notification.target_protocol} labelId="target-protocol-label">
										<MenuItem value=""><em>None</em></MenuItem>
										<MenuItem value="http://">http://</MenuItem>
										<MenuItem value="https://">https://</MenuItem>
										<MenuItem value="travis://">travis://</MenuItem>
								</Select>
							</FormControl>
							<TextField id="target" label="Link target" value={notification?.target} InputProps={{readOnly: !isEditable}} onChange={handleChange} />
						</Grid>
						<Grid item sm={12}>
							<Grid container direction="row">
								<Grid item sm={11}>
									<TextField fullWidth id="image_url" label="Image URL" value={notification?.image_url} InputProps={{readOnly: !isEditable}} onChange={handleChange} />
								</Grid>
								<Grid item>
									<Button onClick={handleOpenDropzone} disabled={!isEditable} >Upload</Button>
									<DropzoneDialog
										open={dropzoneOpen}
										onClose={handleCloseDropzone}
										onSave={handleDropzoneSave}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item sm={12}>
							<FormControl component="fieldset">
								<FormLabel component="legend">Notification type</FormLabel>
								<RadioGroup onChange={handleChange} aria-label="Notification type"  value={notification?.type} >
									{notificationTypes.map((notificationType)=> {
										return (<FormControlLabel 
													value={notificationType.value} 
													key={notificationType.value}
													control={<Radio id="type" />} 
													disabled={!isEditable}
													label={notificationType.label} />)
									})}
								</RadioGroup>
							</FormControl>
						</Grid>
					</Grid>
				</Box>
				<Box className={classes.tabPanel} hidden={(tabIndex !== 1)}>
					<Grid container spacing={4}>
						<Grid item sm={6}>
							<FormControl component="fieldset">
								<FormLabel component="legend">Target devices</FormLabel>
								<FormGroup aria-label="Target devices">
									{deviceTargets.map((device)=> {
										return (<FormControlLabel 
													value={device.value} 
													key={device.value}
													control={<Checkbox 
																onChange={(e,c)=>handleChangeList(e,c,"device", notification.device)}
																checked={isChecked(notification.device, device.value)} 
																/>} 
													disabled={!isEditable}
													label={device.label} />)
									})}
								</FormGroup>
							</FormControl>
						</Grid>
						<Grid item sm={6}>
							<FormControl component="fieldset">
								<FormLabel component="legend">Delivery mechanism</FormLabel>
								<FormGroup aria-label="Delivery mechanism">
									{deliveryMechanisms.map((delivery)=> {
										return (<FormControlLabel 
													value={delivery.value} 
													key={delivery.value}
													control={<Checkbox 
																onChange={(e,c)=>handleChangeList(e,c,"delivery", notification.delivery)}
																checked={isChecked(notification.delivery, delivery.value)} 
																/>} 
													disabled={!isEditable}
													label={delivery.label} />)
									})}
								</FormGroup>
							</FormControl>
						</Grid>
						<Grid item sm={12}>
							<FormLabel component="legend">Target version</FormLabel><br/>
							<FormControl component="fieldset">
								<InputLabel htmlFor="target-version-label">Comparator</InputLabel>
								<Select style={{width:"220px"}} onChange={(e)=>handleSelectChange(e,"target_version_comparator")} id="target_version_comparator" inputProps={{readOnly: !isEditable}} value={notification.target_version_comparator} labelId="target-version-label">
									<MenuItem value=""><em>None</em></MenuItem>
									<MenuItem value={"eq"}>== Equals</MenuItem>
									<MenuItem value={"gte"}>&gt;= Greater than or equals</MenuItem>
									<MenuItem value={"lte"}>&lt;= Less than or equals</MenuItem>
								</Select>
							</FormControl>
							<TextField id="target_version" label="Version (ex. 4.6.1)" value={notification?.target_version} InputProps={{readOnly: !isEditable}} onChange={handleChange} />
						</Grid>
						<Grid item sm={6}>
							<TimePicker id="start_at" onChange={handleStartTimePickerChange} defaultDate={checkDate(notification?.start_at)} label="Start time" emptyLabel="No start time set" disabled={!isEditable} />
						</Grid>
						<Grid item sm={6}>
							<TimePicker onChange={handleEndTimePickerChange} defaultDate={checkDate(notification?.end_at)} label="End time" emptyLabel="No end time set" disabled={!isEditable} />
						</Grid>
					</Grid>
				</Box>
				<Box className={classes.tabPanel} hidden={(tabIndex !== 2)}>
					<NotificationChangeLog entries={notification.change_log} />
				</Box>
				<Box className={classes.footer} color="text.primary">
					<Grid container justifyContent="space-between">
						<Grid item>
							<Button variant="contained" color="secondary" onClick={onClickDelete} disabled={!canDelete}>
								Delete
							</Button>
						</Grid>
						<Grid item>
							<Button variant="contained" color="primary" onClick={onClickSave} disabled={!canSave || !isEditable}>
								Save
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>;
}
