import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
	getRental,
	Rental as RentalType,
	RentalEvent,
	EventType,
} from "../../api/rentals-new";

import Map from "../map";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Paper from "@material-ui/core/Paper";
import Check from "@material-ui/icons/Check";
import Error from "@material-ui/icons/Error";
import { OperatorIcon } from "../operator_icon";
import { format } from "date-fns";

import Typography from "@material-ui/core/Typography";

// Poll the rental every 5 seconds
const rentalPollInterval = 5000;

type RentalParams = {
	rental_id: string;
};

const useStyles = makeStyles({
	paper: {
		width: "100%",
	},
	grid: {
		minHeight: 50,
		padding: 10,
	},
});

export const Event = ({ event }: { event: RentalEvent }) => {
	const classes = useStyles();
	return (
		<Grid container item>
			<Paper className={classes.paper} elevation={1}>
				<Grid className={classes.grid} alignItems="center" container>
					<Grid item xs={6}>
						{event.successful ? <Check /> : <Error />} {event.type}
					</Grid>
					<Grid item xs={4}>
						Created:{" "}
						{format(new Date(event.created), "yyyy/MM/dd kk:mm:ss")}
						<br />
						{event.duration
							? `Rental duration (minutes): ${event.duration}`
							: null}
					</Grid>
					<Grid item xs={2}>
						Source: {event.source}
					</Grid>
				</Grid>
				{event.error ? (
					<Grid container item>
						{event.error}
					</Grid>
				) : null}
			</Paper>
		</Grid>
	);
};

export const RentalEvents = ({ events }: { events?: Array<RentalEvent> }) => {
	return (
		<Grid container spacing={2}>
			{events?.map((event) => {
				switch (event.type) {
					case EventType.PreStartReserveEventType:
					case EventType.PreStartVerifyEventType:
					case EventType.StartEventType:
					case EventType.EndEventType:
					case EventType.PaymentRegisterUnpaidEventType:
					case EventType.PaymentEventType:
						return <Event key={event.created} event={event} />;
					default:
						return (
							<Grid key={event.created} item>
								Unknown event type {event.type}
							</Grid>
						);
				}
			})}
		</Grid>
	);
};

/* Remove later */

const mockRental: RentalType = {
	id: "asd",
	partner_rental_id: "partner-rental-id-123",
	user_id: "A-UsErId?!",
	operator: "se_voi",
	events: [
		{
			created: "2021-04-30T08:29:35+02:00",
			location: {
				lat: 59.329323,
				lon: 18.068581,
				z: 0,
			},
			type: EventType.StartEventType,
			successful: true,
			error: "",
		},
		{
			created: "2021-04-30T08:34:59+02:00",
			location: {
				lat: 59.334579,
				lon: 18.062389,
				z: 0,
			},
			type: EventType.PaymentEventType,
			successful: true,
			error: "",
			order_id: "123123123",
		},
		{
			created: "2021-04-30T08:35:00+02:00",
			location: {
				lat: 59.334679,
				lon: 18.062410,
				z: 0,
			},
			type: EventType.EndEventType,
			successful: true,
			error: "",
			duration: 5,
		},
	],
	vehicle: {
		type: "",
		geometry: {
			type: "asd",
			coordinates: [18.068581, 59.329323],
		},
		properties: {
			id: "asd",
			code: "Code?!",
			distance: 100,
			operator_url: "asdjkasd",
			battery_level: 100,
			name: "asjkdlas",
			icon: "iojadowj",
			selected_icon: "asjdok",
			icon_url: "i0948sdfmölsd",
			operator_code: "jskaldjals",
			transportation_type: "asdkljas",
			location: [18.068581, 59.329323],
			price_details: [],
			mapbox_source: "ajknsdoiqw",
			mapbox_layers: ["voi_zones_outline", "voi_zones_fill"],
			cluster_icon_normal: "asdoiqwq",
			cluster_icon_wide: "q9weu81u2",
		},
	},
	active: false,
	created: "2021-04-30T08:29:35+02:00",
	modified: "2021-04-30T08:35:00+02:00",
};

/* EndMock */

export const Rental = () => {
	const [rental, setRental] = useState<RentalType | null>();
	const [hasEnded, setHasEnded] = useState<boolean>(false);

	const { rental_id } = useParams<RentalParams>();

	// This function is responsible for actually fetching the rental
	const fetchRentalCallback = useCallback(() => {
		if (!rental_id) {
			console.log("fetchRental() rental_id is undefined");
			return;
		}

		if (rental_id === "test-rental") {
			setRental(mockRental);
			return;
		}

		console.log(`Fetching rental ${rental_id}`);

		getRental(rental_id)
			.then(setRental)
			.catch((err) => {
				console.log("An error occured while fetching a rental:", err);
			});


	}, [rental_id]);


	useEffect(() => {
		// Immediately fetch the rental
		fetchRentalCallback();


		// Set up a timer to poll the state of the rental.
		const intervalId = setInterval(
			(prev) => {
				if (prev) {
					// Clearing interval
					clearInterval(prev);
				}
				fetchRentalCallback();
			}, rentalPollInterval);
		return () => clearInterval(intervalId);
	}, [fetchRentalCallback]);

	useEffect(() => {
		if (!rental) {
			return;
		}

		setHasEnded(!!rental.events.find(e => e.type === EventType.EndEventType));
	}, [rental]);


	const createMarkers = (rentalEvents: RentalEvent[], vehicleLoc: number[]) => {
		const eventTypeToIcon = (eventType: EventType) => {
			switch (eventType) {
				case EventType.StartEventType:
					return "aubergine_selected";
				case EventType.EndEventType:
					return "finished";
				default:
					return "unknown";
			}
		};

		var filteredEvents = rentalEvents.filter(function (ev) {
			if (ev.type === EventType.EndEventType) {
				return true;
			} else if (ev.type === EventType.StartEventType) {
				return true;
			}
			return false;
		}
		)

		var ev = filteredEvents.map((event) => {
			var type = eventTypeToIcon(event.type);

			return {
				lat: event.location.lat,
				lng: event.location.lon,
				type: type,
				desc: '',
			}
		});

		if (!hasEnded) {
			console.log("Active ride at: " + vehicleLoc[1] + " " + vehicleLoc[0]);
			ev.push({
				lat: vehicleLoc[1],
				lng: vehicleLoc[0],
				type: "scooter",
				desc: '',
			});
		}

		console.log(JSON.stringify(ev, null, "   "));
		return ev;
	};

	return (
		<Grid container spacing={4} direction="column">
			<Grid
				item
				container
				direction="row"
				wrap="nowrap"
				justifyContent="space-around"
			>
				<Grid item>
					<List>
						<ListItem>
							<ListItemIcon>
								{rental ? <OperatorIcon operator={rental.operator} /> : null}
							</ListItemIcon>
							<ListItemText
								primary="Partner Rental ID"
								secondary={rental?.partner_rental_id}
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								primary="Rental ID"
								secondary={rental?.id}
							/>
						</ListItem>
						<ListItem
							component={Link}
							to={`/users/${rental?.user_id}`}
						>
							<ListItemText
								primary="User ID"
								secondary={rental?.user_id}
							/>
						</ListItem>
					</List>
				</Grid>
				<Grid item>
					<List>
						<ListItem>
							{rental ? (
								<ListItemText
									primary="Created"
									secondary={format(new Date(rental.created), "yyyy/MM/dd kk:mm:ss")}
								/>
							) : null}
						</ListItem>
						<ListItem>
							{rental ? (
								<ListItemText
									primary="Modified"
									secondary={format(new Date(rental.modified), "yyyy/MM/dd kk:mm:ss")}
								/>
							) : null}
						</ListItem>
						<ListItem>
							<ListItemText
								primary="Vehicle Code"
								secondary={rental?.vehicle.properties.code}
							/>
						</ListItem>
					</List>
				</Grid>
			</Grid>
			<Grid item>
				<RentalEvents events={rental?.events} />
			</Grid>
			{rental ? (
				<Grid style={{ position: "relative" }} item>
					{rental?.vehicle?.geometry?.coordinates ?
						<Map
							layers={rental.vehicle.properties.mapbox_layers}
							points={createMarkers(rental.events, rental.vehicle.properties.location)}
						/>
						:
						<Typography variant="h5">Rental Vehicle data is corrupt.</Typography>
					}
				</Grid>
			) : null}
		</Grid>
	);
};
