import { format } from "date-fns";


interface DateLabelProps {
	inputDate: Date | number | undefined;
	inputFormat?: string;
	outputFormat?: string;
}

export const DateLabel = (props:DateLabelProps)  => {
	var multiplier : number = 1000;

	if (props.inputDate === undefined) {
		return <></>;
	}
	
	if (typeof props.inputDate === 'number') {
		if (!props.inputFormat) {
			const date = new Date(props.inputDate * multiplier);
			return <>{date.toLocaleString("sv-SE")}</>;
		}
		const date = new Date(props.inputDate / 1000000);
		return <>{date.toLocaleString("sv-SE")+"."+date.getMilliseconds()}</>;
	}

	return <>{format(new Date(props.inputDate), "yyyy-MM-dd HH:mm:ss")}</>;
}

export default DateLabel;