import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import { Color as AlertColor } from '@material-ui/lab/Alert'
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { getUser, mergeUser, User } from "../../api/user";

type UserMergeModalProps = {
    sourceUser: User;
    onClose: () => void;
    isOpen: boolean;
}

export const UserMergeModal = (props: UserMergeModalProps) => {

    const [inProgress, setInProgress] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [targetUserID, setTargetUserID] = useState<string>("");
    const [isValidTargetUser, setIsValidTargetUser] = useState<boolean>(false);

    const handleClose = () => {
        props.onClose();
    };

    const handleMerge = () => {
        console.log(props.sourceUser.user_id);
        console.log(targetUserID);

        if (!props.sourceUser.user_id || (props.sourceUser.user_id === targetUserID || targetUserID === "")) {
            return;
        }


        setInProgress(true);
        mergeUser(props.sourceUser.user_id, targetUserID)
            .then(async (response: Response) => {
                const resp = await response;
                if (resp.ok) {
                    props.onClose();
                    return
                }


                setAlertMessage(resp.statusText);
            })
            .finally(() => {
                setInProgress(false);
            });
    };

    const handleOnTargetUserChanged = (e: any) => {
        setTargetUserID(e.target.value);
        setIsValidTargetUser(false);

        // Yeah I thought I'd ask for the user ID while the TextField was being
        // populated but this endpoint is painfully slow
        /*
        getUser(e.target.value)
            .then((response) => {
                setIsValidTargetUser(true);
            })
            .catch(err => {
                setIsValidTargetUser(false);
            });
            */
    };

    const handleCheckForExistence = (e: any) => {
        setInProgress(true);

        getUser(targetUserID)
            .then((response) => {
                setIsValidTargetUser(true);
            })
            .catch(err => {
                setIsValidTargetUser(false);
            })
            .finally(() => {
                setInProgress(false);
            });

    }

    const response = false;

    return (
        <>
            <Box>
                {alertMessage &&
                    <Alert severity="warning">{alertMessage}</Alert>
                }
            </Box>
            <Dialog open={props.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Merge users</DialogTitle>
                {response &&
                    <Alert severity="error">{"nisse"}</Alert>}
                <DialogContent>
                    <DialogContentText>
                        Merge the contents from one user to another. This operation is irreversible and will result in the deletion of the source user.
                    </DialogContentText>
                    {inProgress &&
                        <CircularProgress />
                    }
                    {!inProgress &&
                        <Grid direction="row">
                            <Grid item xs={12}>
                                <TextField
                                    margin="dense"
                                    placeholder="From user"
                                    id="sourceUserID"
                                    label="From user"
                                    type="text"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth
                                    value={props.sourceUser.user_id}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    margin="dense"
                                    placeholder="To user"
                                    id="targetUserID"
                                    label="To user"
                                    type="text"
                                    fullWidth
                                    value={targetUserID}
                                    onChange={handleOnTargetUserChanged} />
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    disabled={inProgress || targetUserID === ""}
                                    variant="contained"
                                    onClick={handleCheckForExistence}
                                    color="primary">
                                    Check
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Button disabled={inProgress} color="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={inProgress || !isValidTargetUser}
                                variant="contained"
                                onClick={handleMerge}
                                color="primary">
                                Merge
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
}