import React, { useEffect, useState } from "react";
import {
	getPermissions,
	addUserAsAdmin,
	removeUserAsAdmin,
	Permission,
} from "../../api/permissions";
import Paper from "@material-ui/core/Paper";
import { Table, TableBody, TableRow, TableCell, TableHead } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Loader } from "../loader";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(3),
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(5),
	}
}));

export const Permissions = () => {
	const classes = useStyles();
	const [permissions, setPermissions] = useState<Permission[]>([]);
	const [addUser, setAddUser] = useState<string>("");
	const [loaded, setLoaded] = useState<boolean>(false);

	const getUserPermissions = () => {
		getPermissions()
			.then((users) => {
				return users.filter(
					(user) => user.permissions["admin"] === true,
				);
			})
			.then(setPermissions)
			.then(() => setLoaded(true));
	};

	useEffect(() => {
		getUserPermissions();
	}, []);

	const handleAddAdmin = () => {
		if (addUser.length > 0) {
			addUserAsAdmin(addUser).then(() => setAddUser("")).then(getUserPermissions);
		}
	};

	if (loaded) {
		return (
			<>
			<Box display="flex" justifyContent="space-between" alignContent="center">	
				<Toolbar>
					<Typography variant="h6">Admins</Typography>
				</Toolbar>	
				<Box>
					<Toolbar>
						<TextField
								value={addUser}
								variant="outlined"
								size="small"
								onChange={(ev) => setAddUser(ev.target.value)}
								placeholder="E-mail address"
							/>
							&nbsp;
							<Button 
								variant="contained"
								color="primary"
								onClick={handleAddAdmin}>
									Add Admin
							</Button>
						</Toolbar>
				</Box>
			</Box>			
			<Paper className={classes.paper}>				
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell width={30}></TableCell>
							<TableCell>E-mail</TableCell>
							<TableCell width={150} align="left">Created</TableCell>
							<TableCell width={40} align="right">Action</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
					{permissions.map((permission) => (
						<TableRow hover>
							<TableCell>
								<Avatar>
									
								</Avatar>
							</TableCell>
							<TableCell>{permission.email}</TableCell>							
							<TableCell>{permission.created}</TableCell>
							<TableCell align="right">
								<IconButton
									onClick={() =>
										removeUserAsAdmin(
											permission.email,
										).then(getUserPermissions)
									}
								>
									<DeleteIcon />
								</IconButton>
							</TableCell>
						</TableRow>
					))}
					</TableBody>
				</Table>
			</Paper>
			</>
		);
	}
	return <Loader />;
};
