import React, { createContext, useEffect, useState } from "react";
import { initApp, updateUser } from "../api";
import firebase from "firebase/app";

export type ContextUser = {
	loggedIn: boolean;
	userData: null | firebase.User;
};

export const AuthContext = createContext<ContextUser>({
	loggedIn: false,
	userData: null,
});

export const AuthProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [user, setUser] = useState<ContextUser>({
		loggedIn: false,
		userData: null,
	});

	useEffect(() => {
		initApp((newUser) => {
			if (newUser) {
				setUser(updateUser(newUser));
			}
		});
	}, []);

	return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};
