import React, { useState, useEffect } from "react";

import {
    Avatar,
    Box,
    Chip,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
    Typography,
    Button,
} from "@material-ui/core";
import {
    Apple as AppleIcon,
    BugReport as BugReportIcon,
    CreditCard as CreditCardIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@material-ui/icons";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { FlexTicketDetail } from "./FlexTicketDetailV2";

import { makeStyles } from "@material-ui/core/styles";
import { amber, green, red, blue } from "@material-ui/core/colors";

import { DateLabel } from "../general/DateLabel";
import { User } from "../../api/user";
import { OrderV2, Payment, PaymentState, OrderState } from "../../api/orders_v2";
import { FlexTicket, getFlexTicketsForOrder } from "../../api/flextickets_v2";
import { Operator } from "../../api/operators";
import { Product } from "../../api/products";

import { UserOrderItemProduct } from "./UserOrderItemProductV2";
import { UserPayment } from "./UserPayment";
import { UserCreditOrders } from "./UserCreditOrders";

const useStyles = makeStyles((theme) => ({
    rowHover: {
        cursor: "pointer"
    },
    smallAvatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    createdDate: {
        color: "#777",
    },
    createdDateToday: {
        color: "#B22",
    },
    updatedDate: {
        color: "#333",
    },
    updatedDateToday: {
        color: "#F00",
    },
    chipAllocated: {
        backgroundColor: blue[200],
        color: "#000",
    },
    chipReady: {
        backgroundColor: blue[200],
        color: "#000",
    },
    chipPayment: {
        backgroundColor: amber[200],
        color: "#000",
    },
    chipFulfillment: {
        backgroundColor: amber[200],
        color: "#000",
    },
    chipComplete: {
        backgroundColor: green[500],
        color: "#FFF",
    },
    chipCredited: {
        backgroundColor: amber[500],
        color: "#FFF",
    },
    chipUnpaid: {
        backgroundColor: red[500],
        color: "#FFF",
    },
    chipCancelled: {
        backgroundColor: red[500],
        color: "#FFF",
    },
    collapsibleCell: {
        paddingBottom: 0,
        paddingTop: 0,
        backgroundColor: "#f5f5f5",
    },
    hiddenCell: {
        borderWidth: 0,
        paddingBottom: 0,
        paddingTop: 0,
        backgroundColor: "#f5f5f5",
    },
    saleLine: {
        fontWeight: "bold",
        color: green[800],
        margin: 0,
    },
    capturedLine: {
        color: green[800],
        margin: 0,
    },
    creditedLine: {
        color: amber[800],
        margin: 0,
    },
}));

const asset_base = "https://storage.googleapis.com/travis-assets-prod/";

type UserOrderItemV2Props = {
    order: OrderV2,
    user: User,
    operators: { [index: string]: Operator },
    products: { [index: string]: Product },
    showLogInspector: Function,
    showMoveFlexTicket: (userId: string, flexTicketId: string, deviceId: string) => void,
};

export const UserOrderItemV2 = ({ order, user, operators, products, showLogInspector, showMoveFlexTicket }: UserOrderItemV2Props) => {
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [flextickets, setFlextickets] = useState<FlexTicket[] | undefined>(undefined)

    useEffect(() => {
        if (!order || !user || !user.user_id) {
            return;
        }

        if (open) {
            getFlexTicketsForOrder(user.user_id, order.id).then((tix) => {
                setFlextickets(tix);
            });
        }
    }, [open, order])

    if (order === undefined) {
        return <></>;
    }

    const orderAvatars = (order: OrderV2) => {
        let avatars: any = [];
        for (const i in order.order_lines) {
            const line = order.order_lines[i];
            const operatorIcon = `${asset_base}${line.operator_id}@2x.png`;

            avatars.push(<Avatar
                key={`${user.user_id} -${order.id} -avatar - ${i} `}
                className={classes.smallAvatar}
                src={operatorIcon}
            />);
        }

        return avatars;
    }

    const relevantPayment = (order: OrderV2) => {
        if (order.payment && [PaymentState.Settled, PaymentState.Credited].includes(order.payment.state)) {
            return order.payment;
        }
        return undefined;
    }

    const transactionId = (order: OrderV2) => {
        const payment = relevantPayment(order);
        if (!payment) {
            return "";
        } else {
            return payment.transaction_id;
        }
    }

    const paymentMethodForOrder = (className: string, order: OrderV2) => {
        const payment = relevantPayment(order);
        if (!payment) {
            return <>N/A</>
        }

        return paymentMethod(className, payment);
    }

    const paymentMethod = (className: string, payment: Payment) => {
        let method = payment.method?.toLowerCase();
        if (method === "swish") {
            return <Avatar
                className={className}
                src={`${asset_base} logos_swish % 40xxxhdpi.png`}
                alt="Swish"
            />;
        } else if (method === "apple_pay") {
            return <Avatar className={className} alt="Apple Pay">
                <AppleIcon />
            </Avatar>;
        } else if (["credit_card", "credit-card", "card"].includes(method)) {
            return <Avatar className={className} alt="Credit Card">
                <CreditCardIcon />
            </Avatar>;
        }

        return <>{payment.method}</>
    }

    const inspectLog = (ev: React.MouseEvent<HTMLElement>, order: OrderV2) => {
        ev.stopPropagation();
        showLogInspector(order);
    }

    const isToday = (timestamp: number) => {
        if (timestamp === 0) {
            return false
        }

        const today = new Date();
        const givenDate = new Date(timestamp * 1000);

        return (
            givenDate.getDate() === today.getDate() &&
            givenDate.getMonth() === today.getMonth() &&
            givenDate.getFullYear() === today.getFullYear()
        );
    }

    const isCredited = (order: OrderV2): boolean => {
        if (order.state != "complete") {
            return false;
        }

        return !!order.credit_orders;
    }

    const onProductChanged = () => {
        if (!order || !user || !user.user_id) {
            return;
        }

        getFlexTicketsForOrder(user.user_id, order.id).then((tix) => {
            setFlextickets(tix);
        });
    };

    const classNameForOrder = (order: OrderV2) => {
        switch (order.state) {
            case OrderState.Allocated:
                return classes.chipAllocated;
            case OrderState.Ready:
                return classes.chipReady;
            case OrderState.Unpaid:
                return classes.chipUnpaid;
            case OrderState.Payment:
                return classes.chipPayment;
            case OrderState.Fulfillment:
                return classes.chipFulfillment;
            case OrderState.Complete:
                return isCredited(order) ? classes.chipCredited : classes.chipComplete;
            case OrderState.Cancelled:
                return classes.chipCancelled;
            default:
                return "";
        }
    }

    const orderStateName = (order: OrderV2): string => {
        if (order.state === "complete") {
            return isCredited(order) ? "credited" : "complete";
        }

        return order.state;
    }

    const capitalize = (name: string) => {
        return name.charAt(0).toUpperCase() + name.slice(1);
    }

    return <>
        <TableRow hover className={classes.rowHover} onClick={() => setOpen(!open)}>
            <TableCell>
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                </IconButton>
            </TableCell>
            <TableCell>
                <AvatarGroup max={2}>
                    {orderAvatars(order)}
                </AvatarGroup>
            </TableCell>
            <TableCell>
                <strong>{order.id}</strong><br />
                {transactionId(order)}
            </TableCell>
            <TableCell align="right">
                {order.price.amount_vat.formatted} {order.price.amount_vat.currency} ({order.price.percent_vat}%)
            </TableCell>
            <TableCell align="right">
                {order.price.including_vat.formatted} {order.price.including_vat.currency}
            </TableCell>
            <TableCell align="center">
                <div style={{ display: "inline-block" }}>
                    {paymentMethodForOrder(classes.smallAvatar, order)}
                </div>
            </TableCell>
            <TableCell align="center">
                <div style={{ display: "inline-block" }}>
                    <IconButton
                        style={{ backgroundColor: "#fdd" }}
                        size="small"
                        onClick={(ev) => inspectLog(ev, order)}
                        color="secondary">
                        <BugReportIcon />
                    </IconButton>
                </div>
            </TableCell>
            <TableCell align="right">
                <Typography variant="caption">
                    <span className={isToday(order.created) ? classes.createdDateToday : classes.createdDate}><DateLabel inputDate={order.created} /></span><br />
                    <span className={isToday(order.updated) ? classes.updatedDateToday : classes.updatedDate}><DateLabel inputDate={order.updated} /></span>
                </Typography>
            </TableCell>
            <TableCell width="40" align="center">
                <Chip label={capitalize(orderStateName(order))} className={classNameForOrder(order)} />
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell className={open ? classes.collapsibleCell : classes.hiddenCell} colSpan={10}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box margin={3} hidden={!flextickets || flextickets.length === 0}>
                        <Typography variant="h6" gutterBottom component="div">
                            Flex Ticket
                        </Typography>
                        {flextickets?.map((flex) => <FlexTicketDetail key={`flexticket-detail-${flex.id} `} flex={flex} />)}
                    </Box>
                    <Box margin={3} hidden={order.order_lines.length === 0}>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Operator</TableCell>
                                    <TableCell>Product Type</TableCell>
                                    <TableCell>Price Group</TableCell>
                                    <TableCell align="right">Quantity</TableCell>
                                    <TableCell width="50" align="right">Amount</TableCell>
                                    <TableCell align="right">VAT</TableCell>
                                    <TableCell align="right">Sum</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {order.order_lines.map((line, idx) =>
                                    <UserOrderItemProduct
                                        key={`order - ${order.id} -${idx} `}
                                        line={line}
                                        order={order}
                                        flex={flextickets?.at(idx)}
                                        operators={operators}
                                        onProductChanged={onProductChanged}
                                    />
                                )}
                            </TableBody>
                        </Table>
                    </Box>
                    {order.credit_orders && <UserCreditOrders credit_orders={order.credit_orders} />}
                    {order.payment && <UserPayment payment={order.payment} />}
                </Collapse>
            </TableCell>
        </TableRow >
    </>;
}