import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';

import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";

import { UserMergeModal } from "./UserMergeModal";
import { UserGetReceiptsModal } from "./UserGetReceiptsModal";

import { User } from "../../api/user";
import { Button, Typography } from "@material-ui/core";
import { ProtectedComponent } from "../ProtectedComponent";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(2),
	},
}));

type UserInfoProps = {
	user: User | undefined;
}

export const UserInfo = ({ user }: UserInfoProps) => {
	const classes = useStyles();
	const [isOpenMergeModal, setIsOpenMergeModal] = useState<boolean>(false);
	const [isOpenGetReceiptsModal, setIsOpenGetReceiptsModal] = useState<boolean>(false);
	let email = user?.email != null ? user?.email : "";
	let name = user?.display_name != null ? user?.display_name : "";
	let is_anonymous = user?.is_anonymous != null ? user?.is_anonymous : true;
	let has_auth_account = user?.has_auth_account != null ? user?.has_auth_account : true;
	let has_firestore_account = user?.has_firestore_account != null ? user?.has_firestore_account : true;
	let auth_provider = user?.auth_providers != null ? user?.auth_providers : "";

	const handleOpenMergeModal = (e: any): void => {
		setIsOpenMergeModal(true);
	};

	const handleCloseMergeModal = () => {
		setIsOpenMergeModal(false);
	};

	const handleOpenGetReceiptsModal = (e: any): void => {
		setIsOpenGetReceiptsModal(true);
	};

	const handleCloseGetReceiptsModal = () => {
		setIsOpenGetReceiptsModal(false);
	};

	return <>
		<Toolbar>
			<Typography variant="h6">
				User
			</Typography>
		</Toolbar>
		<div className={classes.paper}>
			<Grid container spacing={2}>
				{is_anonymous && has_auth_account &&
					<Grid item xs={12}>
						<Alert severity="info">
							<strong>User has a no authorization provider</strong><br />
							This means that the user has a Firebase account, but is still <strong>considered anonymous</strong> since they have not logged in using an e-mail or via Google or Apple.
						</Alert>
					</Grid>}

				{!has_auth_account &&
					<Grid item xs={12}>
						<Alert severity="error">
							<strong>User has no associated Firebase Account</strong><br />
							This means that the user cannot log in and access this account. A Firebase account is required to create a user, which means that either the user has deleted their account or the account has been removed due to the user logging in with another authorization provider.
						</Alert>
					</Grid>}

				{!has_firestore_account &&
					<Grid item xs={12}>
						<Alert severity="error">
							This user has no associated Firestore Account, which means they have no data associated with them.
						</Alert>
					</Grid>}

				<Grid item>
					<TextField
						InputProps={{
							readOnly: true,
						}}
						fullWidth
						label="User ID"
						defaultValue="Normal"
						value={user?.user_id}
						variant="outlined" />
				</Grid>
				{email &&
					<Grid item>
						<TextField
							InputProps={{
								readOnly: true,
							}}
							fullWidth
							label="E-mail address"
							defaultValue="Normal"
							value={email}
							variant="outlined"
						/>
					</Grid>}
				{auth_provider &&
					<Grid item>
						<TextField
							InputProps={{
								readOnly: true,
							}}
							fullWidth
							label="Authorization provider"
							defaultValue="Normal"
							value={auth_provider}
							variant="outlined"
						/>
					</Grid>}
				{name &&
					<Grid item>
						<TextField
							InputProps={{
								readOnly: true,
							}}
							fullWidth
							label="Display name"
							defaultValue="Normal"
							value={name}
							variant="outlined"
						/>
					</Grid>}

				<Grid item>
					<ProtectedComponent claim={"merge_users"}>
						<Button variant="outlined" size="large" onClick={handleOpenMergeModal}>Merge to</Button>
					</ProtectedComponent>
				</Grid>
				<Grid item>
					<Button variant="outlined" size="large" onClick={handleOpenGetReceiptsModal}>Get receipts</Button>
				</Grid>
			</Grid>
			{isOpenMergeModal && !!user &&
				<UserMergeModal
					sourceUser={user}
					isOpen={isOpenMergeModal}
					onClose={handleCloseMergeModal}
				/>
			}
			{isOpenGetReceiptsModal && !!user &&
				<UserGetReceiptsModal
					user={user}
					isOpen={isOpenGetReceiptsModal}
					onClose={handleCloseGetReceiptsModal}
				/>
			}
		</div>
	</>;
}
