import { MouseEvent } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
	Campaign as CampaignType,
	CouponApplyRule,
	CouponDiscount,
	CouponDiscountType,
	CouponSpec,
	getCampaign,
	putCampaign,
} from "../../api/campaigns";
import { TimePicker } from "../TimePicker";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ActiveIcon from "@material-ui/icons/CheckCircleOutline";
import UpcomingIcon from "@material-ui/icons/Alarm";
import DisabledIcon from "@material-ui/icons/HighlightOff";
import ExpiredIcon from "@material-ui/icons/AlarmOff";

type CouponSpecListProps = {
	specs: Array<CouponSpec> | undefined;
};

export const CouponSpecList = ({ specs }: CouponSpecListProps) => {
	const classes = useStyles();
	const history = useHistory();

	const handleCouponSpecClick = (event: MouseEvent<HTMLTableRowElement | HTMLAnchorElement>, id: string) => {
		if (!(event.target instanceof HTMLAnchorElement)) {
			history.push(`/v2/coupon_specs/${id}`);
		}
	}

	const filterText = (spec: CouponSpec): string => {
		let filters = [];
		if (spec.operator !== "") {
			filters.push(spec.operator);
		}
		if (spec.transportationType !== "") {
			filters.push(spec.transportationType);
		}

		switch (filters.length) {
			case 0:
				return "<any>";
			default:
				return filters.join(", ");
		}
	}

	const applyRuleText = (rule: CouponApplyRule): string => {
		switch (rule) {
			case CouponApplyRule.AutoAll:
				return "Automatic (all users)"
			case CouponApplyRule.AutoNewUsers:
				return "Automatic (new users)"
			case CouponApplyRule.Code:
				return "Coupon Code"
		}
	}

	const discountText = (discount: CouponDiscount): string => {
		switch (discount.discountType) {
			case CouponDiscountType.Percent:
				return `${discount.percent}%`;
			case CouponDiscountType.SingleAmount:
				return `${discount.amount / discount.scaleDivider} ${discount.currency}`
			case CouponDiscountType.TotalAmount:
				return `${discount.amount / discount.scaleDivider} ${discount.currency} (one-time use)`;
		}
	}

	if (!specs) {
		return null;
	}

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>
							ID
						</TableCell>
						<TableCell>
							Code
						</TableCell>
						<TableCell>
							Filter
						</TableCell>
						<TableCell>
							Limit
						</TableCell>
						<TableCell>
							Apply Rule
						</TableCell>
						<TableCell>
							Discount
						</TableCell>
						<TableCell align="right">
							Created / Updated
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{specs.map((spec) => (
						<TableRow
							hover
							onClick={(e) => handleCouponSpecClick(e, spec.id)}
							key={spec.id}
							style={{ cursor: "pointer" }}
						>
							<TableCell>
								{spec.id}
							</TableCell>
							<TableCell>
								{spec.code}
							</TableCell>
							<TableCell>
								{filterText(spec)}
							</TableCell>
							<TableCell>
								{spec.limit}
							</TableCell>
							<TableCell>
								{applyRuleText(spec.applyRule)}
							</TableCell>
							<TableCell>
								{discountText(spec.discount)}
							</TableCell>
							<TableCell align="right">
								{format(spec.created, "yyyy/MM/dd kk:mm:ss")}
								<Typography color="textSecondary">
									{format(spec.updated, "yyyy/MM/dd kk:mm:ss")}
								</Typography>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

type CampaignParams = {
	campaignId: string;
};

const useStyles = makeStyles({
	paper: {
		width: "100%",
	},
	grid: {
		minHeight: 50,
		padding: 10,
	},
	table: {
		minWidth: 650,
	},
});

export const Campaign = () => {
	const { campaignId } = useParams<CampaignParams>();
	const [campaign, setCampaign] = useState<CampaignType | null>();

	useEffect(() => {
		getCampaign(campaignId).then(setCampaign);
	}, [campaignId]);

	const statusItem = (status: string | undefined) => {
		if (!status) {
			return null;
		}

		let icon = null;
		let colour = "gray";
		switch (status) {
			case "inactive":
				break;
			case "active":
				icon = (<ActiveIcon style={{ color: "green" }} />);
				colour = "green";
				break;
			case "upcoming":
				icon = (<UpcomingIcon style={{ color: "gray" }} />);
				colour = "gray";
				break;
			case "disabled":
				icon = (<DisabledIcon style={{ color: "red" }} />);
				colour = "red";
				break;
			case "expired":
				icon = (<ExpiredIcon style={{ color: "orange" }} />);
				colour = "orange";
				break;
		}

		return (
			<ListItem>
				<ListItemIcon>
					{icon}
				</ListItemIcon>
				<ListItemText
					primary="Status"
					secondary={status}
					style={{ color: colour }}
				/>
				<Button
					onClick={() => enableDisableCampaign(campaign)}
					style={{ marginLeft: "30px" }}
				>
					{campaign?.active ? "Disable" : "Enable"}
				</Button>
			</ListItem>
		);
	}

	const enableDisableCampaign = (campaign: CampaignType | null | undefined) => {
		if (!campaign) {
			return;
		}

		campaign.active = !campaign.active;

		putCampaign(campaign).then(setCampaign);
	}

	const updateStartDate = (campaign: CampaignType | null | undefined, date: Date | null) => {
		if (!campaign || !date) {
			return;
		}

		campaign.startDate = date;

		putCampaign(campaign).then(setCampaign);
	}

	const updateEndDate = (campaign: CampaignType | null | undefined, date: Date | null) => {
		if (!campaign || !date) {
			return;
		}

		campaign.endDate = date;

		putCampaign(campaign).then(setCampaign);
	}

	return (
		<Grid container spacing={4}>
			<Grid item container direction="row" wrap="nowrap" justifyContent="space-around">
				<Grid item>
					<List>
						<ListItem>
							<ListItemText
								primary="ID"
								secondary={campaign?.id}
							/>
						</ListItem>
						<ListItem>
							<TimePicker
								defaultDate={campaign?.startDate}
								onChange={(date) => updateStartDate(campaign, date)}
								label="Start Date"
							/>
							{campaign?.startDate.toDateString()}
						</ListItem>
						<ListItem>
							<TimePicker
								defaultDate={campaign?.endDate}
								onChange={(date) => updateEndDate(campaign, date)}
								label="End Date"
							/>
							{campaign?.endDate.toDateString()}
						</ListItem>
					</List>
				</Grid>
				<Grid item>
					<List>
						<ListItem>
							<ListItemText
								primary="Created"
								secondary={format(campaign?.created ?? 0, "yyyy/MM/dd kk:mm:ss")}
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								primary="Updated"
								secondary={format(campaign?.updated ?? 0, "yyyy/MM/dd kk:mm:ss")}
							/>
						</ListItem>
						{statusItem(campaign?.status)}
					</List>
				</Grid>
			</Grid>
			<Grid item style={{ width: "100%" }}>
				<CouponSpecList specs={campaign?.couponSpecs} />
			</Grid>
		</Grid>
	);
}