import { useState, ChangeEvent } from 'react';
import {
    Box,
    Collapse,
    Paper,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { by639_1 as codes } from 'iso-language-codes';

import { Device } from '../../api/device';
import DateLabel from '../general/DateLabel';
import PlatformIcon from '../general/PlatformIcon';

const useStyles = makeStyles((theme) => ({
	table: {
	  textDecoration: "none",
	},
	red: {
		color: "#f00",
	}
  }));

export const UserDevicesSelect = ({ currentDeviceId, devices, onSelectDevice }: { currentDeviceId: string, devices?: Device[], onSelectDevice: (deviceId: string) => void }) => {
    const [selectedDeviceId, setSelectedDeviceId] = useState<string>(currentDeviceId)

    if (!devices || devices.length === 0) {
        return <>No Devices</>;
    }

    const handleDeviceChange = (deviceId: string) => {
        setSelectedDeviceId(deviceId);
        onSelectDevice(deviceId)
    }

    return (
    <>
        <TableContainer component={Paper}>
            <Table aria-label="Users devices">
                <TableHead>
                    <TableRow>
                        <TableCell width={20}></TableCell>
                        <TableCell width={30}>Platform</TableCell>
                        <TableCell>Device</TableCell>
                        <TableCell>Client</TableCell>
                        <TableCell>Language</TableCell>
                        <TableCell width={140} align="right">Registered</TableCell>
                        <TableCell width={140} align="right">Last updated</TableCell>
                        <TableCell width={50} align="right">Move</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {devices.map((device) => {
                        if (device.device_id === "") {
                            return
                        }
                        return <UserDeviceRow key={'udr_'+device.device_id} device={device} selectedDeviceId={selectedDeviceId} onSelectDevice={handleDeviceChange} />
                    })
                    }
            </TableBody>
        </Table>
        </TableContainer>
    </>
    );
}

const UserDeviceRow = ({ device, selectedDeviceId, onSelectDevice }: { device: Device, selectedDeviceId: string, onSelectDevice: (deviceId: string) => void }) => {
	const classes = useStyles();
	const [open, setOpen] = useState<boolean>(false);

    const handleDeviceChange = (event: ChangeEvent<HTMLInputElement>) => {
        onSelectDevice(event.target.value)
    }

	return <>
        <TableRow key={'device_row_' + device.device_id} className={classes.table} hover>
				<TableCell>
					{device.device_id.length !== 0 && 
					<IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
					{open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
					</IconButton>}
				</TableCell>
				<TableCell>
					{(device && device.user_agent && device.user_agent.platform) && 
					<PlatformIcon platform={device.user_agent.platform} />}
				</TableCell>
				<TableCell>
					<strong>
					{device.user_agent.model && 
						<>
						{device.user_agent.manufacturer && <>{device.user_agent.manufacturer} </>}
						{device.user_agent.model && <>{device.user_agent.model}<br/></>}
						{device.user_agent.os_version && <>{device.user_agent.os_version}<br/></>}
						{device.user_agent.os_build && <>{device.user_agent.os_build}</>}
						</>
					}
					{!device.user_agent.model && <>Unknown device</>}
					</strong>
				</TableCell>
				<TableCell>
					<strong>
						{device.user_agent.app_name &&
						<span>
							{device.user_agent.app_name && <><span>{device.user_agent.app_name}</span> </>}
							{device.user_agent.app_version && <><span>v{device.user_agent.app_version}</span></>}
							{device.user_agent.app_build && <> <span>(Build {device.user_agent.app_build})</span><br/></>}
						</span>}
						{!device.user_agent.app_name &&
							<span>
								Unknown client
								<br/>
							</span>
						}
					</strong>								
				</TableCell>
				<TableCell>
				{codes[device.language].name}
				</TableCell>
				<TableCell align="right">
					<DateLabel inputDate={device.created} />
				</TableCell>
				<TableCell align="right">
					<DateLabel inputDate={device.updated} />
				</TableCell>
				<TableCell align="right">
                    <Radio
                        key={'rdio_'  + device.device_id}
                        value={device.device_id}
                        checked= {selectedDeviceId === device.device_id}
                        onChange={handleDeviceChange}
                    />
				</TableCell>
        </TableRow>
        {device.device_id.length !== 0 && 
			<TableRow key={"usr.coll_" + device.device_id}>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#f5f5f5" }} colSpan={8}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={3}>
							<p><strong>Device ID</strong></p> 
							{device.device_id}
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>} 
			</>;
}