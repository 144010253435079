import React, { useEffect, useState, Dispatch, SetStateAction, useRef } from "react";
import Container from "@material-ui/core/Container";
import Select from '@material-ui/core/Select';
import { MenuItem } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { TextField } from "@material-ui/core";

export const DurationPicker = ({secs}: {secs?:number}) => {
	const [days, setDays] = useState<number>(0);
	const [hours, setHours] = useState<number>(0);
	const [minutes, setMinutes] = useState<number>(0);
	const [seconds, setSeconds] = useState<number>(0);
	const [total, setTotal] = useState<number>(0);

	useEffect(()=>{

		let secVal = secs ? secs : 0;

		let d = Math.trunc(secVal / (24 * 60 * 60));
		if (d > 0) {
			secVal -= (d * 24 * 60 * 60);
		}
		setDays(d);

		let h = Math.trunc(secVal / (60 * 60));
		if (h > 0) {
			secVal -= (h * 60 * 60);
		}
		setHours(h);

		let m = Math.trunc(secVal / 60);
		if (m > 0) {
			secVal -= (m * 60);
		}
		setMinutes(m);

		let s = secVal;
		setSeconds(s);

	},[secs]);

	useEffect(()=>{
		let calculatedValue : number = 0;
		calculatedValue += (days * 24 * 60 * 60);
		calculatedValue += (hours * 60 * 60);
		calculatedValue += (minutes * 60);
		calculatedValue += (seconds);
		
		setTotal(calculatedValue);

	}, [days,hours,minutes,seconds]);

	const onChangeValue = (label:string, val:number) => {
		switch (label.toLowerCase()) {
			case "days": setDays(val); break;
			case "hours": setHours(val);  break;
			case "minutes": setMinutes(val);  break;
			case "seconds": setSeconds(val);  break;
			default:
				break;
		}
	}

	return <>
			<Box display="flex" alignItems="center">
			<RowItems label="Days" start={0} end={120} selected={days} onChangeValue={onChangeValue} />
			<RowItems label="Hours" start={0} end={23} selected={hours} onChangeValue={onChangeValue} />
			<RowItems label="Minutes" start={0} end={59} selected={minutes} onChangeValue={onChangeValue} />
			<RowItems label="Seconds" start={0} end={59} selected={seconds} onChangeValue={onChangeValue} />
			</Box>
			</>;
}

export const RowItems = ({start, end, selected, label, onChangeValue}: 
						{	
							start: number, 
							end : number, 
							selected: number,
							label: string,
							onChangeValue: ((label:string,val:number)=>void),					
						}) => {

	const changeValue = (newValue:any) => {
		let val = parseInt(newValue);
		onChangeValue(label, val);
	}

	let rows = [];
	let menuItems = [];
	for (var i = start; i <= end; i++) {
		rows.push(<option key={i} value={i}>{i}</option>);
		menuItems.push(<MenuItem value={i}>{i}</MenuItem>);
	}
	/*return <><select 
				value={selected} 
				onChange={(event)=>changeValue(event.target.value)}>
				<option disabled>{label}</option>
				{rows}
			</select></>;*/

	return <>
	 		<Box margin={1} width={50}>
				<FormControl fullWidth>
					<InputLabel id={label.toLowerCase() + "-select-label"}>{label}</InputLabel>
					<Select
						labelId={label.toLowerCase() + "-select-label"}
						id={label.toLowerCase() + "-select"}
						value={selected}
						label={label}
						variant="standard"
						onChange={(event)=>changeValue(event.target.value)}>
						{menuItems}
					</Select>
				</FormControl>
			</Box>
			</>;
}