import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import { Color as AlertColor } from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { sendNotification } from '../../api/notifications';
import { Device } from "../../api/device";

interface NotificationTestModalProps {
	isOpen: boolean;
	device?: Device;
	onClose: Function;
}

export const NotificationTestModal = (props: NotificationTestModalProps) => {
	const [response, setResponse] = useState<{message:string,severity:AlertColor}>({message:"",severity:"info"});
	
	const [inProgress, setInProgress] = useState<boolean>(false);
	const [title, setTitle] = useState<string>("");
	const [content, setContent] = useState<string>("");
	const [token, setToken] = useState<string>("");

	const handleClose = () => {
		props.onClose();
	};

	const handleTest = () => {

		if ((token.length === 0) || (title.length === 0) || (content.length === 0)) {
			return;
		}

		setInProgress(true);
		setResponse({message:"",severity:"info"});

		sendNotification(title,content,token)
		.then((data)=>{
			setInProgress(false);

			if (data.error) {
				setResponse({message:data.error,severity:"error"});
			} else {
				setResponse({message:"Successfully sent notification!",severity:"success"});
			}
		})
		.catch((err)=>{
			console.log("Error occured", err);
			setResponse({message:"An error occured while sending:" + err,severity:"error"});
			setInProgress(false);
		})
		
	}

	useEffect(() => {
		if (props.device) {
			setToken(props.device?.token);
		}
	},[props.device]);

	return (
		<>
		<Dialog open={props.isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Send push notification</DialogTitle>
			{response.message && 
			<Alert severity={response.severity}>{response.message}</Alert>}
			<DialogContent>
				<DialogContentText>					
					Instantly send a push notification to a single device.
					Ensure your device has a valid FCM token before sending.
				</DialogContentText>
				{inProgress &&
					<CircularProgress />
				}
				{!inProgress && 
					<Box>
						<TextField autoFocus margin="dense" id="title" label="Title" type="title" fullWidth value={title} onChange={(e)=>setTitle(e.target.value)} />
						<TextField margin="dense" minRows={3} placeholder="Content of your push notification here ..." id="content" label="Content" type="content" multiline fullWidth value={content} onChange={(e)=>setContent(e.target.value)} />
						<TextField margin="dense" placeholder="Enter a valid FCM token ..." id="token" label="Device token" type="token" fullWidth  value={token} onChange={(e)=>setToken(e.target.value)}/>
					</Box>
				}
			</DialogContent>
			<DialogActions>
				<Grid container justifyContent="space-between">
					<Grid item>
						<Button disabled={inProgress} variant="outlined" color="secondary" onClick={handleClose}>
							Cancel
						</Button>
					</Grid>
					<Grid item>
						<Button disabled={inProgress} variant="contained" onClick={handleTest} color="primary">
							Send notification
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
		</>
	);
}


export default NotificationTestModal;