import DateFnsUtils from '@date-io/date-fns';
import { Button, Box, Card, Checkbox, FormControlLabel, FormGroup, Grid, Typography, makeStyles } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useEffect, useState } from 'react';
import { FlexTicketOperator, RentalOperators, downloadOperatorReport, downloadSalesReport } from '../../api/reports_v2';

const useStyles = makeStyles((theme) => ({
    downloadButton: {
        marginTop: theme.spacing(2),
    }
}));

export const ReportsV2 = () => {
    const styles = useStyles();
    const now = new Date();
    const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    const [flexDownloadEnabled, setFlexDownloadEnabled] = useState<boolean>(true);
    const [salesDownloadEnabled, setSalesDownloadEnabled] = useState<boolean>(true);
    const [inProgress, setInProgress] = useState<boolean>(false);

    const [fromFlex, setFromFlex] = useState<Date | null>(lastMonth);
    const [toFlex, setToFlex] = useState<Date | null>(lastMonth);
    const [fromSales, setFromSales] = useState<Date | null>(lastMonth);
    const [toSales, setToSales] = useState<Date | null>(lastMonth);

    const [errOperator, setErrOperator] = useState<string>("");
    const [errSales, setErrSales] = useState<string>("");


    const checkBoxesFlex = [
        { label: "SL", enum: FlexTicketOperator.SL, checked: true },
        { label: "Västtrafik", enum: FlexTicketOperator.VastTrafik, checked: true },
    ]

    const checkBoxesSales = [
        { label: "SL", enum: FlexTicketOperator.SL, checked: true },
        { label: "Västtrafik", enum: FlexTicketOperator.VastTrafik, checked: true },
        { label: "Tier", enum: RentalOperators.TIER, checked: true },
        { label: "Voi", enum: RentalOperators.VOI, checked: true },
    ]


    const [flexCheckBoxes, setCheckBoxes] = useState(checkBoxesFlex);
    const [salesCheckBoxes, setSalesCheckBoxes] = useState(checkBoxesSales);

    useEffect(() => {
        const anyCheckedFlex = flexCheckBoxes.some((checkBox) => checkBox.checked);
        const flexDateValid = fromFlex !== null && toFlex !== null && fromFlex <= toFlex;
        setFlexDownloadEnabled(anyCheckedFlex && flexDateValid && !inProgress);

        const anyCheckedSales = salesCheckBoxes.some((checkBox) => checkBox.checked);
        const salesDateValid = fromSales !== null && toSales !== null && fromSales <= toSales;
        setSalesDownloadEnabled(anyCheckedSales && salesDateValid && !inProgress);
    }, [flexCheckBoxes, salesCheckBoxes, fromFlex, toFlex, fromSales, toSales, inProgress]);



    const handleFlexCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newCheckBoxes = flexCheckBoxes.map((checkBox) => {
            if (checkBox.label === event.target.name) {
                return { label: checkBox.label, enum: checkBox.enum, checked: event.target.checked }
            }
            return checkBox;
        })
        setCheckBoxes(newCheckBoxes);
    }

    const handleSalesCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newCheckBoxes = salesCheckBoxes.map((checkBox) => {
            if (checkBox.label === event.target.name) {
                return { label: checkBox.label, enum: checkBox.enum, checked: event.target.checked }
            }
            return checkBox;
        })
        setSalesCheckBoxes(newCheckBoxes);
    }

    const handleDownloadFlex = () => {
        setErrOperator("");
        if (fromFlex === null || toFlex === null) {
            return;
        }
        setInProgress(true);
        const from = new Date(fromFlex);
        // from first to first of next month (to date is exclusive)
        const to = new Date(toFlex);
        to.setMonth(to.getMonth() + 1);
        const operators = flexCheckBoxes.filter((checkBox) => checkBox.checked).map((checkBox) => checkBox.enum);
        const p = downloadOperatorReport(from, to, operators);
        p.catch((err) => {
            setErrOperator(err.message);
        }
        ).finally(() => setInProgress(false));
    }

    const handleDownloadSales = () => {
        setErrSales("");
        if (fromSales === null || toSales === null) {
            return;
        }
        setInProgress(true);
        const from = new Date(fromSales);
        // from first to first of next month (to date is exclusive)
        const to = new Date(toSales);
        to.setMonth(to.getMonth() + 1);

        const flexOperators: FlexTicketOperator[] = [];
        const rentalOperators: RentalOperators[] = [];

        salesCheckBoxes.filter((checkBox) => checkBox.checked).forEach((checkBox) => {
            if (Object.values(FlexTicketOperator).includes(checkBox.enum as FlexTicketOperator)) {
                flexOperators.push(checkBox.enum as FlexTicketOperator);
            }
            else if (Object.values(RentalOperators).includes(checkBox.enum as RentalOperators)) {
                rentalOperators.push(checkBox.enum as RentalOperators);
            }
        });

        const p = downloadSalesReport(from, to, flexOperators, rentalOperators);
        p.catch((err) => {
            setErrSales(err.message);
        }).finally(() => setInProgress(false));
    }

    return (
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={8} direction="column">
                    <Grid item xs={12}>
                        <Typography variant="h6">Reports</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <Box padding={2}>
                                <Typography variant="h6">Operators</Typography>
                                <Typography variant="body1">Operators report is reports based on activation date, which is when we issue a ticket from the operator (flex tickets)</Typography>
                                <br />
                                <FormGroup row>
                                    {flexCheckBoxes.map((checkBox) => (
                                        <FormControlLabel
                                            key={checkBox.label}
                                            control={
                                                <Checkbox
                                                    key={checkBox.label}
                                                    checked={checkBox.checked}
                                                    onChange={handleFlexCheckBoxChange}
                                                    name={checkBox.label}
                                                />
                                            }
                                            label={checkBox.label}
                                        />
                                    ))}
                                </FormGroup>
                                <Grid container direction="row" alignItems="flex-end" spacing={2}>
                                    <Grid item>
                                        <DatePicker
                                            variant="inline"
                                            openTo="month"
                                            margin="normal"
                                            views={["year", "month"]}
                                            label="From"
                                            value={fromFlex}
                                            onChange={setFromFlex}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <DatePicker
                                            variant="inline"
                                            openTo="month"
                                            margin="normal"
                                            views={["year", "month"]}
                                            label="To"
                                            value={toFlex}
                                            onChange={setToFlex}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleDownloadFlex}
                                        disabled={!flexDownloadEnabled}
                                        endIcon={<DownloadIcon />}
                                        className={styles.downloadButton}
                                    >
                                        Download
                                    </Button>
                                    <Typography variant="body2" color="error">{errOperator}</Typography>
                                </Grid>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <Box padding={2}>
                                <Typography variant="h6">Sales</Typography>
                                <Typography variant="body1">Sales report is reports based on the date when the ticket was purchased or when a rental was ended<br />This includes flex tickets, rentals, and credits</Typography>
                                <br />
                                <FormGroup row>
                                    {salesCheckBoxes.map((checkBox) => (
                                        <FormControlLabel
                                            key={checkBox.label}
                                            control={
                                                <Checkbox
                                                    checked={checkBox.checked}
                                                    onChange={handleSalesCheckBoxChange}
                                                    name={checkBox.label}
                                                />
                                            }
                                            label={checkBox.label}
                                        />
                                    ))}
                                </FormGroup>
                                <Grid container direction="row" alignItems="flex-end" spacing={2}>
                                    <Grid item>
                                        <DatePicker
                                            variant="inline"
                                            openTo="month"
                                            margin="normal"
                                            views={["year", "month"]}
                                            label="From"
                                            value={fromSales}
                                            onChange={setFromSales}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <DatePicker
                                            variant="inline"
                                            openTo="month"
                                            margin="normal"
                                            views={["year", "month"]}
                                            label="To"
                                            value={toSales}
                                            onChange={setToSales}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleDownloadSales}
                                        disabled={!salesDownloadEnabled}
                                        endIcon={<DownloadIcon />}
                                        className={styles.downloadButton}
                                    >
                                        Download
                                    </Button>
                                    <Typography variant="body2" color="error">{errSales}</Typography>
                                </Grid>
                            </Box>
                        </Card>
                    </Grid>

                </Grid>
            </MuiPickersUtilsProvider>
        </div>

    )
}