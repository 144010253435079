import { baseUrl } from "./config";
import { getToken } from "./index";

type ActiveRental = {
	id?: string;
	operator_id?: string;
	rental_id?: string;
	started_at?: string;
	vehicle_id?: string;
	user_id?: string;
};

export const getActiveRentals = async (): Promise<Array<ActiveRental>> => {
	return fetch(`${baseUrl}/api/v1/active_rentals`, {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	}).then((response) => response.json());
};

export const deleteActiveRental = async (rental_id: string) => {
	return fetch(`${baseUrl}/api/v1/active_rentals/${rental_id}?force=true`, {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
		method: "DELETE",
	}).then((response) => response.json());
};
