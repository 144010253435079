import { baseUrl } from "./config";
import { getToken } from "./index";
import { checkErrors } from "./utils";

export type FlexTicket = {
	id: string;
	user_id: string;
	created: number;
	activated: number;
	expires: number;
	deactivated: boolean;
	urgent_after: number;
	travel_periods_remaining: number;
	travel_periods_included: number;
	travel_periods: Array<FlexTicketTravelPeriod>;
	device?: FlexTicketDevice;
};

export type OperatorTicket = {
	issued : number;
	expires: number;
	ticket_id: string;
	type: string;
}

export type FlexTicketDevice = {
	id: string;
	platform: string;
	app_version: string;	
}

export type FlexTicketTravelPeriod = {
	period_started: number;
	period_ends: number;
	period_urgent_after: number;
	activated: number;
	tickets: Array<OperatorTicket>;
	tickets_issued: number;
}

export const getFlexTicket = async (userId: string, flexTicketId: string): Promise<FlexTicket | undefined> => {
	if (!userId) throw new Error(`No userId passed to getFlexTicket`);
	if (!flexTicketId) throw new Error(`No flexTicketId passed to getFlexTicket`);

	
	return fetch(`${baseUrl}/api/v2/users/${userId}/flex_tickets/${flexTicketId}`, {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
		.then(checkErrors)
		.then((response): Promise<FlexTicket> => response.json());
};

export const expireFlexTicket = async (userId: string, flexTicketId: string): Promise<FlexTicket | undefined> => {
	if (!userId) throw new Error(`No userId passed to expireFlexTicket`);
	if (!flexTicketId) throw new Error(`No flexTicketId passed to expireFlexTicket`);

	
	return fetch(`${baseUrl}/api/v2/users/${userId}/flex_tickets/${flexTicketId}/expire`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
		.then(checkErrors)
		.then((response): Promise<FlexTicket> => response.json());
};

export const moveFlexTicket = async (userId: string, flexTicketId: string, deviceId: string, toDeviceId: string): Promise<FlexTicket | undefined> => {
	if (!userId) throw new Error(`No userId passed to moveFlexTicket`);
	if (!flexTicketId) throw new Error(`No flexTicketId passed to moveFlexTicket`);
	if (!deviceId) throw new Error(`No deviceId passed to moveFlexTicket`);
	if (!toDeviceId) throw new Error(`No toDeviceId passed to moveFlexTicket`);
	
	return fetch(`${baseUrl}/api/v2/users/${userId}/flex_tickets/${flexTicketId}/transfer`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			from_device_id: deviceId,
			to_device_id: toDeviceId,
		}),
	})
		.then(checkErrors)
		.then((response): Promise<FlexTicket> => response.json());
}
