import React, { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import {
    getAppVersion
} from "../api/app";

export function Overview() {

	const [loaded, setLoaded] = useState<boolean>(false);
	
	const getAppVersionInfo = () => {
		getAppVersion()
			.then((data)=>{
				console.log(data);
			})
			.then(() => setLoaded(true));
	};
	
	useEffect(() => {
		getAppVersionInfo();
	}, []);

	return <Container>
				<div>
					Do something fancy here
					{loaded &&
						<>
								<br/>
								Welcome
						</>}
				</div>
			</Container>;
}

export default Overview;