import { baseUrl } from "./config"
import { checkErrors } from "./utils";
import { getToken } from "./index";

export enum CouponApplyRule {
	AutoAll = "auto_all",
	AutoNewUsers = "auto_new_users",
	Code = "code",
}

export enum CouponDiscountType {
	SingleAmount = "single_amount",
	TotalAmount = "total_amount",
	Percent = "percent",
}

export type CouponDiscount = {
	discountType: CouponDiscountType;
	amount: number;
	currency: string;
	scaleDivider: number;
	percent: number;
}

export type PriceDetails = {
	currency: string;
	value: number;
	price: number;
	unit: string;
	type: string;
	scaleDivider: number;
}

export type CouponDto = {
	id: string;
	specId: string;
	validFrom: number;
	validUntil: number;
	discount: PriceDetails;
	isRedeemed: boolean;
	redeemed: number;
	orderId: string;
	created: number;
	updated: number;
}

export type Coupon = {
	id: string;
	specId: string;
	validFrom: Date;
	validUntil: Date;
	discount: PriceDetails;
	isRedeemed: boolean;
	redeemed: Date;
	orderId: string;
	created: Date;
	updated: Date;
}

export type CouponSpecDto = {
	id: string;
	campaignId: string;
	code: string;
	operator: string;
	transportationType: string;
	limit: number;
	applyRule: CouponApplyRule;
	discount: CouponDiscount;
	created: number;
	updated: number;
};

export type CouponSpec = {
	id: string;
	campaignId: string;
	code: string;
	operator: string;
	transportationType: string;
	limit: number;
	applyRule: CouponApplyRule;
	discount: CouponDiscount;
	created: Date;
	updated: Date;
}

export type CampaignDto = {
	id: string;
	startDate: number;
	endDate: number;
	active: boolean;
	couponSpecs: Array<CouponSpecDto>;
	created: number;
	updated: number;
};

export type Campaign = {
	id: string;
	startDate: Date;
	endDate: Date;
	active: boolean;
	status: string;
	couponSpecs: Array<CouponSpec>;
	created: Date;
	updated: Date;
}

export const getCampaigns = async (): Promise<Array<Campaign>> => {
	const requestUrl = new URL("/api/v1/campaigns", baseUrl);
	return fetch(requestUrl.toString(), {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
		.then(checkErrors)
		.then((response) => {
			return response.json()
				.then((campaigns: Array<CampaignDto>) => {
					let result: Array<Campaign> = [];

					for (let campaign of campaigns) {
						result.push(campaignDtoToModel(campaign));
					}

					return result;
				});
		});
}

export const getCampaign = async (id: string): Promise<Campaign> => {
	if (!id) {
		throw new Error("No ID supplied to getCampaign");
	}

	const requestUrl = new URL(`/api/v1/campaigns/${id}`, baseUrl);
	return fetch(requestUrl.toString(), {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
		.then(checkErrors)
		.then((response) =>
			response.json().then((campaign: CampaignDto) =>
				campaignDtoToModel(campaign)));
}

export const putCampaign = async (campaign: Campaign): Promise<Campaign> => {
	const requestUrl = new URL(`/api/v1/campaigns`, baseUrl);
	return fetch(requestUrl.toString(), {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
		body: JSON.stringify(campaignModelToDto(campaign)),
	})
		.then(checkErrors)
		.then((response) =>
			response.json().then((campaign: CampaignDto) =>
				campaignDtoToModel(campaign)));
}

export const postCampaign = async (campaign: Campaign): Promise<Campaign> => {
	const requestUrl = new URL(`/api/v1/campaigns`, baseUrl);
	return fetch(requestUrl.toString(), {
		method: "POST",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
		body: JSON.stringify(campaignModelToDto(campaign)),
	})
		.then(checkErrors)
		.then((response) =>
			response.json().then((campaign: CampaignDto) =>
				campaignDtoToModel(campaign)));
}

export const getCouponSpec = async (id: string): Promise<CouponSpec> => {
	const requestUrl = new URL(`/api/v1/coupon_specs/${id}`, baseUrl);
	return fetch(requestUrl.toString(), {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
		.then(checkErrors)
		.then((response) =>
			response.json().then((spec: CouponSpecDto) =>
				couponSpecDtoToModel(spec)));
}

export const putCouponSpec = async (spec: CouponSpec): Promise<CouponSpec> => {
	const requestUrl = new URL(`/api/v1/campaigns/${spec.campaignId}/coupon_specs`, baseUrl);
	return fetch(requestUrl.toString(), {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
		body: JSON.stringify(couponSpecModelToDto(spec)),
	})
		.then(checkErrors)
		.then((response) =>
			response.json().then((spec: CouponSpecDto) =>
				couponSpecDtoToModel(spec)));
}

export const getCouponsForCouponSpec = async (id: string, from: Date, to: Date): Promise<Array<Coupon>> => {
	const requestUrl = new URL(`/api/v1/coupon_specs/${id}/coupons?from=${from.toISOString()}&to=${to.toISOString()}`, baseUrl);
	return fetch(requestUrl.toString(), {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
		.then(checkErrors)
		.then((response) =>
			response.json().then((coupons: Array<CouponDto>) => {
				let result: Array<Coupon> = [];

				for (let coupon of coupons) {
					result.push(couponDtoToModel(coupon));
				}

				return result;
			}));
}

const campaignDtoToModel = (campaign: CampaignDto): Campaign => {
	console.log("campaignDtoToModel", campaign);
	const start = new Date(campaign.startDate * 1000);
	const end = new Date(campaign.endDate * 1000);

	let status = "inactive";
	const now = new Date();
	if (start <= now && now < end && campaign.active) {
		status = "active";
	} else if (now < start && campaign.active) {
		status = "upcoming";
	} else if (start <= now && now < end && !campaign.active) {
		status = "disabled";
	} else if (end <= now) {
		status = "expired";
	}

	const created = new Date(campaign.created * 1000);
	let updated = new Date(campaign.updated * 1000);
	if (campaign.updated === 0) {
		updated = created;
	}

	let specs: Array<CouponSpec> = [];
	for (let spec of campaign.couponSpecs) {
		specs.push(couponSpecDtoToModel(spec));
	}

	return {
		id: campaign.id,
		startDate: start,
		endDate: end,
		active: campaign.active,
		status: status,
		couponSpecs: specs,
		created: created,
		updated: updated,
	};
}

const couponSpecDtoToModel = (spec: CouponSpecDto) => {
	const created = new Date(spec.created * 1000);
	let updated = new Date(spec.updated * 1000);
	if (spec.updated === 0) {
		updated = created;
	}

	return {
		id: spec.id,
		campaignId: spec.campaignId,
		code: spec.code,
		operator: spec.operator,
		transportationType: spec.transportationType,
		limit: spec.limit,
		applyRule: spec.applyRule,
		discount: spec.discount,
		created: created,
		updated: updated,
	};
}

const couponDtoToModel = (coupon: CouponDto): Coupon => {
	const validFrom = new Date(coupon.validFrom * 1000);
	const validUntil = new Date(coupon.validUntil * 1000);
	const redeemed = new Date(coupon.redeemed * 1000);
	const created = new Date(coupon.created * 1000);
	let updated = new Date(coupon.updated * 1000);
	if (coupon.updated === 0) {
		updated = created
	}

	return {
		id: coupon.id,
		specId: coupon.specId,
		validFrom: validFrom,
		validUntil: validUntil,
		discount: coupon.discount,
		isRedeemed: coupon.isRedeemed,
		redeemed: redeemed,
		orderId: coupon.orderId,
		created: created,
		updated: updated,
	}
}

const campaignModelToDto = (campaign: Campaign): CampaignDto => {
	return {
		id: campaign.id,
		startDate: Math.floor(campaign.startDate.getTime() / 1000),
		endDate: Math.floor(campaign.endDate.getTime() / 1000),
		active: campaign.active,
		couponSpecs: [],
		created: Math.floor(campaign.created.getTime() / 1000),
		updated: Math.floor(campaign.created.getTime() / 1000),
	};
}

const couponSpecModelToDto = (spec: CouponSpec): CouponSpecDto => {
	return {
		id: spec.id,
		campaignId: spec.campaignId,
		code: spec.code,
		operator: spec.operator,
		transportationType: spec.transportationType,
		limit: spec.limit,
		applyRule: spec.applyRule,
		discount: spec.discount,
		created: spec.created.getTime() / 1000,
		updated: spec.updated.getTime() / 1000,
	};
}