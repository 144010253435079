import firebase from "firebase/app";
import { getFirebaseConfig } from "./config";
import { ContextUser } from "../context/authContext";

import "firebase/auth";

let USER: firebase.User | null = null;

export const getToken = async () => {
	return new Promise((resolve, reject) => {
		USER?.getIdToken().then((result) => {
			resolve(result);
		});
	});
};

export const initApp = async (onStateChange: (user: firebase.User | null) => void) => {
	const firebaseConfig = await getFirebaseConfig();

	try {
		firebase.initializeApp(firebaseConfig);
	} catch (error) {
		console.log(error);
	}

	return new Promise((resolve, reject) => {
		firebase.auth().onAuthStateChanged((returnedUser) => {
			if (onStateChange) {
				onStateChange(returnedUser);
			}

			resolve(true);
		});
	});
};

export const updateUser = (user: firebase.User): ContextUser => {
	USER = user;
	if (user === null) {
		return {
			loggedIn: false,
			userData: null,
		};
	} else if (user.isAnonymous === true) {
		return {
			userData: user,
			loggedIn: true,
		};
	} else if (user.providerData && user.providerData[0]) {
		return {
			userData: user,
			loggedIn: true,
		};
	}

	return {
		userData: null,
		loggedIn: false,
	};
};
