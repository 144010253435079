import { baseUrl } from "./config";
import { getToken } from "./index";
import { checkErrors } from "./utils";

export type LastUpdateDateResponse = {
	last_updated_date: string;
	last_updated_by: string;
	is_cleared: boolean;
};

export type ReInitResponse = {
	is_cleared: boolean;
	last_updated_date: string;
	last_updated_by: string;
}


export const getLastUpdateDate = async (): Promise<LastUpdateDateResponse> => {
	
	return fetch(`${baseUrl}/api/v1/cacheutil/lastupdatedate`, {
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
		
	})
	.then(checkErrors)
	.then((response) => response.json())
	.catch(e => {
		throw e
	})

};


export const reinitialise = async (): Promise<ReInitResponse> => {
	
	return fetch(`${baseUrl}/api/v1/cacheutil/reinit`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
	.then(checkErrors)
	.then((response) => response.json())
	.catch((e) => {
		throw e
	})
};

export const clear = async (): Promise<ReInitResponse> => {
	
	return fetch(`${baseUrl}/api/v1/cacheutil/clear`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${await getToken()}`,
		},
	})
	.then(checkErrors)
	.then((response) => response.json())
	.catch((e) => {
		throw e
	})
};
