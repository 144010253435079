import React from "react";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import NotificationsActive from "@material-ui/icons/NotificationsActive";
import ImageIcon from "@material-ui/icons/Image";
import Button from '@material-ui/core/Button';
import PlatformIcon from "../general/PlatformIcon";
import { makeStyles } from "@material-ui/core/styles";

import { Link } from "react-router-dom";

import { Notification } from "../../api/notifications";

import { DateLabel } from "../general/DateLabel";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	tableRow: {
		textDecoration: "none",
	},
	imageNotification: {
		backgroundColor: "#99f",
	},
	textNotification: {
		backgroundColor: "#5b5",
	},
	activeRow: {
		textDecoration: "none",
		animationDuration: "2s",
		animationDirection: "normal",
		animationIterationCount: "infinite",
		animationName: "$blink",
		animationTimingFunction: "ease",
	},
	"@keyframes blink": {
		"0%": {
			background: "rgba(255, 255, 255, 1.0)",
		},
		"50%": {
			background: "rgba(255, 255, 20, 0.2)",
		},
		"100%": {
			background: "rgba(255, 255, 255, 1.0)",
		}
	}
}));

interface NotificationsListItemProps {
	notification: Notification;
	onCancel: (notification: Notification) => void;
}

export const NotificationsListItem = (props: NotificationsListItemProps) => {
	const classes = useStyles();

	const isDateSet = (input:number | undefined): boolean => {
		if (!input) return false;
		if (input === 0) return false;
		return true;
	}

	const isActive = (isActive:boolean | undefined): string => {
		return isActive ? classes.activeRow : classes.tableRow;
	}

	const isCancellable = (notification: Notification): boolean => {
		if (notification) {
			console.log(`${notification.title}: ${notification.push_notification_state}`)
		}
		return notification && (notification.push_notification_state === 0 || notification.push_notification_state === 1);
	}

	const checkArray = (input:string[] | undefined): string[] =>  {
		if ((input === null) || (input === undefined)) {
			return [];
		}

		return input;
	}

	const getVersionTarget = (notification: Notification) => {
		if ((!notification.target_version) || (!notification.target_version_comparator)) {
			return <></>;
		}

		let mod:string = "";
		switch (notification.target_version_comparator) {
			case "gte": mod = ">="; break;
			case "lte": mod = "<="; break;
			default: mod = ""; break;
		}
		
		let label:string = mod + " " + notification.target_version;

		return <Grid item><Chip color="secondary" size="small" label={label} /></Grid>;
		
	}

	if (!props.notification) {
		return <></>;
	}


	const stateDesc = (notification: Notification): string => {
		if (!notification) {
			return "";
		}

		switch (notification.push_notification_state) {
			case 0:
				return "Idle";
			case 1:
				return "Active";
			case 2:
				return "Sent";
			case 3:
				return "Cancelled";
			default:
				return "Unknown"
		}
	};

	return <>
			<TableRow 
				hover 
				component={Link} 
				to={`/notifications/${props.notification.notification_id}`}
				className={isActive(props.notification.is_active)}>
				<TableCell width={30}>
					{props.notification.image_url &&
						<Avatar className={classes.imageNotification}><ImageIcon /></Avatar>
					}
					{!props.notification.image_url &&
						<Avatar className={classes.textNotification}><NotificationsActive /></Avatar>
					}
				</TableCell>
				<TableCell>{props.notification.title}</TableCell>
				
				<TableCell width={130}>
					<Grid container spacing={1}>
					{checkArray(props.notification.device).map((platform)=>{
						if (!platform) {
							return <></>;
						}
						return <Grid item>
								<PlatformIcon platform={platform} />
							</Grid>;
						})
					}
					{getVersionTarget(props.notification)}
					</Grid>
					
				</TableCell>
				<TableCell>
					<Grid container spacing={1}>
					{checkArray(props.notification.delivery).map((delivery)=>{
						if (!delivery) {
							return <></>;
						}
						return <Grid item>
							<Chip size="small" label={delivery} />
							</Grid>;
					})}
					</Grid>
				</TableCell>
				<TableCell>
					<Typography>{stateDesc(props.notification)}</Typography>
				</TableCell>
				<TableCell>
					{isDateSet(props.notification.start_at) && 
						<DateLabel inputDate={props.notification.start_at} />
					} - {isDateSet(props.notification.end_at) &&
						<DateLabel inputDate={props.notification.end_at} />
					}
				</TableCell>
				<TableCell width={140}>
					{isDateSet(props.notification.created) && 
						<DateLabel inputDate={props.notification.created} />
					}
				</TableCell>
				<TableCell>
					{isCancellable(props.notification) &&
						<Button id="cancel"  variant="outlined" color="primary" onClick={(e) => {
							// Stop the event from bubbling up
							e.stopPropagation();
							e.preventDefault();
							props.onCancel(props.notification);
						}}>Cancel</Button>
					}
				</TableCell>
			</TableRow>
			</>;
}

export default NotificationsListItem;