import React, { useEffect, useState } from "react";

import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, IconButton, Toolbar } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Loader } from "../loader";
import { Chip } from "@material-ui/core";

import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar"
import EditIcon from "@material-ui/icons/Edit";

import { getProducts, Product } from "../../api/products";
import { OperatorIcon } from "../operator_icon";
import FullIcon from "@material-ui/icons/Lens";
import ReducedIcon from "@material-ui/icons/PanoramaFishEye";


const useSupportRequestStyles = makeStyles((theme) => ({
	caseNumber: {
		paddingLeft: `${theme.spacing(1)}px`,
	},
	tableRow: {
		textDecoration: "none",
	}
}));



const useListSupportRequestsStyles = makeStyles((theme) => ({
	filters: {
		marginTop: `${theme.spacing(1)}px`,
		padding: `${theme.spacing(2)}px`,
		backgroundColor: "#f0f0f0",
	},
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(5),
	}
}));


export const ListProducts = () => {
	const classes = useListSupportRequestsStyles();
	const [products, setProducts] = useState<Product[]>([]);
	const [loaded, setLoaded] = useState<boolean>(false);

	useEffect(() => {
		getProducts()
			.then((prods) => {
				console.log("FETCH PRODUCTS", prods);
				let sortedProds = prods.sort((a: Product, b: Product) => {
					if (a.group > b.group) { return 1; }
					if (a.group < b.group) { return -1; }
					return 0;
				})
				setProducts(sortedProds);
			})
			.then(() => setLoaded(true));
	}, []);

	if (loaded) {
		return (
			<>
				<Toolbar>
					<Typography variant="h6">Products</Typography>
				</Toolbar>
				<Paper className={classes.paper}>
					<Table aria-label="Products">
						<TableHead>
							<TableRow>
								<TableCell></TableCell>
								<TableCell>Type</TableCell>
								<TableCell>Name</TableCell>
								<TableCell>Group</TableCell>
								<TableCell align="right">Action</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{products.map((product) =>
								<ProductItem product={product} />
							)}
						</TableBody>
					</Table>
				</Paper>
			</>
		);
	}

	return <Loader />;
};

export const ProductItem = ({ product }: { product: Product }) => {
	const classes = useSupportRequestStyles();

	return (
		<TableRow hover component={Link} to={`/products/${product.product_id}`} className={classes.tableRow}>
			<TableCell width={30}>
				<Avatar>
					<OperatorIcon operator={product.operator?.code ? product.operator.code : ""} />
				</Avatar>
			</TableCell>
			<TableCell width={50}>
				<ProductType product={product} />
			</TableCell>
			<TableCell>
				<Typography variant="body2">{product.product_description?.title_en}</Typography>
				<Typography variant="caption">
					{product.name}
				</Typography>
			</TableCell>
			<TableCell>
				<Typography variant="inherit">{product.group}</Typography>
			</TableCell>
			<TableCell align="right">
				<IconButton color="primary" aria-label="Edit product">
					<EditIcon />
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export const ProductType = ({ product }: { product: Product }) => {

	let icon = <FullIcon />

	if (product.name.includes("reduced")) {
		icon = <ReducedIcon />
	}

	if (product.type === "flexticket") {
		return <Chip
			icon={icon}
			color="primary"
			label={product.type} />
	}

	return <Chip
		color="secondary"
		icon={icon}
		label={product.type} />
}