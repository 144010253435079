import { MouseEvent } from "react"
import { useHistory } from "react-router-dom";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
} from "@material-ui/core";
import {
	CheckCircleOutline as ActiveIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import * as Colors from "@material-ui/core/colors";

import { Contest, ContestCodeStatus } from "../../api/contest";
import { Product } from "../../api/products";

type ContestListProps = {
	contests: Array<Contest>;
	products: Array<Product>;
}

const useStyles = makeStyles({
	table: {
		minWidth: 650,
	}
});

export const ContestList = ({ contests, products }: ContestListProps) => {
	const classes = useStyles();
	const history = useHistory();

	const handleContestClick = (event: MouseEvent<HTMLTableRowElement |HTMLAnchorElement>, id: string) => {
		if (!(event.target instanceof HTMLAnchorElement)) {
			history.push(`/v2/contests/${id}`)
		}
	}

	type codeNumbers = {
		total: number,
		delivered: number,
		used: number,
		error: number,
	};
	const getCodeNumbers = (row: Contest): codeNumbers => {
		const numbers: codeNumbers = {
			total: row.codes.length,
			delivered: 0,
			used: 0,
			error: 0,
		};

		for (const code of row.codes) {
			if (code.status == ContestCodeStatus.DeliveredContestCode) {
				numbers.delivered += 1;
			}
			if (code.status == ContestCodeStatus.UsedContestCode) {
				numbers.used += 1;
			}
			if (code.status == ContestCodeStatus.ErrorContestCode) {
				numbers.error += 1;
			}
		}

		return numbers;
	}

	const formatCodeNumbers = (row: Contest) => {
		const numbers = getCodeNumbers(row);
		if (numbers.error > 0) {
			return (
				<TableCell>
					<Typography style={{color: Colors.red[600]}}>
						{`${numbers.error} error(s)`}
					</Typography>
					{`${numbers.total} / ${numbers.delivered} / ${numbers.used}`}
				</TableCell>
			);
		}

		return (
			<TableCell>
				{`${numbers.total} / ${numbers.delivered} / ${numbers.used}`}
			</TableCell>
		)
	}

	const productMap = new Map(products.map(p => [p.product_id, p]))
	const productName = (product_id: string): string => {
		const product = productMap.get(product_id)
		if (product == null) {
			return product_id
		}

		return `${product.product_description.price_category_single_en} ${product.product_description.title_en}`;
	}

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell/>
						<TableCell>
							ID
						</TableCell>
						<TableCell>
							Codes
							<Typography color="textSecondary">
								Total / Delivered / Used
							</Typography>
						</TableCell>
						<TableCell>
							Reward
						</TableCell>
						<TableCell>
							Created / Modified
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{contests.map((contest) => (
						<TableRow
							hover
							onClick={(e) => handleContestClick(e, contest.id)}
							key={contest.id}
							style={{ cursor: "pointer" }}
						>
							<TableCell>
								{contest.active ? (<ActiveIcon style={{ color: "green" }} />) : ""}
							</TableCell>
							<TableCell>
								{contest.id}
							</TableCell>
							{formatCodeNumbers(contest)}
							<TableCell>
								{productName(contest.reward_product_id)}
							</TableCell>
							<TableCell>
								{format(contest.created, "yyyy/MM/dd kk:mm:ss")}
								<br/>
								<Typography color="textSecondary">
									{format(contest.updated, "yyyy/MM/dd kk:mm:ss")}
								</Typography>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}