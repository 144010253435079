import { baseUrl } from "./config";
import { getToken } from "./index";


export const getLogs = async (start: Date, end: Date, condition: string, terms: string): Promise<LogResult> => {

	//beginning of day
	start.setHours(0, 0, 0, 0);
	// end of day
	end.setHours(23, 59, 59, 999);
	// convert to RFC3339
	let startDate = start.toISOString();
	let endDate = end.toISOString();

    return fetch(`${baseUrl}/api/v2/logs/query?start=${startDate}&end=${endDate}&q=${terms}&c=${condition}`, {
        headers: {
            "Authorization": `Bearer ${await getToken()}`
        }
    	})
		.then(response => response.json());
}

export type LogResult = {
	entries?: Entry[];
}

export type Entry = {
	timestamp: number;
	request_id?: string;
	message?: string;
	line?: number;
	file?: string;
	module?: string;
	user_agent?: UserAgent;
}

export type UserAgent = {
	app_agent?: string;
	app_version?: string;
	app_build?: string;
	app_name?: string;
	os_build?: string;
	os_version?: string;
	model?: string;
	platform?: string;
	manufacturer?: string;
}
