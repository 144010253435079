import React, { useRef, useEffect, useState } from "react";
import mapboxgl, { LngLatLike } from "mapbox-gl";
import "./map.css";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const Map = (props: { points: Array<{ type: string, desc: string, lng: number; lat: number; }>, layers: any }) => {
	const mapContainerRef = useRef<HTMLDivElement>(null);

	const [lng, setLng] = useState<number>(props.points[0].lng);
	const [lat, setLat] = useState<number>(props.points[0].lat);
	const [zoom, setZoom] = useState<number>(12);

	useEffect(() => {
		setLat(props.points[0].lat);
		setLng(props.points[0].lng);
	}, [props]);

	// Initialize map when component mounts
	useEffect(() => {
		if (!mapContainerRef.current) return;

		const map = new mapboxgl.Map({
			container: mapContainerRef.current,
			style: "mapbox://styles/nobtech/ck665wqag0hwy1io5idr38869",
			center: [lng, lat],
			zoom: zoom,
			accessToken:
				"pk.eyJ1Ijoibm9idGVjaCIsImEiOiJja2l1MnlsejIxMG14MnhzYzZvZ3B1ZzMwIn0.2dxfJ1v9smbNosudOlbobA",
		});

		// Add navigation control (the +/- zoom buttons)
		map.addControl(new mapboxgl.NavigationControl(), "top-right");

		// map.loadImage(
		//     'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
		//     function (error, image) {
		//         if (error) throw error;
		//         map.addImage('custom-marker', image)
		//     }
		// );

		/*
		map.on("move", () => {
			setLng(+map.getCenter().lng.toFixed(4));
			setLat(+map.getCenter().lat.toFixed(4));
			setZoom(+map.getZoom().toFixed(2));
		});
		*/

		map.on("load", () => {
			map.removeLayer("tracks");
			map.removeLayer("public_transport_bus");
			map.removeLayer("public_transport");
			map.removeLayer("rental_car");
			map.removeLayer("rental_bicycle");
			map.removeLayer("entrances");
			map.removeLayer("bike_pump");

			if (props.layers != null) {
				props.layers.forEach((p: string) => {
					map.setLayoutProperty(p, "visibility", "visible");
				});
			}


			var geojson: GeoJSON.FeatureCollection = {
				type: 'FeatureCollection',
				features: [],
			};



			var bbox: [[number, number], [number, number]] = [[0, 0], [0, 0]]
			var index = 0;
			props.points.forEach((p) => {

				var feature: GeoJSON.Feature = {
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: [p.lng, p.lat],
					},
					properties: {
						icon: p.type,
						title: '',
						description: p.desc,
					}
				}

				geojson["features"].push(feature);

				bbox[index++] = [p.lng, p.lat];

			});

			map.addSource('scootermap', {
				'type': 'geojson',
				'data': geojson,
			});

			// Add a layer to use the image to represent the data.
			map.addLayer({
				'id': 'points',
				'type': 'symbol',
				'source': 'scootermap', // reference the data source
				'layout': {
					'icon-image': "{icon}", // reference the image
					'icon-size': 0.75,
					'text-field': ['get', 'description'],
					'text-variable-anchor': ['left'],
					'text-radial-offset': 1.5,
					'text-justify': 'auto',
				},
				'paint': {
					'text-color': '#ffffff',
				},
			});
			map.fitBounds(bbox, { padding: 100 });
		});

		// Clean up on unmount
		return () => map.remove();
	}, [lat, lng]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div>
			<div className="sidebarStyle">
				<div>
					Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
				</div>
			</div>
			<div className="map-container" ref={mapContainerRef} />
		</div>
	);
};

export default Map;
