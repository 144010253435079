import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import AppsIcon from "@material-ui/icons/Apps";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import GridOnIcon from "@material-ui/icons/GridOn";
import HistoryIcon from "@material-ui/icons/History";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import DisplaySettingsIcon from '@material-ui/icons/Settings';
import RedeemIcon from "@material-ui/icons/Redeem";
import StorageIcon from "@material-ui/icons/Storage";
import TrainIcon from "@material-ui/icons/Train";
import React from "react";
import { Link } from "react-router-dom";

export const DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme) => ({
	drawer: {
		width: DRAWER_WIDTH,
		flexShrink: 0,
	},
	drawerPaper: {
		width: DRAWER_WIDTH,
	},
}));

export const Menu = () => {
	const classes = useStyles();

	return (
		<Drawer
			variant="permanent"
			anchor="left"
			className={classes.drawer}
			classes={{ paper: classes.drawerPaper }}>

			<Toolbar />
			<List>
				<ListItem button component={Link} to="/">
					<ListItemIcon>
						<AppsIcon />
					</ListItemIcon>
					<ListItemText primary="Overview" />
				</ListItem>
				<ListItem button component={Link} to="/v2/rentals">
					<ListItemIcon>
						<HistoryIcon />
					</ListItemIcon>
					<ListItemText primary="Rentals" />
				</ListItem>
				<ListItem button component={Link} to="/v2/rentalsV2">
					<ListItemIcon>
						<HistoryIcon />
					</ListItemIcon>
					<ListItemText primary="Rentals V2" />
				</ListItem>	
				<ListItem button component={Link} to="/support-requests">
					<ListItemIcon>
						<ConfirmationNumberIcon />
					</ListItemIcon>
					<ListItemText primary="Support Requests" />
				</ListItem>
				<ListItem button component={Link} to={"/v2/campaigns"}>
					<ListItemIcon>
						<RedeemIcon />
					</ListItemIcon>
					<ListItemText primary="Campaigns" />
				</ListItem>
				<ListItem button component={Link} to={"/notifications"}>
					<ListItemIcon>
						<NotificationsActiveIcon />
					</ListItemIcon>
					<ListItemText primary="Notifications" />
				</ListItem>
				<ListItem button component={Link} to={"/v2/contests"}>
					<ListItemIcon>
						<RedeemIcon />
					</ListItemIcon>
					<ListItemText primary="Contests" />
				</ListItem>
				<ListItem button component={Link} to="/products">
					<ListItemIcon>
						<TrainIcon />
					</ListItemIcon>
					<ListItemText primary="Products" />
				</ListItem>
				<ListItem button component={Link} to="/operators">
					<ListItemIcon>
						<TrainIcon />
					</ListItemIcon>
					<ListItemText primary="Operators" />
				</ListItem>
				<ListItem button component={Link} to="/permissions">
					<ListItemIcon>
						<ConfirmationNumberIcon />
					</ListItemIcon>
					<ListItemText primary="Permissions" />
				</ListItem>
				<ListItem button component={Link} to="/reports">
					<ListItemIcon>
						<GridOnIcon />
					</ListItemIcon>
					<ListItemText primary="Reports" />
				</ListItem>
				{/* <ListItem button component={Link} to="/places-tasks" target="_blank">
					<ListItemIcon>
						<GridOnIcon />
					</ListItemIcon>
					<ListItemText primary="Places Tasks" />
				</ListItem> */}
				<ListItem button component={Link} to="/cache">
					<ListItemIcon>
						<StorageIcon />
					</ListItemIcon>
					<ListItemText primary="Cache" />
				</ListItem>
				<ListItem button component={Link} to="/app/settings">
					<ListItemIcon>
						<DisplaySettingsIcon />
					</ListItemIcon>
					<ListItemText primary="Travis Settings" />
				</ListItem>
			</List>
		</Drawer>
	);
};
