import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { Loader } from "./loader";
import { getRentalHistory } from "../api/rental_history";
import { OperatorIcon } from "./operator_icon";
import { Link } from "react-router-dom";

export class History extends Component {
	constructor(props) {
		super(props);
		this.state = { loaded: false, hasError: false, rental_history: [] };
	}

	render() {
		if (!this.state.loaded) {
			return <Loader />;
		}

		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <h1>Something went wrong.</h1>;
		}

		return (
			<List>
				<ListSubheader>Rental History</ListSubheader>
				{this.state.rental_history.map(function (listValue) {
					return (
						<ListItem
							key={listValue.rental_id}
							button
							component={Link}
							to={
								"rentals/" +
								listValue["operator"] +
								":" +
								listValue["rental_id"]
							}
						>
							<OperatorIcon operator={listValue["operator"]} />
							<span> &nbsp;&nbsp;&nbsp; </span>
							<ListItemText
								primary={`${listValue["event_name"]}@${new Date(
									listValue["id"],
								).toLocaleString("sv-SE")}`}
								secondary={
									"Vehicle: " +
									listValue["vehicle_code"] +
									" " +
									listValue["amount"] +
									" SEK " +
									listValue["duration"] +
									" mins"
								}
							/>
							<ListItemSecondaryAction>
								<Button
									component={Link}
									to={"users/" + listValue["user_id"]}
									color="primary"
									edge="end"
									aria-label="End Ride"
								>
									View User
								</Button>
							</ListItemSecondaryAction>
						</ListItem>
					);
				})}
			</List>
		);
	}

	componentDidMount() {
		console.log("Fetching active rentals");
		getRentalHistory().then((data) =>
			this.setState({
				loaded: true,
				hasError: false,
				rental_history: data ? data : [],
			}),
		);
	}
}
