import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Route } from "react-router-dom";
import { signOut } from "../api/user";
import { AuthContext } from "../context/authContext";
import { CustomClaimsKey } from "./permissions/PermissionsV2";

export const ProtectedRoute = ({
	component: Component,
	...rest
}: {
	component: React.ElementType;
	path: string;
	claim?: string;
	exact?: boolean;
}) => {
	const history = useHistory();
	const user = useContext(AuthContext);
	const [hasClaim, setHasClaim] = useState<boolean>(false);

	useEffect(() => {
		user.userData?.getIdTokenResult()
			.then((result) => {
				const claims = result.claims[CustomClaimsKey]

				if (!claims) {
					signOut();
					return;
				}

				const userHasClaim = claims.includes(rest.claim);
				setHasClaim(userHasClaim);
			});

	}, [rest.claim, user]);


	return (
		<Route
			{...rest}
			render={(routeProps) => {
				if (user.loggedIn) {
					if (!rest.claim || hasClaim) {
						return <Component {...routeProps} />;
					}
					return <></>;
				}

				return (
					<Redirect
						to={{
							pathname: "/login",
							state: { from: routeProps.location },
						}}
					/>
				);
			}}
		/>
	);
};
