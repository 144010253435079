import React from "react";

export const OperatorIcon = ({ operator }: { operator: string }) => {
	return (
		<img
			alt={operator}
			src={`https://storage.googleapis.com/travis-assets-prod/${operator}@2x.png`}
		/>
	);
};
