import { useEffect, useState } from "react";

import {
    AdminUser,
	getClaims,
	getPermissionsV2,
	removeUserAsAdminV2,
} from "../../api/permissions";

import {
    Button,
    Chip,
    Grid,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell, 
    Typography,
	Toolbar,
} from "@material-ui/core";

import { format } from "date-fns";

import { AddAdminUserModal, UpdateAdminUserModal } from "./AdminUserModal";
import { Loader } from "../loader";
import { makeStyles } from "@material-ui/core/styles";

export const CustomClaimsKey = "travis-support-tool";

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(5),
	}
}));

export const PermissionsV2 = () => {
	const classes = useStyles();
    const [reload, setReload] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [claims, setClaims] = useState<string[]>([]);
    const [users, setUsers] = useState<AdminUser[]>([]);
    const [selectedUser, setSelectedUser] = useState<AdminUser>();
    const [isAddAdminModalOpen, setIsAddAdminModalOpen] = useState<boolean>(false);
    const [isUpdateAdminModalOpen, setIsUpdateAdminModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!reload) {
            return;
        }

        setLoading(true);
        getPermissionsV2()
            .then((data: AdminUser[]) => {
                data.sort((a, b) => { return a.created - b.created; }); 
                setUsers(data);
            })
            .catch((error) => {
                console.log("Error ", error);
            })
            .finally(() => {
                setLoading(false);
                setReload(false);
            });

        getClaims()
            .then((data) => {
                console.log("CLAIMS: ", data);
                setClaims(data);
            })
            .catch((error) => {
                console.log("Error ", error);
            });
    }, [reload]);


    const handleRemoveAdminUser = (user: AdminUser): void => {
        removeUserAsAdminV2(user.id)
            .then(() => {
                setReload(true);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleUserAdded = () => {
        setReload(true);
    };

    const handleUserUpdated = () => {
        setReload(true);
    };

    return (
        <>
            {loading && <Loader />}
            {!loading && (
                <Grid container direction="column">
					<Toolbar>
						<Grid container justifyContent="space-between">
							<Grid item>
								<Typography variant="h6">Administrators</Typography>
							</Grid>
							<Grid item>
								<Grid container spacing={2}>
									<Grid item>
										<Button 
											variant="contained" 
											color="primary"
											onClick={() => setIsAddAdminModalOpen(true)}>
											Add user
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Toolbar>
					<Grid item>
						<Paper className={classes.paper}>
                            <TableContainer>
                                <Table aria-label="Admin users" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>E-mail</TableCell>
                                            <TableCell>ID</TableCell>
                                            <TableCell>{/* Make space for inactive warning chip */}</TableCell>
                                            <TableCell>Created</TableCell>
                                            <TableCell>Last login</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {users.map((user) => {
                                            return (
                                                <AdminUserRow
                                                    key={"admin_user_row_"+ user.id}
                                                    onClick={() => {
                                                        setSelectedUser(user);
                                                        setIsUpdateAdminModalOpen(true);
                                                    }
                                                    }
                                                    onClickRemove={(user: AdminUser) => {
                                                        console.log("Remove ", user?.email);
                                                        handleRemoveAdminUser(user);
                                                    }}
                                                    user={user} />
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>
            )}

            <AddAdminUserModal
                claims={claims}
                isOpen={isAddAdminModalOpen}
                onAdded={handleUserAdded}
                onClose={() => {setIsAddAdminModalOpen(false)}}/>

            <UpdateAdminUserModal
                user={selectedUser}
                claims={claims}
                isOpen={isUpdateAdminModalOpen}
                onUpdated={handleUserUpdated}
                onClose={() => {setIsUpdateAdminModalOpen(false)}}/>
        </>
    );
};

export const AdminUserRow = ({user, onClick, onClickRemove} : {user: AdminUser, onClick: () => void, onClickRemove: (user: AdminUser) => void}) => {
    const nowInEpochMillis = new Date().getTime();
    const ageLimitWarning = (6 * 30 * 24 * 60 * 60 * 1000); // Approx 6 months
    const ageLimitReached = nowInEpochMillis - user.last_login > ageLimitWarning;

    return (
        <TableRow hover onClick={onClick}>
            <TableCell width={40}>
                <Typography variant="body2">{user.email}</Typography>
            </TableCell>
            <TableCell width={40}>
                <Typography variant="body2">{user.id}</Typography>
            </TableCell>
            <TableCell width={40}>
                {ageLimitReached ? <Chip size="small" color="secondary" label="Inactive" /> : <></>}
            </TableCell>
            <TableCell width={40}>
                <Typography variant="body2">{format(user.created, "yyyy/MM/dd")}</Typography>
            </TableCell>
            <TableCell width={40}>
                <Typography variant="body2">{format(user.last_login, "yyyy/MM/dd")}</Typography>
            </TableCell>
            <TableCell width={40}>
                <Button variant="outlined" color="secondary" onClick={(e) => {
                    if (window.confirm("Are you sure?")) {
                        onClickRemove(user);
                    }

                    e.stopPropagation();
                    e.preventDefault();
                }}>Delete</Button>
            </TableCell>
        </TableRow>
    );
};
