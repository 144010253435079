import { useEffect, useState } from "react";
import { Campaign, getCampaigns } from "../../api/campaigns";
import { CampaignList } from "./CampaignList";

export const Campaigns = () => {
	const [campaigns, setCampaigns] = useState<Array<Campaign>>([]);

	useEffect(() => {
		fetchCampaigns();
	}, []);

	const fetchCampaigns = () => {
		getCampaigns().then(setCampaigns);
	};

	return (
		<CampaignList rows={campaigns} />
	);
}