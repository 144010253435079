import React, { useState, useContext, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { Location } from "history";
import { signIn } from "../api/user";
import { AuthContext } from "../context/authContext";
import { useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";

const generateErrorMessageFromError = (error: { code: string }): string | undefined => {
	if (error.code) {
		switch (error.code) {
			case "auth/user-not-found":
				return "Email not found.";
			case "auth/invalid-email":
				return "Invalid email.";
			case "auth/wrong-password":
				return "Wrong password";
			default:
				return "Unknown error";
		}
	}
};

export const Login = () => {
	const user = useContext(AuthContext);
	const history = useHistory();
	const [loginFormState, setLoginFormState] = useState<{
		error: {
			message: string | undefined;
		} | null;
		email: string;
		password: string;
	}>({
		error: null,
		email: "",
		password: "",
	});
	const location = useLocation<{
		from: Location
	}>();

	useEffect(() => {
		if (!user.loggedIn) return;

		user?.userData?.getIdTokenResult()
			.then((result) => {
				if (location.state && location.state.from) {
					history.replace(location.state.from.pathname);
				} else {
					history.replace("/");
				}
			});

	}, [user, history, location.state]);

	const handleSignIn = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();

		signIn(loginFormState.email, loginFormState.password)
			.then((user_) => {
				console.log("Signed in as", user_);

				setLoginFormState({
					error: null,
					email: "",
					password: "",
				});

			})
			.catch((error) => {
				console.log("Sign in failed", error.message, error.code);

				setLoginFormState({
					...loginFormState,
					error: { message: generateErrorMessageFromError(error) },
				});
			});
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLoginFormState({
			...loginFormState,
			[event.target.name]: event.target.value,
		});
	};

	return (
		<Grid
			container
			justifyContent="space-around"
			alignItems="center"
			style={{ minHeight: "calc(90vh)" }}
		>
			<Grid item container spacing={2} md={6} direction="column">
				<Grid item md={12}>
					<h1>Login to Travis Support Tool</h1>
				</Grid>
				<Grid item container md={12}>
					<Grid item md={6}>
						<TextField
							id="email"
							name="email"
							label="Email"
							onChange={handleChange}
							variant="outlined"
							value={loginFormState.email}
						/>
					</Grid>
					<Grid item md={6}>
						<TextField
							id="password"
							name="password"
							label="Password"
							type="password"
							onChange={handleChange}
							value={loginFormState.password}
							variant="outlined"
						/>
					</Grid>
				</Grid>

				<Grid item md={12}>
					{loginFormState.error ? (
						<Typography color="error">
							{loginFormState.error.message}
						</Typography>
					) : null}
				</Grid>
				<Grid item md={12}>
					<Button onClick={handleSignIn} variant="contained">
						Login
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};
