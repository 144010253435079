import { useEffect, useState } from "react";
import { Contest, getContests } from "../../api/contest";
import { Product, getProducts } from "../../api/products";
import { ContestList } from "./ContestList";
import { AddContest } from "./AddContest";
import {
	Grid,
	Button
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";

export const Contests = () => {
	const [contests, setContests] = useState<Array<Contest>>([]);
	const [products, setProducts] = useState<Array<Product>>([]);

	useEffect(() => {
		fetchContests();
		fetchProducts();
	}, []);

	const fetchContests = () => {
		getContests().then(setContests);
	}

	const fetchProducts = () => {
		getProducts().then(products => {
			setProducts(products.filter(product => product.type == "flexticket"))
		});
	}

	const onUpdate = () => {
		fetchContests();
	}

	return (
		<div>
		<Grid container direction="column" spacing={2}>
			<AddContest onUpdate={onUpdate} products={products} />
			<Grid item>
				<ContestList contests={contests} products={products} />
			</Grid>
		</Grid>
		</div>
	);
}