import { baseUrl } from "./config";
import { getToken } from "./index";

export enum FlexTicketOperator {
  SL = "se_sl" ,
  VastTrafik = "se_vasttrafik",
}

export enum RentalOperators {
    TIER = "se_tier",
    VOI = "se_voi",
}

// This function triggers a download of a file from the server
// It checks the response headers for a Content-Disposition header
// If it finds one, it extracts the filename and triggers a download
// of the file
const triggerFileClientDownload = async (response: Response) => {
    const disposition = response.headers.get("Content-Disposition");

    if (disposition && disposition.indexOf("attachment") !== -1) {
        let filename = "report.xlsx";
        const fNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/; 
        const matches = fNameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
        } else {
            console.log("No filename found in Content-Disposition header");
        }

        const url = window.URL.createObjectURL(new Blob([await response.blob()]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    }
    return response;
}

export const checkErrors = (response: Response) => {
    if (response.status >= 400) {
        throw new Error(`Error handling request: ${response.status}, ${response.statusText}`);
    } else if (response.status === 204) {
        throw new Error("No content found for request");
    }

    return response;
}

// This function extracts the date from a Date object
// and returns it as a string in the format "YYYY-MM-DD"
const extractDate = (date: Date): string => {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${date.getFullYear()}-${month}-${day}`;
}

export const downloadOperatorReport = async (from: Date, to: Date, operators: FlexTicketOperator[]) => {
    const requestUrl = new URL("/api/v2/reports/operators", baseUrl);

    const f = extractDate(from);
    const t = extractDate(to);

    requestUrl.searchParams.append("from", f);
    requestUrl.searchParams.append("to", t);

    let ops = "";
    operators.forEach(o => ops += o + ",");

    requestUrl.searchParams.append("operators", ops);

    return fetch(requestUrl.toString(), {
        headers: {
            "Authorization": `Bearer ${await getToken()}`,
        },
    })
        .then(checkErrors)
        .then(triggerFileClientDownload);
}

export const downloadSalesReport = async (from: Date, to: Date, flexTicketOperators: FlexTicketOperator[], rentalOperators: RentalOperators[]) => {
    const requestUrl = new URL("/api/v2/reports/sales", baseUrl);

    // convert to string and extract date
    const f = extractDate(from);
    const t = extractDate(to);

    requestUrl.searchParams.append("from", f);
    requestUrl.searchParams.append("to", t);

    let flexOps = "";
    flexTicketOperators.forEach(o => flexOps += o + ",");

    let rentalOps = "";
    rentalOperators.forEach(o => rentalOps += o + ",");

    requestUrl.searchParams.append("flex_ticket_operators", flexOps);
    requestUrl.searchParams.append("rental_operators", rentalOps);

    return fetch(requestUrl.toString(), {
        headers: {
            "Authorization": `Bearer ${await getToken()}`,
        },
    })
        .then(checkErrors)
        .then(triggerFileClientDownload);
}